"use client";

import { useErrorBoundary } from "react-error-boundary";
import styles from "./ErrorFallback.module.css";

export type FallbackProps = {
	error: any;
	resetErrorBoundary: (...args: any[]) => void;
};

export const ErrorFallback = ({ error }: FallbackProps) => {
	const { resetBoundary } = useErrorBoundary();

	return (
		<div className={styles["wrapper"]}>
			<div className={styles["box"]} role="alert">
				<p>Something went wrong.</p>
				<p>Request Error. Please contact our technical support.</p>
				<pre className={styles["message"]}>{error.message}</pre>
				<div className={styles["btns-wrapper"]}>
					{/* Try again */}
					<button className={styles["button"]} onClick={resetBoundary}>To the Home page</button>
				</div>
			</div>
		</div>
	);
};
