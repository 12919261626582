import styles from './FormSteps.module.css';
import { createPortal } from "react-dom";
import { useForm, SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useEffect, MouseEvent, useState } from 'react';
import { FormStep4Values } from '../../models/models';
import { useAppSelector, useAppDispatch } from '../../store/hookTypes';
import { setFormStep4, setQuoteData } from '../../store/newQuoteSlice';
import { useCreateQuoteMutation } from '../../store/secondApiQueries';
import { rootPortal } from '../../index';
import { Loader } from '../Loader/Loader';
import { getExpirationDate } from '../../helpers/getExpirationDate';
import { getDiffBetweenYears } from '../../helpers/getDiffBetweenYears';
import { ROOF_TYPE_MAIN } from '../../constants';
import { SelectRequired } from '../Select/SelectRequired';
import { YesNoRequired } from '../Select/YesNoRequired';

//TODO: обработка isErrorQuote при помощи ErrorBoundary
export const FormStep4 = () => {
	const { formStep4 } = useAppSelector((state) => state.newQuote);
	const { formStep1, formStep2, formStep3 } = useAppSelector((state) => state.newQuote);
	const windHailCoverage = useAppSelector((state) => state.newQuote.formStep3.windHailCoverage);
	const { roofYear, roofType, doesBuildingHavePolybutylene, plumbingYear } = useAppSelector((state) => state.newQuote.formStep3);
	const { effectiveDate } = useAppSelector((state) => state.newQuote.formStep1);
	const yearsDiffRoof = getDiffBetweenYears(roofYear, effectiveDate);
	const isIncludedRoofOption = yearsDiffRoof > 35 ?
		false :
		yearsDiffRoof > 25 && (ROOF_TYPE_MAIN.includes(roofType)) ?
			false :
			true;
	const yearsDiffOtherThanRoof = getDiffBetweenYears(plumbingYear, effectiveDate);
	const isIncludedOtherThanRoofOption = (doesBuildingHavePolybutylene === "Yes") || (yearsDiffOtherThanRoof > 20) ?
		false :
		true;
	const isLimitOtherThanRoofOption = (doesBuildingHavePolybutylene === "Yes") || (yearsDiffOtherThanRoof > 30) ?
		false :
		true;
	const isRcv = yearsDiffRoof > 35 ?
		false :
		yearsDiffRoof > 25 && (ROOF_TYPE_MAIN.includes(roofType)) ?
			false :
			true;

	const [isWaterBackUpExcludedOnly, setIsWaterBackUpExcludedOnly] = useState(false);

	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const [createQuote, { data: quoteData, isLoading: isLoadingQuote, isError: isErrorQuote }] = useCreateQuoteMutation();

	const {
		register,
		watch,
		formState: { errors },
		handleSubmit,
		setValue,
	} = useForm<FormStep4Values>({
		mode: 'onSubmit',
		defaultValues: formStep4,
	});

	const watchAllFields = watch(); // все поля формы - для сохранения в store, когда нажимается Previous

	const watchWaterDamageCoverageOther = watch('waterDamageCoverageOther');

	useEffect(() => {
		if (!isIncludedRoofOption) {
			setValue('waterDamageCoverageRoof', 'Excluded');
		};
	}, [isIncludedRoofOption, setValue]);

	useEffect(() => {
		if ((!isIncludedOtherThanRoofOption) && (!isLimitOtherThanRoofOption)) {
			setValue('waterDamageCoverageOther', 'Excluded');
		};
	}, [isIncludedOtherThanRoofOption, isLimitOtherThanRoofOption, setValue]);

	useEffect(() => {
		if (!(windHailCoverage === '0') && !(isRcv)) {
			setValue('valuationOnRoofForWindLosses', 'Actual cash value (ACV)');
		};
	}, [isRcv, setValue, windHailCoverage]);

	useEffect(() => {
		if (quoteData) {
			dispatch(setQuoteData(quoteData)); // saving quote data to the store
			navigate('/new-quote-step5');
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [quoteData]);

	useEffect(() => {
		if (watchWaterDamageCoverageOther === 'Excluded') {
			setIsWaterBackUpExcludedOnly(true);
			setValue('waterBackUpCoverageLimit', 'Excluded');
		} else {
			setIsWaterBackUpExcludedOnly(false);
		};
	}, [setValue, watchWaterDamageCoverageOther]);

	const onSubmit: SubmitHandler<FormStep4Values> = (data) => {
		console.log('FormStep4=', data);
		dispatch(setFormStep4(data));
		createQuote(
			{
				account: {
					InsuredNatureOfBusiness: "", //* no field in my form
					businessformation: {
						Address1: formStep2.street,
						Address2: "", //* no field in my form
						City: formStep2.city,
						Country: "United States", //	hardcode
						State: formStep2.locationState,
						ZipCode: formStep1.zipCode,
						Zip4: formStep1.zip4,
					},
					location: {
						id1: {
							Address1: formStep2.street,
							Address2: formStep2.street2,
							Buildings: {
								id1: {
									Occupancy: formStep3.occupancyType,
									RoofType: formStep3.roofType,
									Shutters: formStep3.shutters,
									construction: formStep3.constructionType,
									distanceToCoast: formStep3.distanceToCoast,
									extraData: {
										id: 1, //  hardcode
										idOriginal: 1, //  hardcode
										ref: 'L1_B1', //  hardcode
									},
									number: 1, //  hardcode
									protectionClass: formStep3.protectionClass,
									renoRoofingYear: Number(formStep3.roofYear),
									roofShape: formStep3.roofShape,
									yearBuilt: Number(formStep3.yearBuilt),
								},
							},
							City: formStep2.city,
							County: formStep2.county,
							Number: 1, //  hardcode
							State: formStep2.locationState,
							ZipCode: formStep1.zipCode,
							Zip4: formStep1.zip4,
							entityType: formStep3.entityType,
							extraData: {
								id: 1, //  hardcode
								idOriginal: 1, //  hardcode
							},
							inspection: true, //  hardcode
						},
					},
				},
				hashes: {
					details: "38D253DA450DEFAA090D55EDA6810F7D1A73226BF4A65122982320EE83DB61BF", //	hardcode
					rate: "78A3494D7D6BD7ED663692BF7F6ABBF1A41B6DE8CE4DA4DA95C7F1ECA8A4E23C", //	hardcode
				},
				policy: {
					EffectiveDate: formStep1.effectiveDate,
					ExpirationDate: getExpirationDate(formStep1.effectiveDate),
					State: formStep2.locationState,
					lineProperty: {
						risk: {
							L1_B1: {
								AOPDeductible: formStep3.aopDeductible,
								BuildingNumber: 1, //  hardcode
								CentralStatationAlarm: formStep3.centralStationAlarm,
								LocationNumber: 1, //  hardcode
								// NSE: formStep3.namedStormDeductible === "Yes" ? 1 : 0, // убрали поле Named Storm Deductible из формы
								NSE: 0, // убрали поле Named Storm Deductible из формы, тут 0 для соответствия с API
								RenovationDebit: data.isAnyRenovationOrReconstruction === "Yes" ? 2 : 1,
								WindDeductible: formStep3.windCoverageDeductible,
								WindDeductible319587: 0, //  hardcode
								WindSelect: formStep3.windHailCoverage === "1" ? 1 : 0,
								coverageForm: formStep3.coverageForm,
								coverages: {
									CoverageA: {
										type: "CoverageA",
										value: formStep3.buildingLimit,
									},
									CoverageB: {
										type: "CoverageB",
										value: formStep3.otherStructuresLimit,
									},
									CoverageC: {
										type: "CoverageC",
										value: formStep3.contentsLimit,
									},
									CoverageD: {
										type: "CoverageD",
										value: formStep3.lossOfUseLimit,
									},
									CoverageE: {
										type: "CoverageE",
										value: formStep3.personalLiabilityLimit,
									},
									CoverageF: {
										type: "CoverageF",
										value: formStep3.medicalExpenseLimit,
									},
									additionalCoveragesIncreasedLimits: {
										type: "additionalCoveragesIncreasedLimits",
										value: data.additionalCoverages,
									},
									identityFraudExpenseCoverage: {
										type: "identityFraudExpenseCoverage",
										value: data.identityFraudExpenseCoverage,
									},
									increasedOrdinanceAndLaw: {
										type: "increasedOrdinanceAndLaw",
										value: data.increasedOrdinanceAndLaw,
									},
									moldCoverageLimit: {
										type: "moldCoverageLimit",
										value: data.moldCoverageLimit,
									},
									waterBackUpCoverage: {
										type: "waterBackUpCoverage",
										value: data.waterBackUpCoverageLimit,
									},
								},
								extraData: {
									buildingId: 1, //  hardcode
									locationId: 1, //  hardcode
								},
								manualDbCrLiability: "1", //  hardcode
								manualDbCrPolicy: "1", //  hardcode
								manualDbCrWind: "1", //  hardcode
								manualDbCrXWind: "1", //  hardcode
								propertyType: "Homeowners", //  hardcode
								replacementCostOnContents: data.replacementCostOnContents,
								valuationOnRoofForWindLosses: data.valuationOnRoofForWindLosses,
								waterDamageCoverageOtherThanRoof: data.waterDamageCoverageOther,
							},
						},
					},
					priorClaim3years: data.anyClaimsOrLossesInLast === "Yes" ? 1 : 0,
					rateType: "NB", //  hardcode
				},
				version: {
					number: "1.0.0", //  hardcode
					type: "7", //  hardcode
				},
			}
		); // quote creation

		if (quoteData) {
			dispatch(setQuoteData(quoteData)); // saving quote data to the store
		};
	};

	const handlePreviousClick = (event: MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		dispatch(setFormStep4(watchAllFields));
		navigate('/new-quote-step3');
	};

	return (
		<>
			<form
				onSubmit={handleSubmit(onSubmit)}
				className={styles["form"]}
				id="form4"
			>

				<div className={styles["grid-container"]}>

					<YesNoRequired
						fieldName={"additionalCoverages"}
						labelText={"Additional Coverages - increased limits"}
						register={register}
						errors={errors}
					/>

					<YesNoRequired
						fieldName={"replacementCostOnContents"}
						labelText={"Replacement cost on contents"}
						register={register}
						errors={errors}
					/>

					<SelectRequired
						fieldName="valuationOnRoofForWindLosses"
						labelText="Valuation on roof for wind losses"
						optionsList={
							windHailCoverage === '0' ? ["N/A Wind excluded"] :
								isRcv ? ["Replacement cost (RCV)", "Actual cash value (ACV)"] :
									["Actual cash value (ACV)"]
						}
						register={register}
						errors={errors}
						requiredText="Choose the option"
					/>

					<YesNoRequired
						fieldName={"identityFraudExpenseCoverage"}
						labelText={"Identity fraud expense coverage"}
						register={register}
						errors={errors}
					/>

					<SelectRequired
						fieldName="waterDamageCoverageRoof"
						labelText="Water damage coverage - roof"
						optionsList={
							isIncludedRoofOption ?
								["Included", "Excluded"] :
								["Excluded"]
						}
						register={register}
						errors={errors}
						requiredText="Choose the option"
					/>

					<SelectRequired
						fieldName="waterDamageCoverageOther"
						labelText="Water damage coverage - other than roof"
						optionsList={
							isIncludedOtherThanRoofOption ?
								["Included", "$10,000 limit", "Excluded"] :
								isLimitOtherThanRoofOption ?
									["$10,000 limit", "Excluded"] :
									["Excluded"]
						}
						register={register}
						errors={errors}
						requiredText="Choose the option"
					/>

					<SelectRequired
						fieldName="moldCoverageLimit"
						labelText="Mold coverage limit"
						optionsList={[]}
						register={register}
						errors={errors}
						requiredText="Choose the option"
						optionsValueTextObject={{ "10000": "10,000", "25000": "25,000" }}
					/>

					<SelectRequired
						fieldName="waterBackUpCoverageLimit"
						labelText="Water back up coverage limit"
						optionsList={
							!isWaterBackUpExcludedOnly ?
								["5,000", "10,000", "Excluded"] :
								["Excluded"]
						}
						register={register}
						errors={errors}
						requiredText="Choose the option"
					/>

					<YesNoRequired
						fieldName={"increasedOrdinanceAndLaw"}
						labelText={"Increased Ordinance And Law"}
						register={register}
						errors={errors}
					/>

					<YesNoRequired
						fieldName={"anyClaimsOrLossesInLast"}
						labelText={"Any claims or losses in last 3 years?"}
						register={register}
						errors={errors}
					/>

					<YesNoRequired
						fieldName={"isAnyRenovationOrReconstruction"}
						labelText={"Is there any renovation or reconstruction?"}
						register={register}
						errors={errors}
					/>

				</div>

				<div className={styles["box-row-end"]}>
					<button type="button" className={styles["step-button"]} onClick={handlePreviousClick}>Previous</button>
					<button type="submit" className={styles["step-button"]}>Next step</button>
				</div>

			</form>

			{
				(isLoadingQuote) ?
					createPortal(<Loader isDark={true} />, rootPortal) :
					isErrorQuote ? <div>Error with creating Quote</div> :
						null
			}
		</>
	);
};
