import styles from './Layout.module.css';
import { Outlet } from 'react-router-dom';
import { Navigation } from "../Navigation/Navigation";

//TODO: сделать футер
export const Layout = () => {
	return (
		<div className={styles["wrapper"]}>
			<Navigation />

			<Outlet />

			{/* здесь будет футер */}
		</div>
	);
};
