import { FEETS_IN_MILE } from "../constants";
import { DistanceToCoast } from "../models/models";

export const getDtcFeets = (dtcObj: DistanceToCoast) => {
	let dtc = 0;

	switch ((dtcObj as DistanceToCoast).distanceToCoastUnits as string) {
		case 'miles':
			dtc = Number(dtcObj.distanceToCoast) * FEETS_IN_MILE;
			break;
		case 'feet':
			dtc = Number(dtcObj.distanceToCoast);
			break;
		default:
			dtc = 0;
			break;
	};

	return dtc;
};
