import { FormStep1 } from '../../components/FormSteps/FormStep1';
import { StepsProgress } from '../../components/StepsProgress/StepsProgress';
// import styles from './NewQuotePage.module.css';

export const NewQuotePageStep1 = () => {
	return (
		<div>
			<StepsProgress step={1} />
			<FormStep1 />
		</div>
	);
};
