import { ErrorMessageProps } from '../../models/models';
import styles from './ErrorMessage.module.css';

export const ErrorMessage = (props: ErrorMessageProps) => {
	return (
		<div className={styles['error-message']}>
			{props.errorMessage}
		</div>
	);
};
