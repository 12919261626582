import { StepsProgressProps } from '../../models/models';
import styles from './StepsProgress.module.css';
import classNames from "classnames";

export const StepsProgress = ({ step }: StepsProgressProps) => {
	return (
		<div className={styles["quote-progress-container"]}>
			<div className={styles["progress-step-container"]}>
				<div className={classNames(styles["progress-icon"], step === 1 && styles["active"])}>
					<i className="ri-user-3-line"></i>
				</div>
				<div className={styles["progress-step-label"]}>
					<div className={classNames(styles["subline"], styles["margin-5"])}>STEP 1</div>
					<h5 className={styles["heading-5"]}>Getting Started</h5>
				</div>
			</div>
			<div className={styles["progress-arrow"]}>
				<i className="ri-arrow-right-s-line"></i>
			</div>
			<div className={styles["progress-step-container"]}>
				<div className={classNames(styles["progress-icon"], step === 2 && styles["active"])}>
					<i className="ri-map-pin-line"></i>
				</div>
				<div className={styles["progress-step-label"]}>
					<div className={classNames(styles["subline"], styles["margin-5"])}>STEP 2</div>
					<h5 className={styles["heading-5"]}>Location</h5>
				</div>
			</div>
			<div className={styles["progress-arrow"]}>
				<i className="ri-arrow-right-s-line"></i>
			</div>
			<div className={styles["progress-step-container"]}>
				<div className={classNames(styles["progress-icon"], step === 3 && styles["active"])}>
					<i className="ri-home-2-line"></i>
				</div>
				<div className={styles["progress-step-label"]}>
					<div className={classNames(styles["subline"], styles["margin-5"])}>STEP 3</div>
					<h5 className={styles["heading-5"]}>Risk Details</h5>
				</div>
			</div>
			<div className={styles["progress-arrow"]}>
				<i className="ri-arrow-right-s-line"></i>
			</div>
			<div className={styles["progress-step-container"]}>
				<div className={classNames(styles["progress-icon"], step === 4 && styles["active"])}>
					<i className="ri-menu-add-line"></i>
				</div>
				<div className={styles["progress-step-label"]}>
					<div className={classNames(styles["subline"], styles["margin-5"])}>STEP 4</div>
					<h5 className={styles["heading-5"]}>Addition</h5>
				</div>
			</div>
			<div className={styles["progress-arrow"]}>
				<i className="ri-arrow-right-s-line"></i>
			</div>
			<div className={styles["progress-step-container"]}>
				<div className={classNames(styles["progress-icon"], step === 5 && styles["active"])}>
					<i className="ri-task-line"></i>
				</div>
				<div className={styles["progress-step-label"]}>
					<div className={classNames(styles["subline"], styles["margin-5"])}>STEP 5</div>
					<h5 className={styles["heading-5"]}>Your Quote</h5>
				</div>
			</div>
			<div className={styles["progress-arrow"]}>
				<i className="ri-arrow-right-s-line"></i>
			</div>
			<div className={styles["progress-step-container"]}>
				<div className={classNames(styles["progress-icon"], step === 6 && styles["active"])}>
					<i className="ri-home-smile-line"></i>
				</div>
				<div className={styles["progress-step-label"]}>
					<div className={classNames(styles["subline"], styles["margin-5"])}>STEP 6</div>
					<h5 className={styles["heading-5"]}>Finish completing</h5>
				</div>
			</div>
			<div className={styles["progress-arrow"]}>
				<i className="ri-arrow-right-s-line"></i>
			</div>
			<div className={styles["progress-step-container"]}>
				<div className={classNames(styles["progress-icon"], step === 7 && styles["active"])}>
					<i className="ri-wallet-3-line"></i>
				</div>
				<div className={styles["progress-step-label"]}>
					<div className={classNames(styles["subline"], styles["margin-5"])}>STEP 7</div>
					<h5 className={styles["heading-5"]}>Sign & Pay</h5>
				</div>
			</div>
		</div>
	);
};
