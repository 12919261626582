import React from 'react';
import styles from './Modal.module.css';

interface ModalProps {
  title: string;
  onClose: (newModal: boolean) => void;
  children: React.ReactNode;
}

export const Modal = ({ title, onClose, children }: ModalProps) => {
  const clickHandler = () => {
    onClose(false);
  };

  return (
    <>
      <div className={styles["modal__overlay"]} onClick={clickHandler}></div>
      <div className={styles["modal__main"]}>
        <h2 className={styles["title"]}>{title}</h2>

        {children}
      </div>
    </>
  );
}
