import classNames from "classnames";
import styles from "../FormSteps/FormSteps.module.css";
import { TextareaBaseProps } from "../../models/models";

export const TextareaBase = ({
	children,
	fieldName,
	labelText,
	placeholder,
	register,
	isAppearing,
	requiredText,
	errors,
	rows }: TextareaBaseProps) => {
	return (
		<div className={classNames(styles["grid-item"], styles["grid-item-wide-3"], isAppearing && styles["appearing-field"])}>
			<label htmlFor={fieldName} className={styles["form-field-label"]}>
				{labelText}
			</label>
			<textarea
				{...register(fieldName, {
					required: requiredText ?? false, // requiredText is optional
				})}
				name={fieldName}
				rows={rows ?? 2} // rows is optional
				placeholder={placeholder}
				className={
					requiredText ?
						classNames(styles["form-text-field"], styles["w-input"], errors[fieldName] && styles["error"]) :
						classNames(styles["form-text-field"], styles["w-input"], styles["optional-field"])
				}
				id={fieldName}>
			</textarea>
			{children}
		</div>
	);
};
