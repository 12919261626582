import { TextareaRequiredProps } from "../../models/models";
import { ErrorMessage } from "../ErrorMessage/ErrorMessage";
import { TextareaBase } from "./TextareaBase";

export const TextareaRequired = ({
	fieldName,
	labelText,
	placeholder,
	register,
	isAppearing,
	requiredText,
	errors,
	rows }: TextareaRequiredProps) => {
	return (
		<TextareaBase
			fieldName={fieldName}
			labelText={labelText}
			placeholder={placeholder}
			register={register}
			isAppearing={isAppearing}
			requiredText={requiredText}
			errors={errors}
			rows={rows}
		>
			<ErrorMessage errorMessage={errors[fieldName] ? (errors[fieldName]?.message as string) : ''} />
		</TextareaBase>
	);
};
