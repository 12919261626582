import { FEETS_IN_MILE } from "../constants";

export const getDtcOption = (dtcFeet: number) => {
	let option = '';

	if (dtcFeet < 0.5 * FEETS_IN_MILE) {
		option = '0 - 0.5 miles';
	} else if (dtcFeet < 1 * FEETS_IN_MILE) {
		option = '0.5 - 1 mile';
	} else if (dtcFeet < 2 * FEETS_IN_MILE) {
		option = '1 - 2 miles';
	} else if (dtcFeet < 5 * FEETS_IN_MILE) {
		option = '2 - 5 miles';
	} else if (dtcFeet < 10 * FEETS_IN_MILE) {
		option = '5 - 10 miles';
	} else if (dtcFeet < 15 * FEETS_IN_MILE) {
		option = '10 - 15 miles';
	} else {
		option = '15 miles +';
	};

	return option;
};
