import { InputDateRequiredProps } from "../../models/models";
import { ErrorMessage } from "../ErrorMessage/ErrorMessage";
import { InputDateBase } from "./InputDateBase";

export const InputDateRequired = ({ fieldName, labelText, register, requiredText, errors }: InputDateRequiredProps) => {
	return (
		<InputDateBase
			fieldName={fieldName}
			labelText={labelText}
			register={register}
			requiredText={requiredText}
			errors={errors}
		>
			<ErrorMessage errorMessage={errors[fieldName] ? (errors[fieldName]?.message as string) : ''} />
		</InputDateBase>
	);
};
