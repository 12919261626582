import styles from './AboutPage.module.css';
import AboutImageManager from './manager-desk-1.svg';
import AboutImageAnalyze from './pie-and-charts-3.svg';
import AboutImageUpgrade from './upgrade-2.svg';

export const AboutPage = () => {
	return (
		<div className={styles["page-container"]}>
			<h2 className={styles["title"]}>A little bit more about our company</h2>

			<div className={styles["main-box"]}>
				<div className={styles["main-text"]}>
					<h3 className={styles["subtitle"]}>Our Commitment</h3>

					<p>At Aldgate Insurance, we are committed to safeguarding your most valuable asset—your home. We believe that every homeowner deserves reliable and affordable insurance coverage that suits their individual needs. With years of experience in the industry, we have developed a deep understanding of the risks and challenges homeowners face. Our goal is to offer tailored insurance solutions that provide financial protection against unforeseen events, allowing you to focus on what matters most: enjoying your home and spending time with your loved ones.</p>

					<h3 className={styles["subtitle"]}>Superior Customer Service</h3>

					<p>At Aldgate Insurance, we believe in providing exceptional customer service. We strive to build strong relationships with our clients, offering support and assistance throughout your insurance journey. Whether you need help selecting the right policy, making a claim, or simply seeking clarification on your coverage, our dedicated team is here to assist you promptly and professionally.</p>
				</div>

				<div className={styles["main-image"]}>
					<img src={AboutImageManager} alt="About company" />
				</div>
			</div>

			<div className={styles["main-box"]}>
				<div className={styles["main-image"]}>
					<img src={AboutImageAnalyze} alt="About company" />
				</div>

				<div className={styles["main-text"]}>
					<h3 className={styles["subtitle"]}>Professional Expertise</h3>

					<p>When it comes to homeowners liability insurance, you can trust the expertise of our team. Our insurance professionals are knowledgeable and experienced in the field, ensuring that you receive accurate information and advice when making important coverage decisions. We take the time to listen to your concerns and address your questions, guiding you through the insurance process with transparency and care.</p>

					<h3 className={styles["subtitle"]}>Tailored Coverage Options</h3>

					<p>We recognize that no two homeowners are alike, which is why we offer a range of coverage options to suit your specific requirements. Our policies are designed to protect you against a wide array of risks, including property damage, personal liability claims, and other unforeseen circumstances that may arise. Whether you own a house, condominium, or rental property, we have policies that can be customized to fit your unique situation.</p>
				</div>
			</div>
			<div className={styles["main-box"]}>
				<div className={styles["main-text"]}>
					<h3 className={styles["subtitle"]}>Community Involvement</h3>

					<p>We are proud to be an active member of the community we serve. We understand the importance of giving back and making a positive impact on the lives of others. Through various initiatives and partnerships, we contribute to local organizations and support causes that align with our values. We believe in building a stronger, safer community together.</p>

					<h3 className={styles["subtitle"]}>Contact Us</h3>

					<p>Thank you for considering Aldgate Insurance for your homeowners liability insurance needs. We look forward to helping you protect what matters most. If you have any questions or would like to discuss your insurance options, please don't hesitate to contact us. Our friendly team is ready to assist you.</p>
				</div>

				<div className={styles["main-image"]}>
					<img src={AboutImageUpgrade} alt="About company" />
				</div>
			</div>
		</div>
	);
};
