import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { CheckedAddress, DistanceToCoast } from '../models/models';

export type CheckedAddressState = {
	checkedAddress: CheckedAddress | null;
	isAddressValidated: boolean;
	isStreetValidated: boolean;
	isCityValidated: boolean;

	isStreetManuallyEntered: boolean;

	dataDTC: DistanceToCoast | null;
	optionDTC: string;

	isWindIncluded: boolean;
	optionsWindCoverage: Array<string>;
};

const initialState: CheckedAddressState = {
	checkedAddress: null,
	isAddressValidated: false,
	isStreetValidated: false,
	isCityValidated: false,
	isStreetManuallyEntered: false,
	dataDTC: null,
	optionDTC: '',
	isWindIncluded: false,
	optionsWindCoverage: [],
};

const checkedAddressSlice = createSlice({
	name: 'checkedAddress',
	initialState: initialState,
	reducers: {
		setCheckedAddress(state, action: PayloadAction<CheckedAddress>) {
			state.checkedAddress = action.payload;
		},
		setIsAddressValidated(state, action: PayloadAction<boolean>) {
			state.isAddressValidated = action.payload;
		},
		setIsStreetValidated(state, action: PayloadAction<boolean>) {
			state.isStreetValidated = action.payload;
		},
		setIsCityValidated(state, action: PayloadAction<boolean>) {
			state.isCityValidated = action.payload;
		},
		setIsStreetManuallyEntered(state, action: PayloadAction<boolean>) {
			state.isStreetManuallyEntered = action.payload;
		},
		setDataDTC(state, action: PayloadAction<DistanceToCoast>) {
			state.dataDTC = action.payload;
		},
		setOptionDTC(state, action: PayloadAction<string>) {
			state.optionDTC = action.payload;
		},
		setIsWindIncluded(state, action: PayloadAction<boolean>) {
			state.isWindIncluded = action.payload;
		},
		setOptionsWindCoverage(state, action: PayloadAction<Array<string>>) {
			state.optionsWindCoverage = action.payload;
		},
	},
});

export const {
	setCheckedAddress,
	setIsAddressValidated,
	setIsStreetValidated,
	setIsCityValidated,
	setIsStreetManuallyEntered,
	setDataDTC,
	setOptionDTC,
	setIsWindIncluded,
	setOptionsWindCoverage,
} = checkedAddressSlice.actions;
export const checkedAddressReducer = checkedAddressSlice.reducer;
