import { FormStep7 } from '../../components/FormSteps/FormStep7';
import { StepsProgress } from '../../components/StepsProgress/StepsProgress';
// import styles from './NewQuotePage.module.css';

export const NewQuotePageStep7 = () => {
	return (
		<div>
			<StepsProgress step={7} />
			<FormStep7 />
		</div>
	);
};
