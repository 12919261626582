import styles from './PreviousInsuranceCard.module.css';
import { useState } from "react";
import { createPortal } from "react-dom";
import { PreviousInsuranceCardProps } from "../../models/models";
import { useAppDispatch } from "../../store/hookTypes";
import { rootPortal } from '../../index';
import { Modal } from "../Modal/Modal";
import { deletePreviousInsuranceEntry } from '../../store/newQuoteSlice';
import { FormEditPreviousInsurance } from '../FormEditPreviousInsurance/FormEditPreviousInsurance';

export const PreviousInsuranceCard = ({ id, companyName, policyNumber, effectiveDate, expirationDate }: PreviousInsuranceCardProps) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const dispatch = useAppDispatch();

	const handleEdit = () => {
		setIsModalOpen(true);
	};

	const handleDelete = () => {
		dispatch(deletePreviousInsuranceEntry(id));
	};

	return (
		<>
			<div className={styles["card"]}>
				<h3 className={styles["card-title"]}>{policyNumber}</h3>

				<div>{companyName}</div>

				<div>{`${effectiveDate} - ${expirationDate}`}</div>

				<div className={styles["icons-wrapper"]}>
					<div className={styles["edit-icon"]} onClick={handleEdit}>
						<i className="ri-edit-2-fill"></i>
					</div>

					<div className={styles["delete-icon"]} onClick={handleDelete}>
						<i className="ri-delete-bin-2-fill"></i>
					</div>
				</div>
			</div>

			{
				isModalOpen &&
				createPortal(
					<Modal title={"Editing the entry"} onClose={setIsModalOpen}>
						<FormEditPreviousInsurance idEntry={id} setIsModalOpen={setIsModalOpen} />
					</Modal>,
					rootPortal)
			}
		</>
	);
};
