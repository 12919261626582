import classNames from "classnames";
import styles from "../FormSteps/FormSteps.module.css";
import { useAppSelector } from "../../store/hookTypes";
import { ErrorMessage } from "../ErrorMessage/ErrorMessage";
import { ProtectionHighClassProps } from "../../models/models";
import { InputTextRequired } from "../InputText/InputTextRequired";
import { YesNoRequiredWithExtra } from "../Select/YesNoRequiredWithExtra";
import { useState, useEffect } from "react";
import { YesNoRequired } from "../Select/YesNoRequired";
import { YesNoRequiredRow } from "../Select/YesNoRequiredRow";
import { TextareaRequired } from "../Textarea/TextareaRequired";
import { TextareaOptional } from "../Textarea/TextareaOptional";
import { SelectDisabled } from "../Select/SelectDisabled";

export const ProtectionHighClass = ({ watchFireHydrant, register, errors, watch }: ProtectionHighClassProps) => {
	const protectionAnswer = useAppSelector((state) => state.newQuote.formStep3.protectionClass);
	const isProtectionHigh = protectionAnswer === "9" || protectionAnswer === "10";

	const watchWaterSource = watch("isAnotherWaterSource");
	const watchOccupiedYearRound = watch("isOccupiedYearRound");

	const [isShowDescribeWaterSource, setIsShowDescribeWaterSource] = useState(false);
	const [isShowDescribeOccupiedYearRound, setIsShowDescribeOccupiedYearRound] = useState(false);

	useEffect(() => {
		if (watchWaterSource === "Yes") {
			setIsShowDescribeWaterSource(true);
		} else {
			setIsShowDescribeWaterSource(false);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [watchWaterSource]);

	useEffect(() => {
		if (watchOccupiedYearRound === "No") {
			setIsShowDescribeOccupiedYearRound(true);
		} else {
			setIsShowDescribeOccupiedYearRound(false);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [watchOccupiedYearRound]);

	return (
		<>
			{
				isProtectionHigh &&
				(<div className={styles["grid-container-3"]}>

					<h3 className={classNames(styles["title"], styles["grid-item-wide-3"])}>Protection Class 9 & 10 Questionnaire</h3>

					<SelectDisabled
						fieldName="protectionClass-disabled"
						labelText="Protection class"
						value={protectionAnswer}
						optionsList={["9", "10"]}
					/>

					<InputTextRequired
						fieldName="fireDepartmentName"
						labelText="Name of responding Fire Department"
						placeholder="i.e. County Fire Department"
						register={register}
						requiredText="Enter the name"
						errors={errors}
					/>

					<div className={classNames(styles["grid-item"])}>
						<fieldset>
							<div>
								<legend className={styles["form-field-label"]}>
									Paid or Volunteer
								</legend>
							</div>
							<div className={classNames(styles["row-checkboxes"])}>
								<div className={styles["box-row-10"]}>
									<input
										{...register('protectionHighCheckbox', {
											required: 'Choose at least one option',
										})}
										type="checkbox"
										value="Paid"
										className={styles["input-checkbox"]}
										id="Paid" />
									<label htmlFor="Paid" className={styles["label-checkbox"]}>Paid</label>
								</div>
								<div className={styles["box-row-10"]}>
									<input
										{...register('protectionHighCheckbox', {
											required: 'Choose at least one option',
										})}
										type="checkbox"
										value="Volunteer"
										className={styles["input-checkbox"]}
										id="Volunteer" />
									<label htmlFor="Volunteer" className={styles["label-checkbox"]}>Volunteer</label>
								</div>
							</div>
						</fieldset>
						{<ErrorMessage errorMessage={errors?.protectionHighCheckbox ? (errors?.protectionHighCheckbox?.message as string) : ''} />}
					</div>

					<InputTextRequired
						fieldName="responseTime"
						labelText="Response Time"
						placeholder="i.e. 5 minutes"
						register={register}
						requiredText="Enter response time"
						errors={errors}
					/>

					<InputTextRequired
						fieldName="numberOfPumpers"
						labelText="Number of pumpers"
						placeholder="i.e. 2"
						register={register}
						requiredText="Enter the number"
						errors={errors}
					/>

					<InputTextRequired
						fieldName="numberOfTankers"
						labelText="Number of tankers"
						placeholder="i.e. 1"
						register={register}
						requiredText="Enter the number"
						errors={errors}
					/>

					<YesNoRequired
						fieldName={"areRoadsPavedAccessibleYearRound"}
						labelText={"Are roads paved and accessible year-round?"}
						register={register}
						errors={errors}
					/>

					<YesNoRequiredWithExtra
						fieldName="anyPhysicalBarriers"
						labelText="Any physical barriers?"
						describeName="describeAnyPhysicalBarriers"
						placeholderText="i.e. Gate to property"
						register={register}
						errors={errors}
						watch={watch}
					/>

					<SelectDisabled
						fieldName="fireHydrantWithin-disabled"
						labelText="Fire hydrant within 1,000 feet of the premises?"
						value={watchFireHydrant}
						optionsList={["Yes", "No"]}
					/>

					<YesNoRequired
						fieldName="isAnotherWaterSource"
						labelText="Is there another accessible water source nearby?"
						register={register}
						errors={errors}
					/>

					{
						isShowDescribeWaterSource &&
						(<div className={classNames(styles["appearing-field"], styles["grid-item-wide-3"], styles["grid-container"])}>

							<InputTextRequired
								fieldName="describeWaterSource"
								labelText="Describe the water source"
								placeholder="i.e. Lake"
								register={register}
								requiredText="Enter the description"
								errors={errors}
							/>

							<InputTextRequired
								fieldName="distanceOfWaterSource"
								labelText="Distance of water source from dwelling"
								placeholder="i.e. 300 feet"
								register={register}
								requiredText="Enter the distance"
								errors={errors}
							/>

							<YesNoRequiredRow
								fieldName="isWaterSourceAccessibleYearRound"
								labelText="Is water source accessible by the Fire Department year-round?"
								register={register}
								errors={errors}
							/>
						</div>)
					}

					<YesNoRequiredWithExtra
						fieldName="anyFullTimeEmployees"
						labelText="Any full-time or live-in employees?"
						describeName="describeAnyFullTimeEmployees"
						placeholderText="i.e. Full-time babysitter"
						register={register}
						errors={errors}
						watch={watch}
					/>

					<YesNoRequired
						fieldName="isOccupiedYearRound"
						labelText="Is dwelling occupied year-round?"
						register={register}
						errors={errors}
					/>

					{
						isShowDescribeOccupiedYearRound &&
						<TextareaRequired
							fieldName="describeIsOccupiedYearRound"
							labelText="Describe"
							placeholder="i.e. Not occupied during the summer period"
							register={register}
							isAppearing={true}
							requiredText="Enter the description"
							errors={errors}
						/>
					}

					<TextareaOptional
						fieldName="protectionHighComments"
						labelText="Comments"
						placeholder="i.e. Additional information"
						register={register}
						isAppearing={false}
					/>

				</div>)
			}
		</>
	);
};
