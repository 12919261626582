import { useAppSelector } from "../../store/hookTypes";
import { PreviousInsuranceCard } from "../PreviousInsuranceCard/PreviousInsuranceCard";
import styles from "./PreviousInsuranceList.module.css";

export const PreviousInsuranceList = () => {
	const formPreviousInsuranceList = useAppSelector((state) => state.newQuote.formPreviousInsuranceList);

	return (
		<div className={styles["list-container"]}>
			{
				formPreviousInsuranceList.map((entry) => (<PreviousInsuranceCard
					key={entry.insuranceId}
					id={entry.insuranceId}
					companyName={entry.insuranceCompanyName}
					policyNumber={entry.insurancePolicyNumber}
					effectiveDate={entry.insuranceEffectiveDate}
					expirationDate={entry.insuranceExpirationDate}
				/>))
			}
		</div>
	);
};
