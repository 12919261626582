import classNames from "classnames";
import { ErrorMessage } from "../ErrorMessage/ErrorMessage";
import styles from "../FormSteps/FormSteps.module.css";
import { FormEditLossProps, FormLossValues } from "../../models/models";
import { useState, MouseEvent, FormEvent, ChangeEvent, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hookTypes";
import { editLossEntry } from "../../store/newQuoteSlice";
import { lossTypeList } from '../../data/lossTypeList';
import { NoteOneRow } from "../NoteOneRow/NoteOneRow";

export const FormEditLoss = ({ idEntry, setIsModalOpen }: FormEditLossProps) => {
	const { formLossList } = useAppSelector((state) => state.newQuote);
	const entryInfo = formLossList.find((item) => item.lossId === idEntry);

	const [isWrongDate, setIsWrongDate] = useState(false);
	const [isWrongType, setIsWrongType] = useState(false);
	const [isWrongClaimStatus, setIsWrongClaimStatus] = useState(false);
	const [isWrongInsuranceCarrier, setIsWrongInsuranceCarrier] = useState(false);
	const [isWrongReserveAmount, setIsWrongReserveAmount] = useState(false);
	const [isWrongDigitReserveAmount, setIsWrongDigitReserveAmount] = useState(false);
	const [isWrongAmountPaid, setIsWrongAmountPaid] = useState(false);
	const [isWrongDigitAmountPaid, setIsWrongDigitAmountPaid] = useState(false);
	const [isWrongLossApprovedByMGA, setIsWrongLossApprovedByMGA] = useState(false);
	const [isWrongDescription, setIsWrongDescription] = useState(false);
	const [isWrongDigitPolicyPremium, setIsWrongDigitPolicyPremium] = useState(false);

	const [formData, setFormData] = useState<FormLossValues>(entryInfo as FormLossValues);
	console.log('FormLossValues INITIAL=', formData);

	const dispatch = useAppDispatch();

	useEffect(() => {
		validateFields();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formData]);

	const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
		const { name, value } = event.target;

		setFormData((prev) => {
			return {
				...prev,
				[name]: value,
			};
		});
	};

	const validateFields = () => {
		let isValid = true;

		if (formData.lossDate.length === 0) {
			setIsWrongDate(true);
			isValid = false;
		} else {
			setIsWrongDate(false);
		};
		if (formData.lossType.length === 0) {
			setIsWrongType(true);
			isValid = false;
		} else {
			setIsWrongType(false);
		};
		if (formData.claimStatus.length === 0) {
			setIsWrongClaimStatus(true);
			isValid = false;
		} else {
			setIsWrongClaimStatus(false);
		};
		if (formData.insuranceCarrier.length === 0) {
			setIsWrongInsuranceCarrier(true);
			isValid = false;
		} else {
			setIsWrongInsuranceCarrier(false);
		};
		if (formData.reserveAmount.length === 0) {
			setIsWrongReserveAmount(true);
			isValid = false;
		} else {
			setIsWrongReserveAmount(false);
		};
		if (formData.amountPaid.length === 0) {
			setIsWrongAmountPaid(true);
			isValid = false;
		} else {
			setIsWrongAmountPaid(false);
		};
		if (formData.lossApprovedByMGA.length === 0) {
			setIsWrongLossApprovedByMGA(true);
			isValid = false;
		} else {
			setIsWrongLossApprovedByMGA(false);
		};
		if (formData.descriptionOfOccurrence.length === 0) {
			setIsWrongDescription(true);
			isValid = false;
		} else {
			setIsWrongDescription(false);
		};
		if (!(/^\d+$/g.test(formData.reserveAmount))) {
			setIsWrongDigitReserveAmount(true);
			isValid = false;
		} else {
			setIsWrongDigitReserveAmount(false);
		};
		if (!(/^\d+$/g.test(formData.amountPaid))) {
			setIsWrongDigitAmountPaid(true);
			isValid = false;
		} else {
			setIsWrongDigitAmountPaid(false);
		};
		// Policy Premium is optional, so we check it for digits only if it is not empty
		if (!(/^\d+$/g.test(formData.lossPolicyPremium)) && formData.lossPolicyPremium.length > 0) {
			setIsWrongDigitPolicyPremium(true);
			isValid = false;
		} else {
			setIsWrongDigitPolicyPremium(false);
		};

		return isValid;
	};

	const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		event.stopPropagation();

		console.log('FormLossValues EDITED=', formData);

		if (validateFields()) {
			dispatch(editLossEntry({ lossId: idEntry, formLossValues: { ...formData } }));
			setIsModalOpen(false);
		} else {
			console.log('validateFields: false');
		};
	};

	const handleCancel = (event: MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		setIsModalOpen(false);
	};

	return (
		<form
			onSubmit={handleSubmit}
			className={styles["form-modal"]}
			id="formAddLoss"
		>
			<div className={styles["grid-container-modal-3"]}>

				<div className={styles["grid-item"]}>
					<label htmlFor="lossDate" className={styles["form-field-label"]}>
						Date of Loss
					</label>
					<div>
						<input
							value={formData.lossDate}
							onChange={handleChange}
							type="date"
							maxLength={250}
							name="lossDate"
							placeholder=""
							className={classNames(styles["form-text-field"], styles["w-input"], isWrongDate && styles["error"])}
							id="lossDate"></input>
						{<ErrorMessage errorMessage={isWrongDate ? 'Enter the date' : ''} />}
					</div>
				</div>

				<div className={classNames(styles["grid-item"], styles["grid-item-wide-right"])}>
					<label htmlFor="lossType" className={styles["form-field-label"]}>
						Type of loss
					</label>
					<div>
						<select
							value={formData.lossType}
							onChange={handleChange}
							name="lossType"
							className={classNames(styles["dropdown-field"], styles["w-select"], isWrongType && styles["error"])}
							id="lossType">
							<option value="">-- Choose --</option>
							{lossTypeList.map((item) => (<option key={item} value={item}>{item}</option>))}
						</select>
						{<ErrorMessage errorMessage={isWrongType ? 'Choose the type' : ''} />}
					</div>
				</div>

				<div className={styles["grid-item"]}>
					<label htmlFor="claimStatus" className={styles["form-field-label"]}>
						Claim Status
					</label>
					<div>
						<select
							value={formData.claimStatus}
							onChange={handleChange}
							name="claimStatus"
							className={classNames(styles["dropdown-field"], styles["w-select"], isWrongClaimStatus && styles["error"])}
							id="claimStatus">
							<option value="">-- Choose --</option>
							<option value="Open">Open</option>
							<option value="Closed">Closed</option>
						</select>
						{<ErrorMessage errorMessage={isWrongClaimStatus ? 'Choose the status' : ''} />}
					</div>
				</div>

				<div className={classNames(styles["grid-item"], styles["grid-item-wide-right"])}>
					<label htmlFor="insuranceCarrier" className={styles["form-field-label"]}>
						Insurance Carrier
					</label>
					<div>
						<input
							value={formData.insuranceCarrier}
							onChange={handleChange}
							type="text"
							maxLength={250}
							name="insuranceCarrier"
							className={classNames(styles["form-text-field"], styles["w-input"], isWrongInsuranceCarrier && styles["error"])}
							id="insuranceCarrier"></input>
						{<ErrorMessage errorMessage={isWrongInsuranceCarrier ? 'Enter the name' : ''} />}
					</div>
				</div>

				<div className={styles["grid-item"]}>
					<label htmlFor="lossPolicyEffectiveDate" className={styles["form-field-label"]}>
						Policy Effective Date
					</label>
					<div>
						<input
							value={formData.lossPolicyEffectiveDate}
							onChange={handleChange}
							type="date"
							maxLength={250}
							name="lossPolicyEffectiveDate"
							placeholder=""
							className={classNames(styles["form-text-field"], styles["w-input"], styles["optional-field"])}
							id="lossPolicyEffectiveDate"></input>
						{<NoteOneRow text="Optional" />}
					</div>
				</div>

				<div className={styles["grid-item"]}>
					<label htmlFor="lossPolicyExpirationDate" className={styles["form-field-label"]}>
						Policy Expiration Date
					</label>
					<div>
						<input
							value={formData.lossPolicyExpirationDate}
							onChange={handleChange}
							type="date"
							maxLength={250}
							name="lossPolicyExpirationDate"
							placeholder=""
							className={classNames(styles["form-text-field"], styles["w-input"], styles["optional-field"])}
							id="lossPolicyExpirationDate"></input>
						{<NoteOneRow text="Optional" />}
					</div>
				</div>

				<div className={styles["grid-item"]}>
					<label htmlFor="lossReportedDate" className={styles["form-field-label"]}>
						Reported Date
					</label>
					<div>
						<input
							value={formData.lossReportedDate}
							onChange={handleChange}
							type="date"
							maxLength={250}
							name="lossReportedDate"
							placeholder=""
							className={classNames(styles["form-text-field"], styles["w-input"], styles["optional-field"])}
							id="lossReportedDate"></input>
						{<NoteOneRow text="Optional" />}
					</div>
				</div>

				<div className={styles["grid-item"]}>
					<label htmlFor="reserveAmount" className={styles["form-field-label"]}>
						Reserve Amount
					</label>
					<div>
						<input
							value={formData.reserveAmount}
							onChange={handleChange}
							type="text"
							maxLength={250}
							name="reserveAmount"
							className={classNames(styles["form-text-field"], styles["w-input"], (isWrongReserveAmount || isWrongDigitReserveAmount) && styles["error"])}
							id="reserveAmount"></input>
						{<ErrorMessage errorMessage={isWrongReserveAmount ? 'Enter the amount' :
							isWrongDigitReserveAmount ? 'Reserve Amount must contain only digits' :
								''} />}
					</div>
				</div>

				<div className={styles["grid-item"]}>
					<label htmlFor="amountPaid" className={styles["form-field-label"]}>
						Amount Paid
					</label>
					<div>
						<input
							value={formData.amountPaid}
							onChange={handleChange}
							type="text"
							maxLength={250}
							name="amountPaid"
							className={classNames(styles["form-text-field"], styles["w-input"], (isWrongAmountPaid || isWrongDigitAmountPaid) && styles["error"])}
							id="amountPaid"></input>
						{<ErrorMessage errorMessage={isWrongAmountPaid ? 'Enter the amount' :
							isWrongDigitAmountPaid ? 'Amount Paid must contain only digits' :
								''} />}
					</div>
				</div>

				<div className={styles["grid-item"]}>
					<label htmlFor="lossPolicyPremium" className={styles["form-field-label"]}>
						Policy Premium
					</label>
					<div>
						<input
							value={formData.lossPolicyPremium}
							onChange={handleChange}
							type="text"
							maxLength={250}
							name="lossPolicyPremium"
							className={classNames(styles["form-text-field"], styles["w-input"], styles["optional-field"], isWrongDigitPolicyPremium && styles["error"])}
							id="lossPolicyPremium"></input>
						{
							isWrongDigitPolicyPremium ?
								(<ErrorMessage errorMessage={'Policy Premium must contain only digits'} />) :
								(<NoteOneRow text="Optional" />)
						}
					</div>
				</div>

				<div className={classNames(styles["grid-item"], styles["grid-item-wide-3"])}>
					<label htmlFor="descriptionOfOccurrence" className={styles["form-field-label"]}>
						Description of Occurrence or Claim
					</label>
					<textarea
						value={formData.descriptionOfOccurrence}
						onChange={handleChange}
						name="descriptionOfOccurrence"
						rows={2}
						className={classNames(styles["form-text-field"], styles["w-input"], isWrongDescription && styles["error"])}
						id="descriptionOfOccurrence"></textarea>
					{<ErrorMessage errorMessage={isWrongDescription ? 'Describe the occurrence' : ''} />}
				</div>

				<div className={styles["grid-item"]}>
					<label htmlFor="lossApprovedByMGA" className={styles["form-field-label"]}>
						Loss approved by MGA?
					</label>
					<div>
						<select
							value={formData.lossApprovedByMGA}
							onChange={handleChange}
							name="lossApprovedByMGA"
							className={classNames(styles["dropdown-field"], styles["w-select"], isWrongLossApprovedByMGA && styles["error"])}
							id="lossApprovedByMGA">
							<option value="">-- Choose --</option>
							<option value="Yes">Yes</option>
							<option value="No">No</option>
						</select>
						{<ErrorMessage errorMessage={isWrongLossApprovedByMGA ? 'Choose the option' : ''} />}
					</div>
				</div>

				<div className={classNames(styles["grid-item"], styles["grid-item-wide-right"])}>
					<label htmlFor="lossComments" className={styles["form-field-label"]}>
						Comments
					</label>
					<div>
						<input
							value={formData.lossComments}
							onChange={handleChange}
							type="text"
							maxLength={250}
							name="lossComments"
							className={classNames(styles["form-text-field"], styles["w-input"], styles["optional-field"])}
							id="lossComments"></input>
						{<NoteOneRow text="Optional" />}
					</div>
				</div>

			</div>

			<div className={styles["box-row-center"]}>
				<button type="submit" className={styles["create-button"]}>Edit</button>
				<button type="button" className={styles["cancel-button"]} onClick={handleCancel}>Cancel</button>
			</div>
		</form>
	);
};
