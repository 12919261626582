import classNames from "classnames";
import { ErrorMessage } from "../ErrorMessage/ErrorMessage";
import styles from "../FormSteps/FormSteps.module.css";
import { FormAddEntryProps, FormLienholderValues } from "../../models/models";
import { useState, MouseEvent, FormEvent, ChangeEvent, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../store/hookTypes";
import { setFormLienholderList } from "../../store/newQuoteSlice";
import { rankList } from "../../data/rankList";
import { NoteOneRow } from "../NoteOneRow/NoteOneRow";

export const FormAddLienholder = ({ setIsModalOpen }: FormAddEntryProps) => {
	const { formLienholderLossPayeeList, formLienholderMortgageeList, formLienholderLendersList, formLienholderContractList } = useAppSelector((state) => state.newQuote);

	const rangeLossPayee = formLienholderLossPayeeList.length + 1;
	const rangeMortgagee = formLienholderMortgageeList.length + 1;
	const rangeLenders = formLienholderLendersList.length + 1;
	const rangeContract = formLienholderContractList.length + 1;

	const [currentType, setCurrentType] = useState('');

	const [isWrongName, setIsWrongName] = useState(false);
	const [isWrongType, setIsWrongType] = useState(false);
	const [isWrongRank, setIsWrongRank] = useState(false);
	const [isWrongStreet, setIsWrongStreet] = useState(false);
	const [isWrongCity, setIsWrongCity] = useState(false);
	const [isWrongState, setIsWrongState] = useState(false);
	const [isWrongZipCode, setIsWrongZipCode] = useState(false);
	const [isWrongDigitZipCode, setIsWrongDigitZipCode] = useState(false);
	const [isWrongLengthZipCode, setIsWrongLengthZipCode] = useState(false);

	const [counterForUseEffectValidation, setCounterForUseEffectValidation] = useState(0);

	let uuid = crypto.randomUUID(); // create a random UUID (it needs for the key of the entry)

	const [formData, setFormData] = useState<FormLienholderValues>({
		lienholderId: uuid,
		lienholderName: '',
		lienholderType: '',
		lienholderRank: '',
		lienholderItemDescription: '',
		lienholderLoanNumber: '',
		specificWording: '',
		lienholderStreet: '',
		lienholderStreet2: '',
		lienholderCity: '',
		lienholderState: '',
		lienholderZipCode: '',
	});

	const dispatch = useAppDispatch();

	useEffect(() => {
		if (counterForUseEffectValidation > 0) {
			validateFields();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formData]);

	const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
		const { name, value } = event.target;
		if (name === 'lienholderType') {
			setCurrentType(value);
		};

		setFormData((prev) => {
			return {
				...prev,
				[name]: value,
			};
		});
	};

	const validateFields = () => {
		let isValid = true;

		if (formData.lienholderName.length === 0) {
			setIsWrongName(true);
			isValid = false;
		} else {
			setIsWrongName(false);
		};
		if (formData.lienholderType.length === 0) {
			setIsWrongType(true);
			isValid = false;
		} else {
			setIsWrongType(false);
		};
		if (formData.lienholderRank.length === 0) {
			setIsWrongRank(true);
			isValid = false;
		} else {
			setIsWrongRank(false);
		};
		if (formData.lienholderStreet.length === 0) {
			setIsWrongStreet(true);
			isValid = false;
		} else {
			setIsWrongStreet(false);
		};
		if (formData.lienholderCity.length === 0) {
			setIsWrongCity(true);
			isValid = false;
		} else {
			setIsWrongCity(false);
		};
		if (formData.lienholderState.length === 0) {
			setIsWrongState(true);
			isValid = false;
		} else {
			setIsWrongState(false);
		};
		if (formData.lienholderZipCode.length === 0) {
			setIsWrongZipCode(true);
			isValid = false;
		} else {
			setIsWrongZipCode(false);
		};
		if (!(/^\d+$/g.test(formData.lienholderZipCode))) {
			setIsWrongDigitZipCode(true);
			isValid = false;
		} else {
			setIsWrongDigitZipCode(false);
		};
		if (formData.lienholderZipCode.length > 0 && formData.lienholderZipCode.length < 5) {
			setIsWrongLengthZipCode(true);
			isValid = false;
		} else {
			setIsWrongLengthZipCode(false);
		};
		return isValid;
	};

	const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		event.stopPropagation();

		setCounterForUseEffectValidation((prev) => prev + 1);

		// console.log('FormLienholderValues=', formData);

		if (validateFields()) {
			dispatch(setFormLienholderList(formData));
			setIsModalOpen(false);
		} else {
			console.log('validateFields: false');
		};
	};

	const handleCancel = (event: MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		setIsModalOpen(false);
	};

	return (
		<form
			onSubmit={handleSubmit}
			className={styles["form-modal"]}
			id="formAddLienholder"
		>
			<div className={styles["grid-container-modal-3"]}>

				<div className={styles["grid-item"]}>
					<label htmlFor="lienholderName" className={styles["form-field-label"]}>
						Name
					</label>
					<div>
						<input
							value={formData.lienholderName}
							onChange={handleChange}
							type="text"
							maxLength={250}
							name="lienholderName"
							className={classNames(styles["form-text-field"], styles["w-input"], isWrongName && styles["error"])}
							id="lienholderName"></input>
						{<ErrorMessage errorMessage={isWrongName ? 'Enter the Name' : ''} />}
					</div>
				</div>

				<div className={styles["grid-item"]}>
					<label htmlFor="lienholderType" className={styles["form-field-label"]}>
						Type
					</label>
					<div>
						<select
							value={formData.lienholderType}
							onChange={handleChange}
							name="lienholderType"
							className={classNames(styles["dropdown-field"], styles["w-select"], isWrongType && styles["error"])}
							id="lienholderType">
							<option value="">-- Choose --</option>
							<option value="Loss Payee">Loss Payee</option>
							<option value="Mortgagee">Mortgagee</option>
							<option value="Lenders Loss Payable">Lenders Loss Payable</option>
							<option value="Contract of sale">Contract of sale</option>
						</select>
						{<ErrorMessage errorMessage={isWrongType ? 'Choose the type' : ''} />}
					</div>
				</div>

				<div className={styles["grid-item"]}>
					<label htmlFor="lienholderRank" className={styles["form-field-label"]}>
						Rank
					</label>
					<div>
						<select
							value={formData.lienholderRank}
							onChange={handleChange}
							name="lienholderRank"
							className={classNames(styles["dropdown-field"], styles["w-select"], isWrongRank && styles["error"])}
							id="lienholderRank">
							<option value="">-- Choose --</option>
							{
								currentType === 'Loss Payee' ?
									rankList.slice(0, rangeLossPayee).map((rank) => (<option key={rank} value={rank}>{rank}</option>)) :
									currentType === 'Mortgagee' ?
										rankList.slice(0, rangeMortgagee).map((rank) => (<option key={rank} value={rank}>{rank}</option>)) :
										currentType === 'Lenders Loss Payable' ?
											rankList.slice(0, rangeLenders).map((rank) => (<option key={rank} value={rank}>{rank}</option>)) :
											currentType === 'Contract of sale' ?
												rankList.slice(0, rangeContract).map((rank) => (<option key={rank} value={rank}>{rank}</option>)) :
												<option value="1st">1st</option>
							}
						</select>
						{<ErrorMessage errorMessage={isWrongRank ? 'Choose the rank' : ''} />}
					</div>
				</div>

				<div className={styles["grid-item"]}>
					<label htmlFor="lienholderItemDescription" className={styles["form-field-label"]}>
						Item Description
					</label>
					<div>
						<input
							value={formData.lienholderItemDescription}
							onChange={handleChange}
							type="text"
							maxLength={250}
							name="lienholderItemDescription"
							className={classNames(styles["form-text-field"], styles["w-input"], styles["optional-field"])}
							id="lienholderItemDescription"></input>
						{<NoteOneRow text="Optional" />}
					</div>
				</div>

				<div className={styles["grid-item"]}>
					<label htmlFor="lienholderLoanNumber" className={styles["form-field-label"]}>
						Loan Number
					</label>
					<div>
						<input
							value={formData.lienholderLoanNumber}
							onChange={handleChange}
							type="text"
							maxLength={250}
							name="lienholderLoanNumber"
							className={classNames(styles["form-text-field"], styles["w-input"], styles["optional-field"])}
							id="lienholderLoanNumber"></input>
						{<NoteOneRow text="Optional" />}
					</div>
				</div>

				<div className={classNames(styles["grid-item"], styles["grid-item-wide-3"])}>
					<label htmlFor="specificWording" className={styles["form-field-label"]}>
						Specific Wording
					</label>
					<textarea
						value={formData.specificWording}
						onChange={handleChange}
						name="specificWording"
						rows={2}
						className={classNames(styles["form-text-field"], styles["w-input"], styles["optional-field"])}
						id="specificWording"></textarea>
					{<NoteOneRow text="Optional" />}
				</div>

				<div className={styles["grid-item"]}>
					<label htmlFor="lienholderStreet" className={styles["form-field-label"]}>
						Mailing Address - Street
					</label>
					<div>
						<input
							value={formData.lienholderStreet}
							onChange={handleChange}
							type="text"
							maxLength={250}
							name="lienholderStreet"
							className={classNames(styles["form-text-field"], styles["w-input"], isWrongStreet && styles["error"])}
							id="lienholderStreet"></input>
						{<ErrorMessage errorMessage={isWrongStreet ? 'Enter the street' : ''} />}
					</div>
				</div>

				<div className={styles["grid-item"]}>
					<label htmlFor="lienholderStreet2" className={styles["form-field-label"]}>
						Mailing Address - Street 2
					</label>
					<div>
						<input
							value={formData.lienholderStreet2}
							onChange={handleChange}
							type="text"
							maxLength={250}
							name="lienholderStreet2"
							className={classNames(styles["form-text-field"], styles["w-input"], styles["optional-field"])}
							id="lienholderStreet2"></input>
						{<NoteOneRow text="Optional" />}
					</div>
				</div>

				<div className={styles["grid-item"]}>
					<label htmlFor="lienholderCity" className={styles["form-field-label"]}>
						Mailing Address - City
					</label>
					<div>
						<input
							value={formData.lienholderCity}
							onChange={handleChange}
							type="text"
							maxLength={250}
							name="lienholderCity"
							className={classNames(styles["form-text-field"], styles["w-input"], isWrongCity && styles["error"])}
							id="lienholderCity"></input>
						{<ErrorMessage errorMessage={isWrongCity ? 'Enter the city' : ''} />}
					</div>
				</div>

				<div className={styles["grid-item"]}>
					<label htmlFor="lienholderState" className={styles["form-field-label"]}>
						Mailing Address - State
					</label>
					<div>
						<select
							value={formData.lienholderState}
							onChange={handleChange}
							name="lienholderState"
							className={classNames(styles["dropdown-field"], styles["w-select"], isWrongState && styles["error"])}
							id="lienholderState">
							<option value="">-- Choose --</option>
							<option value="N/A">N/A</option>
							<option value="INTL">INTL</option>
							<option value="AK-US">AK-US</option>
							<option value="AL-US">AL-US</option>
							<option value="AR-US">AR-US</option>
							<option value="AZ-US">AZ-US</option>
							<option value="CA-US">CA-US</option>
							<option value="CO-US">CO-US</option>
							<option value="CT-US">CT-US</option>
							<option value="DC-US">DC-US</option>
							<option value="DE-US">DE-US</option>
							<option value="FL-US">FL-US</option>
							<option value="GA-US">GA-US</option>
							<option value="HI-US">HI-US</option>
							<option value="IA-US">IA-US</option>
							<option value="ID-US">ID-US</option>
							<option value="IL-US">IL-US</option>
							<option value="IN-US">IN-US</option>
							<option value="KS-US">KS-US</option>
							<option value="KY-US">KY-US</option>
							<option value="LA-US">LA-US</option>
							<option value="MA-US">MA-US</option>
							<option value="MD-US">MD-US</option>
							<option value="ME-US">ME-US</option>
							<option value="MI-US">MI-US</option>
							<option value="MN-US">MN-US</option>
							<option value="MO-US">MO-US</option>
							<option value="MS-US">MS-US</option>
							<option value="MT-US">MT-US</option>
							<option value="NC-US">NC-US</option>
							<option value="ND-US">ND-US</option>
							<option value="NE-US">NE-US</option>
							<option value="NH-US">NH-US</option>
							<option value="NJ-US">NJ-US</option>
							<option value="NM-US">NM-US</option>
							<option value="NV-US">NV-US</option>
							<option value="NY-US">NY-US</option>
							<option value="OH-US">OH-US</option>
							<option value="OK-US">OK-US</option>
							<option value="OR-US">OR-US</option>
							<option value="PA-US">PA-US</option>
							<option value="PR-US">PR-US</option>
							<option value="RI-US">RI-US</option>
							<option value="SC-US">SC-US</option>
							<option value="SD-US">SD-US</option>
							<option value="TN-US">TN-US</option>
							<option value="TX-US">TX-US</option>
							<option value="USVI-US">USVI-US</option>
							<option value="UT-US">UT-US</option>
							<option value="VA-US">VA-US</option>
							<option value="VT-US">VT-US</option>
							<option value="WA-US">WA-US</option>
							<option value="WI-US">WI-US</option>
							<option value="WV-US">WV-US</option>
							<option value="WY-US">WY-US</option>
							<option value="AB-CA">AB-CA</option>
							<option value="BC-CA">BC-CA</option>
							<option value="MB-CA">MB-CA</option>
							<option value="NB-CA">NB-CA</option>
							<option value="NL-CA">NL-CA</option>
							<option value="NS-CA">NS-CA</option>
							<option value="NT-CA">NT-CA</option>
							<option value="NU-CA">NU-CA</option>
							<option value="ON-CA">ON-CA</option>
							<option value="PE-CA">PE-CA</option>
							<option value="QC-CA">QC-CA</option>
							<option value="SK-CA">SK-CA</option>
							<option value="XQ-CA">XQ-CA</option>
							<option value="YT-CA">YT-CA</option>
						</select>
						{<ErrorMessage errorMessage={isWrongState ? 'Choose the State' : ''} />}
					</div>
				</div>

				<div className={styles["grid-item"]}>
					<label htmlFor="lienholderZipCode" className={styles["form-field-label"]}>
						Mailing Address - Zip Code
					</label>
					<div>
						<input
							value={formData.lienholderZipCode}
							onChange={handleChange}
							type="text"
							maxLength={5}
							name="lienholderZipCode"
							className={classNames(styles["form-text-field"], styles["w-input"], (isWrongZipCode || isWrongDigitZipCode || isWrongLengthZipCode) && styles["error"])}
							id="lienholderZipCode"></input>
						{<ErrorMessage errorMessage={isWrongZipCode ? 'Enter zip code' :
							isWrongDigitZipCode ? 'Zip Code must contain only digits' :
								isWrongLengthZipCode ? 'Zip Code must contain 5 digits' :
									''} />}
					</div>
				</div>

			</div>

			<div className={styles["box-row-center"]}>
				<button type="submit" className={styles["create-button"]}>Add</button>
				<button type="button" className={styles["cancel-button"]} onClick={handleCancel}>Cancel</button>
			</div>
		</form>
	);
};
