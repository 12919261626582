import { configureStore } from '@reduxjs/toolkit';
import { mainApiQueries } from './mainApiQueries';
import { newQuoteReducer } from './newQuoteSlice';
import { authorizationReducer } from './authorizationSlice';
import { locationWithZipReducer } from './locationWithZipSlice';
import { secondApiQueries } from './secondApiQueries';
import { signatureReducer } from './signatureSlice';
import { checkedAddressReducer } from './checkedAddressSlice';

export const store = configureStore({
	reducer: {
		[mainApiQueries.reducerPath]: mainApiQueries.reducer,
		[secondApiQueries.reducerPath]: secondApiQueries.reducer,
		newQuote: newQuoteReducer,
		authorization: authorizationReducer,
		locationWithZip: locationWithZipReducer,
		signature: signatureReducer,
		checkedAddress: checkedAddressReducer,
	},
	middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(mainApiQueries.middleware, secondApiQueries.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
