import { InputEmailRequiredProps } from "../../models/models";
import { ErrorMessage } from "../ErrorMessage/ErrorMessage";
import { InputTextBase } from "../InputText/InputTextBase";

export const InputEmailRequired = ({ fieldName, labelText, placeholder, register, errors }: InputEmailRequiredProps) => {
	return (
		<InputTextBase
			fieldName={fieldName}
			labelText={labelText}
			placeholder={placeholder}
			register={register}
			requiredText={"Enter the email"}
			errors={errors}
			maxLength={100}
			validation={
				{
					pattern: {
						// eslint-disable-next-line no-useless-escape
						value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
						message: "Wrong email format"
					},
				}
			}
		>
			<ErrorMessage errorMessage={errors[fieldName] ? (errors[fieldName]?.message as string) : ''} />
		</InputTextBase >
	);
};
