import classNames from "classnames";
import styles from "../FormSteps/FormSteps.module.css";
import { InputPhoneTripleBaseProps } from "../../models/models";
import { useEffect } from "react";

export const InputPhoneTripleBase = ({
	children,
	fieldName1,
	fieldName2,
	fieldName3,
	labelText,
	watch,
	setFocus,
	register,
	requiredText,
	errors }: InputPhoneTripleBaseProps) => {
	const watchPhoneNumber1 = watch(fieldName1);
	const watchPhoneNumber2 = watch(fieldName2);

	useEffect(() => {
		if (watchPhoneNumber1.length === 3) {
			setFocus(fieldName2);
		};
	}, [watchPhoneNumber1, setFocus, fieldName2]);

	useEffect(() => {
		if (watchPhoneNumber2.length === 3) {
			setFocus(fieldName3);
		};
	}, [watchPhoneNumber2, setFocus, fieldName3]);

	return (
		<div className={classNames(styles["grid-item"], styles["grid-item-wide"])}>
			<label htmlFor={fieldName1} className={styles["form-field-label"]}>
				{labelText}
			</label>
			<div>
				<div className={styles["phone-number-field"]}>
					<input
						{...register(fieldName1, {
							required: requiredText ?? false, // requiredText is optional
							pattern: {
								// eslint-disable-next-line no-useless-escape
								value: /^\d+$/g,
								message: "Phone number must contain only digits"
							},
							minLength: {
								value: 3,
								message: "Wrong phone format"
							},
						})}
						type="text"
						maxLength={3}
						name={fieldName1}
						placeholder="i.e. 123"
						className={
							requiredText ?
								classNames(styles["form-text-field"], styles["w-input"], styles["phone"], errors[fieldName1] && styles["error"]) :
								classNames(styles["form-text-field"], styles["w-input"], styles["phone"], styles["optional-field"], errors[fieldName1] && styles["error"])
						}
						id={fieldName1}>
					</input>
					<input
						{...register(fieldName2, {
							required: requiredText ?? false, // requiredText is optional
							pattern: {
								// eslint-disable-next-line no-useless-escape
								value: /^\d+$/g,
								message: "Phone number must contain only digits"
							},
							minLength: {
								value: 3,
								message: "Wrong phone format"
							},
						})}
						type="text"
						maxLength={3}
						name={fieldName2}
						placeholder="i.e. 123"
						className={
							requiredText ?
								classNames(styles["form-text-field"], styles["w-input"], styles["phone"], errors[fieldName2] && styles["error"]) :
								classNames(styles["form-text-field"], styles["w-input"], styles["phone"], styles["optional-field"], errors[fieldName2] && styles["error"])
						}
						id={fieldName2}>
					</input>
					<input
						{...register(fieldName3, {
							required: requiredText ?? false, // requiredText is optional
							pattern: {
								// eslint-disable-next-line no-useless-escape
								value: /^\d+$/g,
								message: "Phone number must contain only digits"
							},
							minLength: {
								value: 4,
								message: "Wrong phone format"
							},
						})}
						type="text"
						maxLength={4}
						name={fieldName3}
						placeholder="i.e. 1234"
						className={
							requiredText ?
								classNames(styles["form-text-field"], styles["w-input"], styles["phone"], errors[fieldName3] && styles["error"]) :
								classNames(styles["form-text-field"], styles["w-input"], styles["phone"], styles["optional-field"], errors[fieldName3] && styles["error"])
						}
						id={fieldName3}>
					</input>
				</div>
				{children}
			</div>
		</div>
	)
};
