import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BASE_URL, POSTFIX_RATE, BASE_USERNAME, BASE_PASSWORD } from '../constants';
import { FormForQuote, QuoteResponce } from '../models/models';

export const secondApiQueries = createApi({
	reducerPath: 'secondApiQueries',
	baseQuery: fetchBaseQuery({
		baseUrl: BASE_URL,
	}),
	endpoints: (builder) => ({
		//! <что-получаем-в-ответ-от-сервера, какой-параметр-принимаем-чтобы-осуществить-данный-запрос>
		createQuote: builder.mutation<QuoteResponce, FormForQuote>({
			query: (obj) => ({
				url: POSTFIX_RATE,
				method: 'POST',
				body: {
					username: BASE_USERNAME,
					password: BASE_PASSWORD,
					data: JSON.stringify(obj),
				},
			}),
		}),
	}),
});

export const { useCreateQuoteMutation } = secondApiQueries;
