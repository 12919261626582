import { useEffect, useState, ChangeEvent, FormEvent, MouseEvent } from "react";
import { FormEditPreviousInsuranceProps, FormPreviousInsuranceValues } from "../../models/models";
import styles from "../FormSteps/FormSteps.module.css";
import { useAppDispatch, useAppSelector } from "../../store/hookTypes";
import { ErrorMessage } from "../ErrorMessage/ErrorMessage";
import classNames from "classnames";
import { editPreviousInsuranceEntry } from "../../store/newQuoteSlice";
import { NoteOneRow } from "../NoteOneRow/NoteOneRow";

export const FormEditPreviousInsurance = ({ idEntry, setIsModalOpen }: FormEditPreviousInsuranceProps) => {
	const { formPreviousInsuranceList } = useAppSelector((state) => state.newQuote);
	const entryInfo = formPreviousInsuranceList.find((item) => item.insuranceId === idEntry);

	const [isWrongCompanyName, setIsWrongCompanyName] = useState(false);
	const [isWrongPolicyNumber, setIsWrongPolicyNumber] = useState(false);
	const [isWrongEffectiveDate, setIsWrongEffectiveDate] = useState(false);
	const [isWrongExpirationDate, setIsWrongExpirationDate] = useState(false);

	const [formData, setFormData] = useState<FormPreviousInsuranceValues>(entryInfo as FormPreviousInsuranceValues);
	// console.log('FormPreviousInsuranceValues INITIAL=', formData);

	const dispatch = useAppDispatch();

	useEffect(() => {
		validateFields();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formData]);

	const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		const { name, value } = event.target;

		setFormData((prev) => {
			return {
				...prev,
				[name]: value,
			};
		});
	};

	const validateFields = () => {
		let isValid = true;

		if (formData.insuranceCompanyName.length === 0) {
			setIsWrongCompanyName(true);
			isValid = false;
		} else {
			setIsWrongCompanyName(false);
		};
		if (formData.insurancePolicyNumber.length === 0) {
			setIsWrongPolicyNumber(true);
			isValid = false;
		} else {
			setIsWrongPolicyNumber(false);
		};
		if (formData.insuranceEffectiveDate.length === 0) {
			setIsWrongEffectiveDate(true);
			isValid = false;
		} else {
			setIsWrongEffectiveDate(false);
		};
		if (formData.insuranceExpirationDate.length === 0) {
			setIsWrongExpirationDate(true);
			isValid = false;
		} else {
			setIsWrongExpirationDate(false);
		};

		return isValid;
	};

	const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		event.stopPropagation();

		// console.log('FormPreviousInsurance EDITED=', formData);

		if (validateFields()) {
			dispatch(editPreviousInsuranceEntry({ previousInsuranceId: idEntry, formPreviousInsuranceValues: { ...formData } }));
			setIsModalOpen(false);
		} else {
			console.log('validateFields: false');
		};
	};

	const handleCancel = (event: MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		setIsModalOpen(false);
	};

	return (
		<form
			onSubmit={handleSubmit}
			className={styles["form-modal"]}
			id="formAddLoss"
		>
			<div className={styles["grid-container-modal"]}>

				<div className={styles["grid-item"]}>
					<label htmlFor="insuranceCompanyName" className={styles["form-field-label"]}>
						Company Name
					</label>
					<div>
						<input
							value={formData.insuranceCompanyName}
							onChange={handleChange}
							type="text"
							maxLength={250}
							name="insuranceCompanyName"
							className={classNames(styles["form-text-field"], styles["w-input"], isWrongCompanyName && styles["error"])}
							id="insuranceCompanyName"></input>
						{<ErrorMessage errorMessage={isWrongCompanyName ? 'Enter the name' : ''} />}
					</div>
				</div>

				<div className={styles["grid-item"]}>
					<label htmlFor="insurancePolicyNumber" className={styles["form-field-label"]}>
						Policy #
					</label>
					<div>
						<input
							value={formData.insurancePolicyNumber}
							onChange={handleChange}
							type="text"
							maxLength={250}
							name="insurancePolicyNumber"
							className={classNames(styles["form-text-field"], styles["w-input"], isWrongPolicyNumber && styles["error"])}
							id="insurancePolicyNumber"></input>
						{<ErrorMessage errorMessage={isWrongPolicyNumber ? 'Enter Policy number' : ''} />}
					</div>
				</div>

				<div className={styles["grid-item"]}>
					<label htmlFor="insuranceEffectiveDate" className={styles["form-field-label"]}>
						Effective Date
					</label>
					<div>
						<input
							value={formData.insuranceEffectiveDate}
							onChange={handleChange}
							type="date"
							maxLength={250}
							name="insuranceEffectiveDate"
							placeholder=""
							className={classNames(styles["form-text-field"], styles["w-input"], isWrongEffectiveDate && styles["error"])}
							id="insuranceEffectiveDate"></input>
						{<ErrorMessage errorMessage={isWrongEffectiveDate ? 'Enter the date' : ''} />}
					</div>
				</div>

				<div className={styles["grid-item"]}>
					<label htmlFor="insuranceExpirationDate" className={styles["form-field-label"]}>
						Expiration Date
					</label>
					<div>
						<input
							value={formData.insuranceExpirationDate}
							onChange={handleChange}
							type="date"
							maxLength={250}
							name="insuranceExpirationDate"
							placeholder=""
							className={classNames(styles["form-text-field"], styles["w-input"], isWrongExpirationDate && styles["error"])}
							id="insuranceExpirationDate"></input>
						{<ErrorMessage errorMessage={isWrongExpirationDate ? 'Enter the date' : ''} />}
					</div>
				</div>

				<div className={styles["grid-item"]}>
					<label htmlFor="insurancePremiumTotal" className={styles["form-field-label"]}>
						Premium Total
					</label>
					<div>
						<input
							value={formData.insurancePremiumTotal}
							onChange={handleChange}
							type="text"
							maxLength={250}
							name="insurancePremiumTotal"
							className={classNames(styles["form-text-field"], styles["w-input"], styles["optional-field"])}
							id="insurancePremiumTotal"></input>
						{<NoteOneRow text="Optional" />}
					</div>
				</div>

			</div>

			<div className={styles["box-row-center"]}>
				<button type="submit" className={styles["create-button"]}>Edit</button>
				<button type="button" className={styles["cancel-button"]} onClick={handleCancel}>Cancel</button>
			</div>
		</form>
	);
};
