import styles from './FormSteps.module.css';
import { createPortal } from "react-dom";
import { useForm, SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { FormStep1Values } from '../../models/models';
import { useAppSelector, useAppDispatch } from '../../store/hookTypes';
import { setFormStep1 } from '../../store/newQuoteSlice';
import { useLazyGetLocationWithZipQuery } from '../../store/mainApiQueries';
import { setLocationWithZip } from '../../store/locationWithZipSlice';
import { rootPortal } from '../../index';
import { Loader } from '../Loader/Loader';
import { InputTextRequired } from '../InputText/InputTextRequired';
import { InputTextOptional } from '../InputText/InputTextOptional';
import { InputDateRequired } from '../InputDate/InputDateRequired';
import { InputEmailRequired } from '../InputEmail/InputEmailRequired';
import { InputPhoneTripleRequired } from '../InputPhone/InputPhoneTripleRequired';
import { InputZipWithZip4 } from '../InputZip/InputZipWithZip4';

//TODO: обработка isError при помощи ErrorBoundary
export const FormStep1 = () => {
	const { formStep1 } = useAppSelector((state) => state.newQuote);

	const [isWrongZip, setIsWrongZip] = useState(false);

	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const [getLocationWithZip, { data: dataLocation, isLoading, isFetching, isError }] = useLazyGetLocationWithZipQuery();

	const {
		register,
		formState: { errors },
		handleSubmit,
		watch,
		setValue,
		setFocus,
	} = useForm<FormStep1Values>({
		mode: 'onSubmit',
		defaultValues: formStep1,
	});

	useEffect(() => {
		if (!formStep1.effectiveDate) {
			setValue("effectiveDate", new Date().toISOString().slice(0, 10));
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (dataLocation) {
			if (dataLocation.state.short === 'FL' || dataLocation.state.short === 'LA') {
				console.log('dataLocation in FormStep1=', dataLocation);
				dispatch(setLocationWithZip(dataLocation)); // saving location data to the store
				navigate('/new-quote-step2');
			} else {
				console.log('Wrong state', dataLocation.state.short);
				setIsWrongZip(true);
			};
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dataLocation]);

	const onSubmit: SubmitHandler<FormStep1Values> = (data) => {
		getLocationWithZip(data.zipCode);
		// if (dataLocation) {
		// 	dispatch(setLocationWithZip(dataLocation)); // тут сохранение ещё не срабатывает, нужен useEffect
		// };
		console.log('FormStep1=', data);
		dispatch(setFormStep1(data));
	};

	if (isError) {
		console.log('Error in FormStep1 with getLocationWithZip');
	};

	return (
		<>
			<form
				onSubmit={handleSubmit(onSubmit)}
				className={styles["form"]}
				id="form1"
			>

				<div className={styles["grid-container-3"]}>

					<InputTextRequired
						fieldName="firstName"
						labelText="First Name"
						placeholder="i.e. John"
						register={register}
						requiredText="Enter your first name"
						errors={errors}
					/>

					<InputTextOptional
						fieldName="middleInitial"
						labelText="MI"
						placeholder="i.e. R"
						register={register}
						errors={errors}
						maxLength={1}
					/>

					<InputTextRequired
						fieldName="lastName"
						labelText="Last Name"
						placeholder="i.e. Smith"
						register={register}
						requiredText="Enter your last name"
						errors={errors}
					/>

					<InputTextOptional
						fieldName="dbaName"
						labelText="DBA Name"
						placeholder="i.e. Smith & Co"
						register={register}
						errors={errors}
					/>

					<InputDateRequired
						fieldName="effectiveDate"
						labelText="Effective Date"
						register={register}
						requiredText="Enter the effective date"
						errors={errors}
					/>

					<InputEmailRequired
						fieldName="email"
						labelText="Email"
						placeholder="i.e. johnsmith@gmail.com"
						register={register}
						errors={errors}
					/>

					<InputPhoneTripleRequired
						fieldName1="phoneNumber1"
						fieldName2="phoneNumber2"
						fieldName3="phoneNumber3"
						labelText="Best Phone Number"
						watch={watch}
						setFocus={setFocus}
						register={register}
						requiredText="Enter the phone number"
						errors={errors}
					/>

					<InputZipWithZip4
						fieldName1="zipCode"
						fieldName2="zip4"
						labelText="Zip Code"
						watch={watch}
						setFocus={setFocus}
						register={register}
						errors={errors}
						isWrongZip={isWrongZip}
					/>

				</div>

				<button type="submit" className={styles["next-step-button"]}>Next step</button>
			</form>

			{
				(isLoading || isFetching) ?
					createPortal(<Loader isDark={true} />, rootPortal) :
					isError ? <div style={{ color: 'red' }}>Error receiving a location with a Zip code</div > :
						null
			}
		</>
	);
};
