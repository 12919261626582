import { logInUser } from '../../store/authorizationSlice';
import { useAppDispatch } from '../../store/hookTypes';
import { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Outlet } from "react-router-dom";

export const RecallToken = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const recallToken = () => {
		dispatch(logInUser());
		console.log('token recalled!');
	};

	//TODO: decide if form data should be saved in local storage
	// пока перенаправляю на первый степ - пока данные не сохраняются в local storage

	useEffect(() => {
		recallToken();
		navigate('/new-quote-step1');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return <Outlet />;
};
