import { createSlice } from "@reduxjs/toolkit";

export type SignatureState = {
	signatureURL: string;
	isValid: boolean;
};

const initialState: SignatureState = {
	signatureURL: "",
	isValid: false,
};

const signatureSlice = createSlice({
	name: "signature",
	initialState: initialState,
	reducers: {
		setSignature(state, action) {
			state.signatureURL = action.payload;
		},
		setValidation(state, action) {
			state.isValid = action.payload;
		},
	},
});

export const { setSignature, setValidation } = signatureSlice.actions;
export const signatureReducer = signatureSlice.reducer;
