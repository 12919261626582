import styles from './ContactPage.module.css';
import ContactImage from './social-media-3.svg';

export const ContactPage = () => {
	return (
		<div className={styles["page-container"]}>
			<h2 className={styles["title"]}>Contact us</h2>

			<div className={styles["main-box"]}>
				<div className={styles["main-text"]}>
					<p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Odit recusandae quasi ad enim reiciendis, vel incidunt explicabo illo perferendis necessitatibus iusto ut libero vitae natus nesciunt dolorum atque ratione veritatis eveniet eaque fugit. Ipsum aliquid minus corrupti fugit temporibus? Itaque corrupti quae perspiciatis ea architecto dolorum fuga tenetur dignissimos non quaerat sunt, modi consequatur.</p>
					<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero, veritatis. Inventore aut laboriosam veniam ab labore obcaecati, numquam ad aspernatur, possimus officia quisquam non. Tenetur hic rerum ullam commodi esse nesciunt blanditiis itaque, repellat inventore, ratione, quod dolores earum architecto labore facilis. Dicta commodi fugit velit distinctio! Culpa sequi itaque, cupiditate illum ab aliquam ut officia quod eligendi asperiores quos vero excepturi numquam porro quibusdam laborum architecto aut perferendis.</p>
				</div>

				<div className={styles["main-image"]}>
					<img src={ContactImage} alt="Contact us" />
				</div>
			</div>
		</div>
	);
};
