import { FormStep4 } from '../../components/FormSteps/FormStep4';
import { StepsProgress } from '../../components/StepsProgress/StepsProgress';
// import styles from './NewQuotePage.module.css';

export const NewQuotePageStep4 = () => {
	return (
		<div>
			<StepsProgress step={4} />
			<FormStep4 />
		</div>
	);
};
