import { FormStep3 } from '../../components/FormSteps/FormStep3';
import { StepsProgress } from '../../components/StepsProgress/StepsProgress';
// import styles from './NewQuotePage.module.css';

export const NewQuotePageStep3 = () => {
	return (
		<div>
			<StepsProgress step={3} />
			<FormStep3 />
		</div>
	);
};
