import { FormStep6 } from '../../components/FormSteps/FormStep6';
import { StepsProgress } from '../../components/StepsProgress/StepsProgress';
// import styles from './NewQuotePage.module.css';

export const NewQuotePageStep6 = () => {
	return (
		<div>
			<StepsProgress step={6} />
			<FormStep6 />
		</div>
	);
};
