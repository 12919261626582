import styles from './BlogPage.module.css';
import BlogImage from './newsletter-2.svg';

export const BlogPage = () => {
	return (
		<div className={styles["page-container"]}>
			<h2 className={styles["title"]}>Our Blog</h2>

			<div className={styles["main-box"]}>
				<div className={styles["main-text"]}>
					<p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Odit recusandae quasi ad enim reiciendis, vel incidunt explicabo illo perferendis necessitatibus iusto ut libero vitae natus nesciunt dolorum atque ratione veritatis eveniet eaque fugit. Ipsum aliquid minus corrupti fugit temporibus? Itaque corrupti quae perspiciatis ea architecto dolorum fuga tenetur dignissimos non quaerat sunt, modi consequatur. Dolorum itaque aliquam consectetur beatae harum? Aperiam iste eum fugiat quidem facilis aliquam omnis doloribus error dolor quam magni doloremque nam, quisquam delectus illum sint.</p>

					<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero, veritatis. Inventore aut laboriosam veniam ab labore obcaecati, numquam ad aspernatur, possimus officia quisquam non. Tenetur hic rerum ullam commodi esse nesciunt blanditiis itaque, repellat inventore, ratione, quod dolores earum architecto labore facilis. Sint magni corporis aliquam in minima quia, vitae nostrum odio consectetur quae. Sit iste esse dolorem pariatur temporibus, quibusdam consequatur! Similique incidunt nemo ad sequi corporis ex maxime doloribus ullam natus!</p>
				</div>

				<div className={styles["main-image"]}>
					<img src={BlogImage} alt="Our blog" />
				</div>
			</div>
		</div>
	);
};
