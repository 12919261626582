import classNames from 'classnames';
import styles from './FaqsPage.module.css';
import FaqsImage from './asking-question-1.svg';

export const FaqsPage = () => {
	return (
		<div className={styles["page-container"]}>
			<h2 className={styles["title"]}>Frequently Asked Questions</h2>

			<div className={styles["main-box"]}>
				<div className={styles["main-image"]}>
					<img src={FaqsImage} alt="Frequently Asked Questions" />
				</div>

				<div className={classNames(styles["main-text-center"], styles["highlight"])}>
					<h3 className={styles["subtitle"]}>Not sure about something?</h3>

					<p>Here we provide answers to the questions we are asked most often. If you do not find the answer to your question, please contact us.</p>
				</div>
			</div>

			{/* <div className={styles["main-box"]}> */}
			<div className={styles["main-text"]}>
				<h3 className={styles["subtitle"]}>What is homeowners liability insurance?</h3>

				<p>Homeowners liability insurance is a type of insurance coverage that protects homeowners in the event that someone is injured on their property or if they cause damage to someone else's property. It provides financial protection and helps cover legal expenses in case of liability claims or lawsuits.</p>

				<h3 className={styles["subtitle"]}>Why do I need homeowners liability insurance?</h3>

				<p>Accidents can happen anytime, anywhere. If someone gets injured on your property or you accidentally damage someone else's property, you could be held legally responsible. Homeowners liability insurance helps protect you financially in such situations by covering medical expenses, legal fees, and potential settlement costs.</p>

				<h3 className={styles["subtitle"]}>What does homeowners liability insurance typically cover?</h3>

				<p>Homeowners liability insurance typically covers bodily injury, which includes medical expenses, lost wages, and pain and suffering of the injured party. It also covers property damage caused by you or your family members. Additionally, it may include coverage for legal defense costs if you are sued.</p>

				<h3 className={styles["subtitle"]}>How much homeowners liability insurance coverage do I need?</h3>

				<p>The amount of coverage you need depends on various factors, including the value of your assets and the potential risks associated with your property. It is generally recommended to have enough liability coverage to protect your assets in case of a lawsuit. Our experienced insurance professionals can help assess your needs and guide you in determining an appropriate coverage amount.</p>
			</div>
			{/* </div> */}
		</div>
	);
};
