import classNames from "classnames";
import { createPortal } from "react-dom";
import { rootPortal } from '../../index';
import { Modal } from "../Modal/Modal";
import { useState } from "react";
import styles from "../FormSteps/FormSteps.module.css";
import { FormAddPreviousInsurance } from "../FormAddPreviousInsurance/FormAddPreviousInsurance";
import { PreviousInsuranceList } from "../PreviousInsuranceList/PreviousInsuranceList";

export const PreviousInsuranceCoverage = () => {
	const [isModalOpen, setIsModalOpen] = useState(false);

	const handleAddInsuranceCoverage = () => {
		setIsModalOpen(true);
	};

	return (
		<>
			<div className={styles["grid-container-3"]}>

				<h3 className={classNames(styles["title"], styles["grid-item-wide-3"])}>Previous Insurance Coverage</h3>

				<div className={classNames(styles["grid-item"], styles["box-row-end-button"])}>
					<button
						type="button"
						className={styles["add-button"]}
						onClick={handleAddInsuranceCoverage}
					>
						Add Previous Insurance Coverage<br></br>+
					</button>
				</div>

			</div>

			<PreviousInsuranceList />

			{
				isModalOpen &&
				createPortal(
					<Modal title={"Adding a new entry"} onClose={setIsModalOpen}>
						<FormAddPreviousInsurance setIsModalOpen={setIsModalOpen} />
					</Modal>,
					rootPortal)
			}
		</>
	);
};
