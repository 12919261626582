import { useAppSelector } from "../../store/hookTypes";
import styles from "./FormSteps.module.css";
import { useNavigate } from 'react-router-dom';
import { MouseEvent } from 'react';

//TODO: add taxes calculation
export const FormStep5 = () => {
	const { quoteData } = useAppSelector((state) => state.newQuote);

	const navigate = useNavigate();

	const handlePreviousClick = (event: MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		navigate('/new-quote-step4');
	};

	const handleNextClick = (event: MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		navigate('/new-quote-step6');
	};

	return (
		<div className={styles["quote-wrapper"]}>
			<h3 className={styles["title"]}>Here's Your Quote:</h3>

			{quoteData ?
				(<div className={styles["quote-container"]}>

					<div className={styles["flex-item"]}>
						<span className={styles["quote-text"]}>TIV:</span>
						<span className={styles["quote-text"]}>{`$${quoteData?.policy.lineProperty.risk.L1_B1._rated.TIV.value}`}</span>
					</div>

					<div className={styles["flex-item"]}>
						<span className={styles["quote-text"]}>Non-Wind Premium:</span>
						<span className={styles["quote-text"]}>{`$${quoteData?.policy.lineProperty.risk.L1_B1._rated.premiumXWind}`}</span>
					</div>

					<div className={styles["flex-item"]}>
						<span className={styles["quote-text"]}>Wind Premium:</span>
						<span className={styles["quote-text"]}>{`$${quoteData?.policy.lineProperty.risk.L1_B1._rated.premiumWind}`}</span>
					</div>

					<div className={styles["flex-item"]}>
						<span className={styles["quote-text"]}>Liability Premium:</span>
						<span className={styles["quote-text"]}>{`$${quoteData?.policy.lineProperty.risk.L1_B1._rated.premiumLiability}`}</span>
					</div>

					<div className={styles["flex-item"]}>
						<span className={styles["quote-text"]}>Other Coverages Premium:</span>
						<span className={styles["quote-text"]}>{`$${quoteData?.policy.lineProperty.risk.L1_B1._rated.premiumOtherCoverages}`}</span>
					</div>

					<div className={styles["flex-item"]}>
						<span className={styles["quote-subtitle"]}>Total Premium:</span>
						<span className={styles["quote-text-strong"]}>{`$${quoteData?.policy.lineProperty.risk.L1_B1._rated.premium}`}</span>
					</div>

					<div className={styles["flex-item"]}>
						<span className={styles["quote-text"]}>Policy fee:</span>
						<span className={styles["quote-text"]}>{`$${quoteData?.policy._rated.policyfee.value}`}</span>
					</div>

					<div className={styles["flex-item"]}>
						<span className={styles["quote-text"]}>Inspection fee:</span>
						<span className={styles["quote-text"]}>{`$${quoteData?.policy._rated.inspection}`}</span>
					</div>

					<div className={styles["flex-item"]}>
						<span className={styles["quote-subtitle"]}>Total fees:</span>
						<span className={styles["quote-text-strong"]}>{`$${quoteData?.policy._rated.fee}`}</span>
					</div>

					<div className={styles["flex-item"]}>
						<span className={styles["quote-subtitle"]}>Total Policy:</span>
						<span className={styles["quote-text-strong"]}>{`$${(quoteData?.policy.lineProperty.risk.L1_B1._rated.premium as number) + (quoteData?.policy._rated.fee as number)}`}</span>
					</div>

					<div className={styles["flex-item"]}>
						<span className={styles["quote-text"]}>EMPA:</span>
						<span className={styles["quote-text"]}>{`$${0}`}</span>
					</div>

					<div className={styles["flex-item"]}>
						<span className={styles["quote-text"]}>FSLSO Tax:</span>
						<span className={styles["quote-text"]}>{`$${0}`}</span>
					</div>

					<div className={styles["flex-item"]}>
						<span className={styles["quote-text"]}>Surplus Lines Tax:</span>
						<span className={styles["quote-text"]}>{`$${0}`}</span>
					</div>

					<div className={styles["flex-item"]}>
						<span className={styles["quote-subtitle"]}>Total including taxes:</span>
						<span className={styles["quote-text-strong"]}>{`$${(quoteData?.policy.lineProperty.risk.L1_B1._rated.premium as number) + (quoteData?.policy._rated.fee as number)}`}</span>
					</div>

				</div>) :
				(<div className={styles["flex-item"]}>Quote data is not available</div>)
			}

			<div className={styles["box-row-end"]}>
				<button type="button" className={styles["step-button"]} onClick={handlePreviousClick}>Previous</button>
				<button type="button" className={styles["step-button"]} onClick={handleNextClick}>Complete</button>
			</div>
		</div>
	);
};
