import styles from "../FormSteps/FormSteps.module.css";
import { SignaturePadWithCanvas } from "../SignaturePadWithCanvas/SignaturePadWithCanvas";

export const PolicySigning = () => {
	return (
		<div className={styles["sign-container"]}>
			{/* <h2 className={styles["sign-title"]}>Signing your new policy</h2> */}
			<h2 className={styles["sign-title"]}>Signature</h2>
			<h3 className={styles["sign-subtitle"]}>Please draw your signature in the text area below</h3>

			<SignaturePadWithCanvas />
		</div>
	);
};
