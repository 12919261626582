import { YesNoOptionalProps } from "../../models/models";
import { NoteOneRow } from "../NoteOneRow/NoteOneRow";
import { SelectBase } from "./SelectBase";

export const YesNoOptional = ({ fieldName, labelText, register }: YesNoOptionalProps) => {
	return (
		<>
			<SelectBase
				fieldName={fieldName}
				labelText={labelText}
				optionsList={["Yes", "No"]}
				register={register}
			>
				<NoteOneRow text="Optional" />
			</SelectBase>
		</>
	);
};