import styles from "../FormSteps/FormSteps.module.css";
import { InputTextBaseProps } from "../../models/models";
import classNames from "classnames";

export const InputTextBase = ({
	children,
	fieldName,
	labelText,
	placeholder,
	register,
	requiredText,
	errors,
	onBlur,
	onFocus,
	onChange,
	maxLength,
	validation
}: InputTextBaseProps) => {
	return (
		<div className={styles["grid-item"]}>
			<label htmlFor={fieldName} className={styles["form-field-label"]}>
				{labelText}
			</label>
			<div>
				<input
					{...register(fieldName, {
						onBlur: onBlur ?? null, // onBlur is optional
						onFocus: onFocus ?? null, // onFocus is optional
						onChange: onChange ?? null, // onChange is optional
						required: requiredText ?? false, // requiredText is optional
						min: validation?.min ?? null, // validation is optional
						max: validation?.max ?? null, // validation is optional
						pattern: validation?.pattern ?? null, // validation is optional
						minLength: validation?.minLength ?? null, // validation is optional
					})}
					type="text"
					maxLength={maxLength ?? 250} // maxLength is optional
					name={fieldName}
					placeholder={placeholder}
					className={
						requiredText ?
							classNames(styles["form-text-field"], styles["w-input"], errors[fieldName] && styles["error"]) :
							classNames(styles["form-text-field"], styles["w-input"], styles["optional-field"], errors[fieldName] && styles["error"])
					}
					id={fieldName}>
				</input>
				{children}
			</div>
		</div>
	);
};
