import style from './Loader.module.css';

export type LoaderProps = {
	isDark: boolean;
};

export const Loader = ({ isDark }: LoaderProps) => {
	return (
		<div className={isDark ? style['backdrop-dark'] : style['backdrop-light']}>
			<div className={style['loader-wrapper']}>
				<div className={style['loader']}></div>
				<div className={style['section-left']}></div>
				<div className={style['section-right']}></div>
			</div>
		</div>
	);
};
