export const lossTypeList = [
	'P1 - Fire',
	'P10 - Vandalism',
	'P11 - Sprinkler Leakage',
	'P12 - Sinkhole Collapse',
	'P13 - Volcanic Action',
	'P14 - Breakage of Glass',
	'P15 - Falling Objects',
	'P16 - Weight of Snow, Ice or Sleet',
	'P17 - Water Damage',
	'P17a - Water Damage - Broken Pipe',
	'P17c - Water Damage - Sewer/Drain/Sump back up/overflow',
	'P17b - Water Damage - Rain',
	'P18 - Theft',
	'P19 - Other Unspecified Perils',
	'P1a - Fire - Wildfire',
	'P1b - Fire - Arson',
	'P1c - Fire - Cooking',
	'P1d - Fire - Dryer Fire',
	'P1e - Fire - Electrical',
	'P1f - Fire - Following Earthquake',
	'P2 - Lightning',
	'P20 - Equipment Breakdown',
	'P21 - Flood',
	'P21a - Flood - Hurricane',
	'P22 - Earthquake',
	'P23 - Crime',
	'P23a - Crime - Robbery',
	'P23b - Crime - Burglary',
	'P23c - Crime - Employee Dishonesty',
	'P23d - Crime - Employee Theft',
	'P24 - Business Income',
	'P24a - Business Income - Loss of Rents',
	'P24b - Business Income - Extra Expense',
	'P25 - Construction Defect',
	'P26 - Food Spoilage',
	'P27 - Terrorism',
	'P28 - Fungus/Mold',
	'P29 - Collapse',
	'P3 - Explosion',
	'P30 - Discharge, overflow of Steam, Water from Systems, Appliances',
	'P31 - Tearing Apart, Cracking, Burning, Bulging of Systems',
	'P32 - Freezing of systems, Appliances',
	'P33 - Artificially Generated Electrical Current',
	'P34 - Identity Theft',
	'P4 - Windstorm',
	'P4a - Windstorm - Hurricane',
	'P4b - Windstorm - Tornado',
	'P4c - Windstorm - Tropical Named Storm',
	'P5 - Hail',
	'P6 - Smoke',
	'P7 - Aircraft',
	'P8 - Vehicle',
	'P9 - Riot or Civil Commotion',
];
