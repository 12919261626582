import styles from "../FormSteps/FormSteps.module.css";
import classNames from "classnames";
import { useState, useEffect } from "react";
import { YesNoRequiredWithExtraProps } from "../../models/models";
import { ErrorMessage } from "../ErrorMessage/ErrorMessage";
import { SelectBase } from "./SelectBase";

export const YesNoRequiredWithExtra = ({ fieldName, labelText, describeName, placeholderText, register, errors, watch }: YesNoRequiredWithExtraProps) => {
	const watchField = watch(fieldName);
	const [isShowDescribe, setIsShowDescribe] = useState(false);

	useEffect(() => {
		if (watchField === "Yes") {
			setIsShowDescribe(true);
		} else {
			setIsShowDescribe(false);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [watchField]);

	return (
		<>
			<SelectBase
				fieldName={fieldName}
				labelText={labelText}
				optionsList={["Yes", "No"]}
				register={register}
				errors={errors}
				requiredText={"Choose the option"}
			>
				<ErrorMessage errorMessage={errors[fieldName] ? (errors[fieldName]?.message as string) : ''} />
			</SelectBase>

			{
				isShowDescribe &&
				(<div className={classNames(styles["grid-item"], styles["grid-item-wide-3"], styles["appearing-field"])}>
					<label htmlFor={describeName} className={styles["form-field-label"]}>
						Describe
					</label>
					<textarea
						{...register(describeName, {
							required: 'Enter the description',
						})}
						name={describeName}
						rows={2}
						placeholder={placeholderText}
						className={classNames(styles["form-text-field"], styles["w-input"], errors[describeName] && styles["error"])}
						id={describeName}></textarea>
					{<ErrorMessage errorMessage={errors[describeName] ? (errors[describeName]?.message as string) : ''} />}
				</div>)
			}
		</>
	);
};
