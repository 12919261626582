import { InputDateOptionalProps } from "../../models/models";
import { NoteOneRow } from "../NoteOneRow/NoteOneRow";
import { InputDateBase } from "./InputDateBase";

export const InputDateOptional = ({ fieldName, labelText, register }: InputDateOptionalProps) => {
	return (
		<InputDateBase
			fieldName={fieldName}
			labelText={labelText}
			register={register}
		>
			<NoteOneRow text="Optional" />
		</InputDateBase>
	);
};
