import { FormStep2 } from '../../components/FormSteps/FormStep2';
import { StepsProgress } from '../../components/StepsProgress/StepsProgress';
// import styles from './NewQuotePage.module.css';

export const NewQuotePageStep2 = () => {
	return (
		<div>
			<StepsProgress step={2} />
			<FormStep2 />
		</div>
	);
};
