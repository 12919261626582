import { YesNoRequiredProps } from "../../models/models";
import { ErrorMessage } from "../ErrorMessage/ErrorMessage";
import { SelectBase } from "./SelectBase";

export const YesNoRequired = ({ fieldName, labelText, register, errors }: YesNoRequiredProps) => {
	return (
		<>
			<SelectBase
				fieldName={fieldName}
				labelText={labelText}
				optionsList={["Yes", "No"]}
				register={register}
				errors={errors}
				requiredText={"Choose the option"}
			>
				<ErrorMessage errorMessage={errors[fieldName] ? (errors[fieldName]?.message as string) : ''} />
			</SelectBase>
		</>
	);
};
