import { SelectRequiredProps } from "../../models/models";
import { ErrorMessage } from "../ErrorMessage/ErrorMessage";
import { SelectBase } from "./SelectBase";

export const SelectRequired = ({
	fieldName,
	labelText,
	optionsList,
	register,
	errors,
	requiredText,
	optionsValueTextObject,
	onChange }: SelectRequiredProps) => {
	return (
		<SelectBase
			fieldName={fieldName}
			labelText={labelText}
			optionsList={optionsList}
			register={register}
			errors={errors}
			requiredText={requiredText}
			optionsValueTextObject={optionsValueTextObject}
			onChange={onChange}
		>
			<ErrorMessage errorMessage={errors[fieldName] ? (errors[fieldName]?.message as string) : ''} />
		</SelectBase>
	);
};
