import { FormStep5 } from '../../components/FormSteps/FormStep5';
import { StepsProgress } from '../../components/StepsProgress/StepsProgress';
// import styles from './NewQuotePage.module.css';

export const NewQuotePageStep5 = () => {
	return (
		<div>
			<StepsProgress step={5} />
			<FormStep5 />
		</div>
	);
};
