import classNames from "classnames";
import styles from "../FormSteps/FormSteps.module.css";
import { InputDateBaseProps } from "../../models/models";

export const InputDateBase = ({ children, fieldName, labelText, register, requiredText, errors }: InputDateBaseProps) => {
	return (
		<div className={styles["grid-item"]}>
			<label htmlFor={fieldName} className={styles["form-field-label"]}>
				{labelText}
			</label>
			<div>
				<input
					{...register(fieldName, {
						required: requiredText ?? false, // requiredText is optional
					})}
					type="date"
					name={fieldName}
					// placeholder=""
					className={
						requiredText ?
							classNames(styles["form-text-field"], styles["w-input"], errors[fieldName] && styles["error"]) :
							classNames(styles["form-text-field"], styles["w-input"], styles["optional-field"])
					}
					id={fieldName}>
				</input>
				{children}
			</div>
		</div>
	);
};
