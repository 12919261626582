import { Fragment } from "react";
import { Routes, Route } from 'react-router-dom';
import { HomePage } from "./pages/HomePage/HomePage";
import { AboutPage } from "./pages/AboutPage/AboutPage";
import { BlogPage } from "./pages/BlogPage/BlogPage";
import { ContactPage } from "./pages/ContactPage/ContactPage";
import { FaqsPage } from "./pages/FaqsPage/FaqsPage";
import { NewQuotePageStep1 } from "./pages/NewQuotePage/NewQuotePageStep1";
import { NewQuotePageStep2 } from "./pages/NewQuotePage/NewQuotePageStep2";
import { NewQuotePageStep3 } from "./pages/NewQuotePage/NewQuotePageStep3";
import { NewQuotePageStep4 } from "./pages/NewQuotePage/NewQuotePageStep4";
import { NewQuotePageStep5 } from "./pages/NewQuotePage/NewQuotePageStep5";
import { NewQuotePageStep6 } from "./pages/NewQuotePage/NewQuotePageStep6";
import { NewQuotePageStep7 } from "./pages/NewQuotePage/NewQuotePageStep7";
import { CheckToken } from "./components/RequireAuth/CheckToken";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallback } from "./components/ErrorFallback/ErrorFallback";
import { useNavigate } from 'react-router-dom';
import { Layout } from "./components/Layout/Layout";

function App() {
  const navigate = useNavigate();

  return (
    <Fragment>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={() => navigate('/')} // в момент сброса ошибки перенаправляю на главную страницу
      >
        <Routes>
          <Route path='/' element={<Layout />}>
            <Route index element={<HomePage />} />
            <Route path='/about' element={<AboutPage />} />
            <Route path='/faqs' element={<FaqsPage />} />
            <Route path='/blog' element={<BlogPage />} />
            <Route path='/contact' element={<ContactPage />} />
            <Route element={<CheckToken />}>
              <Route path='/new-quote-step1' element={<NewQuotePageStep1 />} />
              <Route path='/new-quote-step2' element={<NewQuotePageStep2 />} />
              <Route path='/new-quote-step3' element={<NewQuotePageStep3 />} />
              <Route path='/new-quote-step4' element={<NewQuotePageStep4 />} />
              <Route path='/new-quote-step5' element={<NewQuotePageStep5 />} />
              <Route path='/new-quote-step6' element={<NewQuotePageStep6 />} />
              <Route path='/new-quote-step7' element={<NewQuotePageStep7 />} />
              <Route path='*' element={<h1>Not Found</h1>} />
            </Route>
          </Route>
        </Routes>
      </ErrorBoundary>
    </Fragment >
  );
}

export default App;
