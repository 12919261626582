import { YesNoRequiredProps } from "../../models/models";
import { ErrorMessage } from "../ErrorMessage/ErrorMessage";
import { SelectBaseRow } from "./SelectBaseRow";

export const YesNoRequiredRow = ({ fieldName, labelText, register, errors }: YesNoRequiredProps) => {
	return (
		<>
			<SelectBaseRow
				fieldName={fieldName}
				labelText={labelText}
				optionsList={["Yes", "No"]}
				register={register}
				errors={errors}
				requiredText={"Choose the option"}
			>
				<ErrorMessage errorMessage={errors[fieldName] ? (errors[fieldName]?.message as string) : ''} />
			</SelectBaseRow>
		</>
	);
};
