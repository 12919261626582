import { useRef, useState } from "react";
import styles from "./SignaturePadWithCanvas.module.css";
import SignaturePad from "react-signature-canvas";
import SignatureCanvas from "react-signature-canvas";
import { setSignature, setValidation } from '../../store/signatureSlice';
import { useAppDispatch } from '../../store/hookTypes';
import { sumDrawed } from "../../helpers/sumDrawed";
import { ErrorMessage } from "../ErrorMessage/ErrorMessage";

export const SignaturePadWithCanvas = () => {
	const [isValid, setIsValid] = useState<boolean>(false);
	const [isSaved, setIsSaved] = useState<boolean>(false);

	let padRef = useRef<SignatureCanvas>(null);

	const dispatch = useAppDispatch();

	const saveSignature = () => {
		setIsSaved(true); // for showing error message

		const imageCreated = padRef.current?.toDataURL("image/png"); // without trimming

		const isSignatureEmpty = padRef.current?.isEmpty();
		const elementCanvas = padRef.current?.getCanvas();
		console.log('testEmpty', isSignatureEmpty);
		console.log('testCanvas', elementCanvas);

		const ctx = elementCanvas?.getContext("2d");
		const dataImg = ctx?.getImageData(0, 0, elementCanvas?.width as number, elementCanvas?.height as number);
		console.log('data', dataImg);

		const sumDrawedPixels = sumDrawed((dataImg as ImageData).data);
		console.log('sumDrawedPixels', sumDrawedPixels);

		if (isSignatureEmpty || sumDrawedPixels < 1500) {
			setIsValid(false);
			dispatch(setSignature("")); // save signature to store
			dispatch(setValidation(false)); // save validation to store
		} else {
			setIsValid(true);
			dispatch(setSignature(imageCreated)); // save signature to store
			dispatch(setValidation(true)); // save validation to store
		};
	};

	const clearSignature = () => {
		padRef.current?.clear();
	};

	return (
		<div className={styles["sigContainer"]}>

			<SignaturePad
				ref={padRef}
				penColor="#15113E"
				canvasProps={{ className: styles["sigCanvas"] }}
			/>

			{
				!(isSaved) ? <ErrorMessage errorMessage={""} /> :
					(<ErrorMessage errorMessage={isValid ? "" : "This signature is ineligible, please reset and re-sign"} />)
			}

			<div className={styles["sigButtons-wrapper"]}>
				<button className={styles["sigButton-clear"]} onClick={clearSignature}>Erase</button>
				<button className={styles["sigButton-save"]} onClick={saveSignature}>Save</button>
			</div>

		</div>
	);
};
