import styles from './FormSteps.module.css';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState, MouseEvent, ChangeEvent } from 'react';
import classNames from 'classnames';
import { FormStep3Values } from '../../models/models';
import { useAppSelector, useAppDispatch } from '../../store/hookTypes';
import { setFormStep3 } from '../../store/newQuoteSlice';
import { useLazyGetWindQuery } from '../../store/mainApiQueries';
import { setIsWindIncluded, setOptionsWindCoverage } from '../../store/checkedAddressSlice';
import { COUNTIES_TIER1, COUNTIES_HIGH_LIMIT_1, COUNTIES_HIGH_LIMIT_2 } from '../../constants';
import { InputTextRequired } from '../InputText/InputTextRequired';
import { SelectRequired } from '../Select/SelectRequired';
import { YesNoRequiredWithExtraRow } from '../Select/YesNoRequiredWithExtraRow';
import { YesNoRequiredRow } from '../Select/YesNoRequiredRow';
import { TextareaRequired } from '../Textarea/TextareaRequired';

//TODO: обработка isErrorWind при помощи ErrorBoundary
export const FormStep3 = () => {
	const { formStep3 } = useAppSelector((state) => state.newQuote);
	const { county, locationState } = useAppSelector((state) => state.newQuote.formStep2);
	const { effectiveDate } = useAppSelector((state) => state.newQuote.formStep1);
	const { optionDTC, isStreetManuallyEntered, isWindIncluded, optionsWindCoverage } = useAppSelector((state) => state.checkedAddress);

	const isDisabledRiskForWindCoverage = !COUNTIES_TIER1.includes(county);
	const isMin500K = COUNTIES_HIGH_LIMIT_1.includes(county);
	const isMin350K = COUNTIES_HIGH_LIMIT_2.includes(county);

	const [isWindCanBeIncluded, setIsWindCanBeIncluded] = useState(isWindIncluded);
	const [windCoverageOptions, setWindCoverageOptions] = useState<Array<string>>(optionsWindCoverage);

	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const [getWind, { data: dataWind, isError: isErrorWind }] = useLazyGetWindQuery();

	const {
		register,
		watch,
		formState: { errors, isSubmitSuccessful },
		handleSubmit,
		setValue,
	} = useForm<FormStep3Values>({
		mode: 'onSubmit',
		defaultValues: formStep3,
	});

	const watchAllFields = watch(); // все поля формы - для сохранения в store, когда нажимается Previous

	const watchCoverageForm = watch("coverageForm");
	const watchYearBuilt = watch("yearBuilt");

	const watchFields = watch(["buildingLimit", "otherStructuresLimit", "contentsLimit", "lossOfUseLimit"]);
	const watchRoofShape = watch("roofShape");
	const watchRoofType = watch("roofType");
	const watchWindHailCoverage = watch("windHailCoverage");

	const [tiv, setTiv] = useState(0); // TIV = Total Insurable Value
	const [isShowDescribeRoofShape, setIsShowDescribeRoofShape] = useState(false); // roofShape === 'Other'
	const [isShowDescribeRoofType, setIsShowDescribeRoofType] = useState(false); // roofType === 'Other'
	const [isShowWindOptions, setisShowWindOptions] = useState(false);

	useEffect(() => {
		if (!isStreetManuallyEntered) {
			getWind({
				state: locationState,
				county: county,
				effectiveDate: effectiveDate,
				distanceToCoast: optionDTC,
			});
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isStreetManuallyEntered]);

	useEffect(() => {
		if (dataWind) {
			// console.log('dataWind=', dataWind);

			setIsWindCanBeIncluded(dataWind.allow);
			dispatch(setIsWindIncluded(dataWind.allow)); // saving isWindIncluded to the store
			setWindCoverageOptions(dataWind.deductible);
			dispatch(setOptionsWindCoverage(dataWind.deductible)); // saving optionsWindCoverage to the store
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dataWind]);

	useEffect(() => {
		if (optionDTC.length > 0 && !isStreetManuallyEntered) {
			setValue('distanceToCoast', optionDTC);
		};
	}, [optionDTC, isStreetManuallyEntered, setValue]);

	useEffect(() => {
		if (isSubmitSuccessful) {
			navigate('/new-quote-step4');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSubmitSuccessful]);

	useEffect(() => {
		const buildingLimit = Number(watchFields[0]);
		const otherStructuresLimit = Number(watchFields[1].slice(0, -1));
		const contentsLimit = Number(watchFields[2].slice(0, -1));
		const lossOfUseLimit = Number(watchFields[3].slice(0, -1));
		setTiv(buildingLimit + (buildingLimit * otherStructuresLimit / 100) + (buildingLimit * contentsLimit / 100) + (buildingLimit * lossOfUseLimit / 100));
	}, [watchFields]);

	useEffect(() => {
		if (watchRoofShape === 'Other') {
			setIsShowDescribeRoofShape(true);
		} else {
			setIsShowDescribeRoofShape(false);
		};
	}, [watchRoofShape]);

	useEffect(() => {
		if (watchRoofType === 'Other') {
			setIsShowDescribeRoofType(true);
		} else {
			setIsShowDescribeRoofType(false);
		};
	}, [watchRoofType]);

	useEffect(() => {
		if (watchWindHailCoverage === '1') {
			setisShowWindOptions(true);
		} else {
			setisShowWindOptions(false);
		};
	}, [watchWindHailCoverage]);

	useEffect(() => {
		if (isDisabledRiskForWindCoverage) {
			setValue('riskForWindCoverage', 'No');
		};
	}, [isDisabledRiskForWindCoverage, setValue]);

	const handleEntry = (event: ChangeEvent<HTMLSelectElement>) => {
		const { value } = event.target as HTMLSelectElement;

		if (value.length > 0) {
			getWind({
				state: locationState,
				county: county,
				effectiveDate: effectiveDate,
				distanceToCoast: value,
			});
		};
	};

	const onSubmit: SubmitHandler<FormStep3Values> = (data) => {
		console.log('FormStep3=', data);
		dispatch(setFormStep3(data));
	};

	const handlePreviousClick = (event: MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		dispatch(setFormStep3(watchAllFields));
		navigate('/new-quote-step2');
	};

	if (isErrorWind) {
		console.log('Error in FormStep3 with getWind');
	};

	return (
		<>
			<form
				onSubmit={handleSubmit(onSubmit)}
				className={styles["form"]}
				id="form3"
			>

				<div className={styles["grid-container-3"]}>

					<SelectRequired
						fieldName="coverageForm"
						labelText="Coverage Form"
						optionsList={["HO-3", "HO-5"]}
						register={register}
						errors={errors}
						requiredText="Choose Coverage Form"
					/>

					<SelectRequired
						fieldName="entityType"
						labelText="Entity Type"
						optionsList={["Individual", "Couple", "LLC", "Trust", "Corporation"]}
						register={register}
						errors={errors}
						requiredText="Choose Entity Type"
					/>

					<SelectRequired
						fieldName="occupancyType"
						labelText="Occupancy / usage type"
						optionsList={["Primary", "Secondary", "Seasonal"]}
						register={register}
						errors={errors}
						requiredText="Choose Occupancy Type"
					/>

					<hr className={styles["grid-item-wide-3"]} />

					<InputTextRequired
						fieldName="buildingLimit"
						labelText="Building limit (Coverage A)"
						placeholder="i.e. 250,000"
						register={register}
						requiredText="Enter Building limit"
						errors={errors}
						validation={
							{
								min: {
									value:
										isMin500K ?
											500000 :
											isMin350K ?
												350000 :
												watchCoverageForm === 'HO-3' ?
													200000 :
													watchCoverageForm === 'HO-5' ?
														250000 :
														0,
									message:
										isMin500K ?
											'Minimum value is 500,000' :
											isMin350K ?
												'Minimum value is 350,000' :
												watchCoverageForm === 'HO-3' ?
													'Minimum value is 200,000' :
													watchCoverageForm === 'HO-5' ?
														'Minimum value is 250,000' :
														'',
								},
								pattern: {
									// eslint-disable-next-line no-useless-escape
									value: /^\d+$/g,
									message: "Field must contain only digits"
								},
							}
						}
					/>

					<SelectRequired
						fieldName="otherStructuresLimit"
						labelText="Other Structures % / limit (Coverage B)"
						optionsList={["0%", "2%", "5%", "10%", "20%"]}
						register={register}
						errors={errors}
						requiredText="Choose Other Structures limit"
					/>

					<SelectRequired
						fieldName="contentsLimit"
						labelText="Contents % / limit (Coverage C)"
						optionsList={["0%", "10%", "25%", "50%", "75%"]}
						register={register}
						errors={errors}
						requiredText="Choose Contents limit"
					/>

					<SelectRequired
						fieldName="lossOfUseLimit"
						labelText="Loss of use % / limit (Coverage D)"
						optionsList={["0%", "5%", "10%", "20%"]}
						register={register}
						errors={errors}
						requiredText="Choose Loss of use limit"
					/>

					<SelectRequired
						fieldName="personalLiabilityLimit"
						labelText="Personal Liability Limit (Coverage E)"
						optionsList={[]}
						register={register}
						errors={errors}
						requiredText="Choose Personal Liability limit"
						optionsValueTextObject={{
							"100000": "100,000",
							"300000": "300,000",
							"500000": "500,000",
							"1000000": "1,000,000",
						}}
					/>

					<SelectRequired
						fieldName="medicalExpenseLimit"
						labelText="Medical Expense Limit (Coverage F)"
						optionsList={[]}
						register={register}
						errors={errors}
						requiredText="Choose Medical Expense limit"
						optionsValueTextObject={{
							"1000": "1,000",
							"5000": "5,000",
						}}
					/>

					<div className={classNames(styles["subtitle-tiv"], styles["grid-item-wide-3"])}>
						<div>{`TIV: $${tiv}`}</div>
					</div>

					<hr className={styles["grid-item-wide-3"]} />

					<InputTextRequired
						fieldName="yearBuilt"
						labelText="Year Built"
						placeholder="i.e. 1990"
						register={register}
						requiredText="Enter year built"
						errors={errors}
						maxLength={4}
						validation={
							{
								pattern: {
									// eslint-disable-next-line no-useless-escape
									value: /^\d+$/g,
									message: "Year must contain only digits"
								},
								minLength: {
									value: 4,
									message: "Wrong year format"
								},
							}
						}
					/>

					<InputTextRequired
						fieldName="plumbingYear"
						labelText="Plumbing Year"
						placeholder="i.e. 2015"
						register={register}
						requiredText="Enter plumbing year"
						errors={errors}
						maxLength={4}
						validation={
							{
								min: {
									value: watchYearBuilt,
									message: 'Minimum year is equal to the year built',
								},
								pattern: {
									// eslint-disable-next-line no-useless-escape
									value: /^\d+$/g,
									message: "Year must contain only digits"
								},
								minLength: {
									value: 4,
									message: "Wrong year format"
								},
							}
						}
					/>

					<InputTextRequired
						fieldName="roofYear"
						labelText="Roof Year"
						placeholder="i.e. 2015"
						register={register}
						requiredText="Enter roof year"
						errors={errors}
						maxLength={4}
						validation={
							{
								min: {
									value: watchYearBuilt,
									message: 'Minimum year is equal to the year built',
								},
								pattern: {
									// eslint-disable-next-line no-useless-escape
									value: /^\d+$/g,
									message: "Year must contain only digits"
								},
								minLength: {
									value: 4,
									message: "Wrong year format"
								},
							}
						}
					/>

					<SelectRequired
						fieldName="constructionType"
						labelText="Construction Type"
						optionsList={[]}
						register={register}
						errors={errors}
						requiredText="Choose construction type"
						optionsValueTextObject={{
							"BV": "Brick Veneer (BV)",
							"F": "Frame (F)",
							"HB": "Hardi-board/hardiplank (HB)",
							"M": "Masonry (M)",
							"SOF": "Stucco over frame (SOF)",
						}}
					/>

					<SelectRequired
						fieldName="roofShape"
						labelText="Roof Shape"
						optionsList={["Flat", "Gable", "Gambrel", "Hip", "Mansard", "Shed", "Other"]}
						register={register}
						errors={errors}
						requiredText="Choose roof shape"
					/>

					{
						isShowDescribeRoofShape &&
						<TextareaRequired
							fieldName="describeRoofShape"
							labelText="Describe Roof Shape"
							placeholder="i.e. Hip and Flat"
							register={register}
							isAppearing={true}
							requiredText="Enter the roof shape"
							errors={errors}
						/>
					}

					<SelectRequired
						fieldName="roofType"
						labelText="Roof Type"
						optionsList={[
							"Tar Shingle",
							"Wood Shingle/Shakes (must be fire retardant)",
							"Composite Shingle",
							"Barrel Tile",
							"Flat Tile",
							"Tile (other)",
							"Metal",
							"Tar & Gravel",
							"Roll Roofing",
							"Slate",
							"Other"]}
						register={register}
						errors={errors}
						requiredText="Choose roof type"
					/>

					{
						isShowDescribeRoofType &&
						<TextareaRequired
							fieldName="describeRoofType"
							labelText="Describe Roof Type"
							placeholder="i.e. Poured concrete"
							register={register}
							isAppearing={true}
							requiredText="Enter the roof type"
							errors={errors}
						/>
					}

					<div className={classNames(styles["grid-item-wide-3-with-link"])}>
						<YesNoRequiredRow
							fieldName="riskForWindCoverage"
							labelText="Is this risk eligible for wind coverage by Citizens (located in wind-pool)?"
							register={register}
							errors={errors}
						/>

						<a className={styles["map-link"]} href="/wind-map.pdf" target='_blank'>Click here for Map</a>
					</div>

					<hr className={styles["grid-item-wide-3"]} />

					<SelectRequired
						fieldName="centralStationAlarm"
						labelText="Central Station alarm"
						optionsList={["None", "Fire only", "Burglary only", "Fire & Burglary"]}
						register={register}
						errors={errors}
						requiredText="Choose Central Station alarm"
					/>

					<SelectRequired
						fieldName="shutters"
						labelText="Shutters"
						optionsList={[
							"None - no shutters at all",
							"0 - Unknown",
							"1 - All openings designed for large missiles",
							"2 - All openings designed for medium missiles",
							"3 - All openings designed for small missiles",
							"4 - All glazed openings designed for large missiles",
							"5 - All glazed openings designed for medium missiles",
							"6 - All glazed openings designed for small missiles",
							"7 - All glazed openings covered with plywood/oriented strand board (OSB)",
							"8 - At least one glazed exterior opening does not have windborne debris protection",
							"9 - No glazed exterior openings have wind-borne debris protection",
						]}
						register={register}
						errors={errors}
						requiredText="Choose Shutters"
					/>

					<SelectRequired
						fieldName="distanceToCoast"
						labelText="Distance to coast"
						optionsList={isStreetManuallyEntered ? [
							"0 - 0.5 miles",
							"0.5 - 1 mile",
							"1 - 2 miles",
							"2 - 5 miles",
							"5 - 10 miles",
							"10 - 15 miles",
							"15 miles +",
						] : [optionDTC]}
						register={register}
						errors={errors}
						requiredText="Choose distance to coast"
						onChange={handleEntry}
					/>

					<SelectRequired
						fieldName="protectionClass"
						labelText="Protection class"
						optionsList={["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]}
						register={register}
						errors={errors}
						requiredText="Choose protection class"
					/>

					<SelectRequired
						fieldName="aopDeductible"
						labelText="AOP deductible"
						optionsList={[]}
						register={register}
						errors={errors}
						requiredText="Choose AOP deductible"
						optionsValueTextObject={{
							"$2,500": "2,500",
							"$5,000": "5,000",
							"$10,000": "10,000",
						}}
					/>

					{/* "WindSelect" */}
					<SelectRequired
						fieldName="windHailCoverage"
						labelText="Wind/Hail coverage"
						optionsList={[]}
						register={register}
						errors={errors}
						requiredText="Choose Wind/Hail coverage"
						optionsValueTextObject={
							isWindCanBeIncluded ?
								{ "0": "Excluded", "1": "Included" } :
								{ "0": "Excluded" }
						}
					/>

					{
						isShowWindOptions &&
						(<div className={classNames(styles["appearing-field"], styles["grid-item-wide-3"], styles["grid-container"])}>
							{/* "WindDeductible" */}
							<SelectRequired
								fieldName="windCoverageDeductible"
								labelText="Wind coverage deductible/option"
								optionsList={windCoverageOptions}
								register={register}
								errors={errors}
								requiredText="Choose Wind coverage deductible"
							/>
						</div>)
					}

					<YesNoRequiredWithExtraRow
						fieldName="doesBuildingHavePolybutylene"
						labelText="Does the building have polybutylene and/or PEX (also known as cross-linked polyethylene) plumbing?"
						describeName="explainPolybutylene"
						placeholderText="i.e. Partial polybutylene and PEX in the home"
						register={register}
						errors={errors}
						watch={watch}
					/>

				</div >

				<div className={styles["box-row-end"]}>
					<button type="button" className={styles["step-button"]} onClick={handlePreviousClick}>Previous</button>
					<button type="submit" className={styles["step-button"]}>Next step</button>
				</div>
			</form >

			{
				isErrorWind ?
					(<div style={{ color: 'red' }}>Error of getting wind data</div >) :
					null
			}

			{/* убрала лоадер, чтобы пользователь пока заполнял другие поля формы */}
			{/* {
				(isLoadingWind || isFetchingWind) ?
					createPortal(<Loader isDark={true} />, rootPortal) :
					isErrorWind ? <div style={{ color: 'red' }}>Error of getting wind data</div > :
						null
			} */}
		</>
	);
};
