import { TextareaOptionalProps } from "../../models/models";
import { NoteOneRow } from "../NoteOneRow/NoteOneRow";
import { TextareaBase } from "./TextareaBase";

export const TextareaOptional = ({
	fieldName,
	labelText,
	placeholder,
	register,
	isAppearing,
	rows }: TextareaOptionalProps) => {
	return (
		<TextareaBase
			fieldName={fieldName}
			labelText={labelText}
			placeholder={placeholder}
			register={register}
			isAppearing={isAppearing}
			rows={rows}
		>
			<NoteOneRow text="Optional" />
		</TextareaBase>
	);
};
