import styles from "../FormSteps/FormSteps.module.css";
import { InputZipWithZip4Props } from "../../models/models";
import classNames from "classnames";
import { ErrorMessage } from "../ErrorMessage/ErrorMessage";
import { NoteOneRow } from "../NoteOneRow/NoteOneRow";
import { useEffect } from "react";

export const InputZipWithZip4 = ({ fieldName1, fieldName2, labelText, watch, setFocus, register, errors, isWrongZip }: InputZipWithZip4Props) => {
	const watchZipCode = watch(fieldName1);

	useEffect(() => {
		if (watchZipCode?.length === 5) {
			setFocus(fieldName2);
		};
	}, [watchZipCode, setFocus, fieldName2]);

	return (
		<div className={styles["grid-item"]}>
			<label htmlFor={fieldName1} className={styles["form-field-label"]}>
				{labelText}
			</label>
			<div className={styles["phone-number-field"]}>
				<div>
					<input
						{...register(fieldName1, {
							required: 'Enter your zip code',
							pattern: {
								// eslint-disable-next-line no-useless-escape
								value: /^\d+$/g,
								message: "Must contain only digits"
							},
							minLength: {
								value: 5,
								message: "Must contain 5 digits"
							},
						})}
						type="text"
						maxLength={5}
						name={fieldName1}
						placeholder="i.e. 12345"
						className={classNames(styles["form-text-field"], styles["w-input"], errors[fieldName1] && styles["error"])}
						id={fieldName1}>
					</input>
					{<ErrorMessage
						errorMessage={isWrongZip ? 'We only work with FL and LA states' : errors[fieldName1] ? (errors[fieldName1]?.message as string) : ''}
					/>}
				</div>
				<div>
					<input
						{...register(fieldName2, {
							pattern: {
								// eslint-disable-next-line no-useless-escape
								value: /^\d+$/g,
								message: "Must contain only digits"
							},
							minLength: {
								value: 4,
								message: "Must contain 4 digits"
							},
						})}
						type="text"
						maxLength={4}
						name={fieldName2}
						placeholder="i.e. 1234"
						className={classNames(styles["form-text-field"], styles["w-input"], styles["optional-field"], errors[fieldName2] && styles["error"])}
						id={fieldName2}>
					</input>
					{
						errors[fieldName2] ?
							(<ErrorMessage errorMessage={errors[fieldName2]?.message as string} />) :
							(<NoteOneRow text="Optional" />)
					}
				</div>
			</div>
		</div>
	);
};
