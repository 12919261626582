import classNames from "classnames";
import { createPortal } from "react-dom";
import { ErrorMessage } from "../ErrorMessage/ErrorMessage";
import styles from "../FormSteps/FormSteps.module.css";
import { LienholderMortgageeProps } from "../../models/models";
import { useEffect, useState } from "react";
import { rootPortal } from '../../index';
import { Modal } from "../Modal/Modal";
import { FormAddLienholder } from "../FormAddLienholder/FormAddLienholder";
import { LienholderList } from "../LienholderList/LienholderList";
import { useAppSelector } from "../../store/hookTypes";
import { YesNoRequired } from "../Select/YesNoRequired";
import { CheckboxInvisible } from "../Checkbox/CheckboxInvisible";

export const LienholderMortgagee = ({ register, errors, watch, setValue }: LienholderMortgageeProps) => {
	const { formLienholderLossPayeeList, formLienholderMortgageeList, formLienholderLendersList, formLienholderContractList } = useAppSelector((state) => state.newQuote);
	const fullList = [...formLienholderLossPayeeList, ...formLienholderMortgageeList, ...formLienholderLendersList, ...formLienholderContractList];

	const watchField = watch("lienholderPremiumEscrowed");
	const [isDisabled, setIsDisabled] = useState(true);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isLength, setIsLength] = useState(fullList.length > 0);

	useEffect(() => {
		if (watchField === "Yes") {
			setIsDisabled(false);
			if (!isLength) {
				setValue('lienholderCheckbox', [""], { shouldValidate: true });
			} else {
				setValue('lienholderCheckbox', ["ok"], { shouldValidate: true });
			};
		} else {
			setIsDisabled(true);
			setValue('lienholderCheckbox', ["ok"], { shouldValidate: true });
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [watchField]);

	useEffect(() => {
		if (fullList.length > 0) {
			setIsLength(true);
			setValue('lienholderCheckbox', ["ok"], { shouldValidate: true });
		} else {
			if (watchField === "Yes") {
				setIsLength(false);
				setValue('lienholderCheckbox', [""], { shouldValidate: true });
			} else {
				setIsLength(false);
				setValue('lienholderCheckbox', ["ok"], { shouldValidate: true });
			};
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fullList.length, watchField]);

	const handleAddPayee = () => {
		setIsModalOpen(true);
	};

	return (
		<div className={styles["grid-container-3"]}>

			<h3 className={classNames(styles["title"], styles["grid-item-wide-3"])}>Lienholder/Mortgagee/Loss Payee</h3>

			<YesNoRequired
				fieldName={"lienholderPremiumEscrowed"}
				labelText={"Premium Escrowed"}
				register={register}
				errors={errors}
			/>

			<div className={classNames(styles["grid-item"], styles["box-row-end-button"])}>
				<button
					type="button"
					className={classNames(styles["add-button"], errors?.lienholderCheckbox && styles["error-button"])}
					onClick={handleAddPayee}
					disabled={isDisabled}
				>
					Add Lender/Loss Payee<br></br>+
				</button>
				{<ErrorMessage errorMessage={errors?.lienholderCheckbox ? (errors?.lienholderCheckbox?.message as string) : ''} />}
			</div>

			{/* INVISIBLE CHECKBOX */}
			<CheckboxInvisible
				name="lienholderCheckbox"
				requiredText="Add at least one entry"
				register={register}
			/>

			<LienholderList />

			{
				isModalOpen &&
				createPortal(
					<Modal title={"Adding a new entry"} onClose={setIsModalOpen}>
						<FormAddLienholder setIsModalOpen={setIsModalOpen} />
					</Modal>,
					rootPortal)
			}

		</div>
	);
};
