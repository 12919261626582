import classNames from "classnames";
import styles from "../FormSteps/FormSteps.module.css";
import { AdditionalCommentsRemarksProps } from "../../models/models";
import { TextareaOptional } from "../Textarea/TextareaOptional";

export const AdditionalCommentsRemarks = ({ register }: AdditionalCommentsRemarksProps) => {
	return (
		<div className={styles["grid-container-3"]}>

			<h3 className={classNames(styles["title"], styles["grid-item-wide-3"])}>Additional Comments/Remarks</h3>

			<TextareaOptional
				fieldName="additionalCommentsRemarks"
				labelText="Comments"
				placeholder="i.e. I would like to ..."
				register={register}
				isAppearing={false}
			/>

		</div>
	);
};
