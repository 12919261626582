import { useAppSelector } from "../../store/hookTypes";
import { LienholderCard } from "../LienholderCard/LienholderCard";
import styles from "./LienholderList.module.css";

export const LienholderList = () => {
	const { formLienholderLossPayeeList, formLienholderMortgageeList, formLienholderLendersList, formLienholderContractList } = useAppSelector((state) => state.newQuote);
	const fullList = [...formLienholderLossPayeeList, ...formLienholderMortgageeList, ...formLienholderLendersList, ...formLienholderContractList];

	return (
		<div className={styles["list-container"]}>
			{
				fullList.map((entry) => (<LienholderCard
					key={entry.lienholderId}
					id={entry.lienholderId}
					title={entry.lienholderName}
					type={entry.lienholderType}
					rank={entry.lienholderRank}
				/>))
			}
		</div>
	);
};
