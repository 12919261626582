import classNames from 'classnames';
import styles from '../FormSteps/FormSteps.module.css';
import { CheckboxesGroupRequiredProps } from '../../models/models';
import { ErrorMessage } from '../ErrorMessage/ErrorMessage';

export const CheckboxesGroupRequired = ({ groupName, legend, valuesList, register, errors, requiredText }: CheckboxesGroupRequiredProps) => {
	return (
		<>
			<fieldset>
				<div>
					<legend className={styles["form-field-label"]}>
						{legend}
					</legend>
				</div>
				<div className={classNames(styles["row-checkboxes"])}>
					{
						valuesList.map((value) =>
						(<div className={styles["box-row-10"]} key={value}>
							<input
								{...register(groupName, {
									required: requiredText ?? 'Choose at least one option', // текст по умолчанию
								})}
								type="checkbox"
								value={value}
								className={styles["input-checkbox"]}
								id={value} />
							<label htmlFor={value} className={styles["label-checkbox"]}>{value}</label>
						</div>)
						)
					}
				</div>
			</fieldset>
			{<ErrorMessage errorMessage={errors[groupName] ? (errors[groupName]?.message as string) : ''} />}
		</>
	);
};
