import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FormStep1Values, FormStep2Values, FormStep3Values, FormStep4Values, QuoteResponce, FormStep6Values, FormLienholderValues, FormLossValues, FormPreviousInsuranceValues } from '../models/models';
import { rankList } from '../data/rankList';

export type NewQuoteState = {
	formStep1: FormStep1Values;
	formStep2: FormStep2Values;
	formStep3: FormStep3Values;
	formStep4: FormStep4Values;
	quoteData: QuoteResponce | null;
	formStep6: FormStep6Values;
	formLienholderLossPayeeList: Array<FormLienholderValues>;
	formLienholderMortgageeList: Array<FormLienholderValues>;
	formLienholderLendersList: Array<FormLienholderValues>;
	formLienholderContractList: Array<FormLienholderValues>;
	formLossList: Array<FormLossValues>;
	formPreviousInsuranceList: Array<FormPreviousInsuranceValues>;
};

const initialState: NewQuoteState = {
	formStep1: {
		firstName: '', //! namedInsured
		middleInitial: '', //! namedInsured
		lastName: '', //! namedInsured
		dbaName: '', //! namedInsured
		effectiveDate: '', //!
		email: '', //! namedInsured
		phoneNumber1: '', //! namedInsured
		phoneNumber2: '', //! namedInsured
		phoneNumber3: '', //! namedInsured
		zipCode: '', //!
		zip4: '', //! businessformation, location
	},

	formStep2: {
		street: '', //!
		street2: '', //! businessformation, location
		city: '', //!
		county: '', //!
		locationState: '', //!
		addressVerificationCheckbox: [], //* invisible, but it's necessary for validation
	},

	formStep3: {
		coverageForm: '', //!
		entityType: '', //!
		occupancyType: '', //!
		buildingLimit: '', //!
		otherStructuresLimit: '', //!
		contentsLimit: '', //!
		lossOfUseLimit: '', //!
		personalLiabilityLimit: '', //!
		medicalExpenseLimit: '', //!
		yearBuilt: '', //!
		plumbingYear: '', //! lineProperty/risk/L1_B1
		roofYear: '', //!
		constructionType: '', //!
		roofShape: '', //!
		roofType: '', //!
		describeRoofShape: '', //! lineProperty/risk/L1_B1
		describeRoofType: '', //! lineProperty/risk/L1_B1
		riskForWindCoverage: '', //! lineProperty/risk/L1_B1
		centralStationAlarm: '', //!
		shutters: '', //!
		distanceToCoast: '', //!
		protectionClass: '', //!
		aopDeductible: '', //!
		windHailCoverage: '', //!
		windCoverageDeductible: '', //!
		doesBuildingHavePolybutylene: '', //! lineProperty/risk/L1_B1
		explainPolybutylene: '', //! lineProperty/risk/L1_B1
	},

	formStep4: {
		additionalCoverages: '', //!
		replacementCostOnContents: '',  //!
		valuationOnRoofForWindLosses: '', //!
		identityFraudExpenseCoverage: '', //!
		waterDamageCoverageRoof: '', //! lineProperty/risk/L1_B1
		waterDamageCoverageOther: '', //!
		moldCoverageLimit: '', //!
		waterBackUpCoverageLimit: '', //!
		increasedOrdinanceAndLaw: '', //!
		anyClaimsOrLossesInLast: '', //!
		isAnyRenovationOrReconstruction: '', //! renovationOrReconstruction
	},

	quoteData: null,

	formStep6: {
		sameAsLocation: '', //* it's necessary to automatically fill in the 4 fields below
		address1Mail: '', //! mailinginformation
		address2Mail: '', //! mailinginformation
		cityMail: '', //! mailinginformation
		locationStateMail: '', //! mailinginformation
		zipCodeMail: '', //! mailinginformation
		zip4Mail: '', //! mailinginformation
		contactName: '', //! mailinginformation
		phoneNumber1Mail: '', //! mailinginformation
		phoneNumber2Mail: '', //! mailinginformation
		phoneNumber3Mail: '', //! mailinginformation
		emailMail: '', //! mailinginformation
		applicantEmployer: '', //! mailinginformation
		occupation: '', //! mailinginformation
		dateOfBirth: '', //! mailinginformation
		coApplicantEmployer: '', //! mailinginformation
		coApplicantOccupation: '', //! mailinginformation
		coApplicantDateOfBirth: '', //! mailinginformation
		starscheckbox: [], //! mailinginformation
		describeStars: '', //! mailinginformation
		wiringYear: '', //! lineProperty/risk/L1_B1
		heatingYear: '', //! lineProperty/risk/L1_B1
		squareFeet: '', //! lineProperty/risk/L1_B1
		marketValue: '', //! lineProperty/risk/L1_B1
		numberOfStories: '', //! lineProperty/risk/L1_B1
		numberOfFamilies: '', //! lineProperty/risk/L1_B1
		foundationType: '', //! lineProperty/risk/L1_B1
		describeFoundationTypePilings: '', //! lineProperty/risk/L1_B1
		describeFoundationTypeOther: '', //! lineProperty/risk/L1_B1
		fireHydrantWithin: '', //! lineProperty/risk/L1_B1
		distanceToFireDepartment: '', //! lineProperty/risk/L1_B1
		smokeDetectors: '', //! lineProperty/risk/L1_B1
		automaticBurglarAlarmLocal: '', //! lineProperty/risk/L1_B1
		automaticFireAlarmLocal: '', //! lineProperty/risk/L1_B1
		gatedCommunity: '', //! lineProperty/risk/L1_B1
		fireExtinguisher: '', //! lineProperty/risk/L1_B1
		automaticSprinklerSystem: '', //! lineProperty/risk/L1_B1
		securityPatrol: '', //! lineProperty/risk/L1_B1

		lienholderPremiumEscrowed: '', //! lienholder
		lienholderCheckbox: [], //* invisible, but it's necessary for validation

		uninsuredWithinTheLast12Months: '', //! underwritingQuestions
		foreclosureRepossessionBankruptcy: '', //! underwritingQuestions
		anyPolicyDeclined: '', //! underwritingQuestions
		riskWithExistingDamage: '', //! underwritingQuestions
		anyKnownSinkholeActivity: '', //! underwritingQuestions
		isThereTrampoline: '', //! underwritingQuestions
		hasAnyoneConvicted: '', //! underwritingQuestions
		haveAnyCrimesOccurred: '', //! underwritingQuestions
		daycareConducted: '', //! underwritingQuestions
		isBusinessConducted: '', //! underwritingQuestions
		isThereWoodstove: '', //! underwritingQuestions
		anyLiveStockOrSaddleAnimal: '', //! underwritingQuestions
		anyExoticAnimals: '', //! underwritingQuestions
		isDwellingRented: '', //! underwritingQuestions
		isDwellingForSale: '', //! underwritingQuestions
		anyHistoricalRegister: '', //! underwritingQuestions
		wasGuttedAndRemodeled: '', //! underwritingQuestions
		areMortgagePaymentsLate: '', //! underwritingQuestions
		areTaxPaymentsLate: '', //! underwritingQuestions
		isThereSwimmingPool: '', //! underwritingQuestions
		willBuildingBeVacant: '', //! underwritingQuestions
		describeUninsuredWithinTheLast12Months: '', //! underwritingQuestions
		describeForeclosureRepossessionBankruptcy: '', //! underwritingQuestions
		describeAnyPolicyDeclined: '', //! underwritingQuestions
		describeRiskWithExistingDamage: '', //! underwritingQuestions
		describeAnyKnownSinkholeActivity: '', //! underwritingQuestions
		describeIsThereTrampoline: '', //! underwritingQuestions
		describeHasAnyoneConvicted: '', //! underwritingQuestions
		describeHaveAnyCrimesOccurred: '', //! underwritingQuestions
		describeDaycareConducted: '', //! underwritingQuestions
		describeIsBusinessConducted: '', //! underwritingQuestions
		describeIsThereWoodstove: '', //! underwritingQuestions
		describeAnyLiveStockOrSaddleAnimal: '', //! underwritingQuestions
		describeAnyExoticAnimals: '', //! underwritingQuestions
		describeIsDwellingRented: '', //! underwritingQuestions
		describeIsDwellingForSale: '', //! underwritingQuestions
		describeAnyHistoricalRegister: '', //! underwritingQuestions
		describeWasGuttedAndRemodeled: '', //! underwritingQuestions
		describeAreMortgagePaymentsLate: '', //! underwritingQuestions
		describeAreTaxPaymentsLate: '', //! underwritingQuestions
		describeIsThereSwimmingPool: '', //! underwritingQuestions
		isPoolFenced: '', //! underwritingQuestions
		anySlidesOrDivingBoards: '', //! underwritingQuestions
		describeWillBuildingBeVacant: '', //! underwritingQuestions
		detailsOfSecurityProtections: '', //! underwritingQuestions
		utilitiesConditions: '', //! underwritingQuestions
		areUtilitiesDisconnected: '', //! underwritingQuestions
		howLongVacant: '', //! underwritingQuestions
		reasonForVacancy: '', //! underwritingQuestions
		conditionOfBuilding: '', //! underwritingQuestions
		isBuildingCondemned: '', //! underwritingQuestions

		isCostMoreThan25Percent: '', //! renovationOrReconstruction
		willBuildingBeLivedIn: '', //! renovationOrReconstruction
		involveStructuralRenovations: '', //! renovationOrReconstruction
		completionDate: '', //! renovationOrReconstruction
		costOfRenovations: '', //! renovationOrReconstruction
		describeRenovationWork: '', //! renovationOrReconstruction
		describeRenovationApplicant: '', //! renovationOrReconstruction

		lossCheckbox: [], //* invisible, but it's necessary for validation

		fireDepartmentName: '', //! protectionClass9And10Questionnaire
		protectionHighCheckbox: [], //! protectionClass9And10Questionnaire
		responseTime: '', //! protectionClass9And10Questionnaire
		numberOfPumpers: '', //! protectionClass9And10Questionnaire
		numberOfTankers: '', //! protectionClass9And10Questionnaire
		areRoadsPavedAccessibleYearRound: '', //! protectionClass9And10Questionnaire
		anyPhysicalBarriers: '', //! protectionClass9And10Questionnaire
		describeAnyPhysicalBarriers: '', //! protectionClass9And10Questionnaire
		isAnotherWaterSource: '', //! protectionClass9And10Questionnaire
		describeWaterSource: '', //! protectionClass9And10Questionnaire
		distanceOfWaterSource: '', //! protectionClass9And10Questionnaire
		isWaterSourceAccessibleYearRound: '', //! protectionClass9And10Questionnaire
		anyFullTimeEmployees: '', //! protectionClass9And10Questionnaire
		describeAnyFullTimeEmployees: '', //! protectionClass9And10Questionnaire
		isOccupiedYearRound: '', //! protectionClass9And10Questionnaire
		describeIsOccupiedYearRound: '', //! protectionClass9And10Questionnaire
		protectionHighComments: '', //! protectionClass9And10Questionnaire
		additionalCommentsRemarks: '', //! policy
	},

	formLienholderLossPayeeList: [], //! lienholder
	formLienholderMortgageeList: [], //! lienholder
	formLienholderLendersList: [], //! lienholder
	formLienholderContractList: [], //! lienholder
	formLossList: [], //! lossHistory
	formPreviousInsuranceList: [], //! previousInsuranceItems
};

const newQuoteSlice = createSlice({
	name: 'newQuote',
	initialState,
	reducers: {
		setFormStep1: (state, action: PayloadAction<FormStep1Values>) => {
			state.formStep1 = action.payload;
		},
		setFormStep2: (state, action: PayloadAction<FormStep2Values>) => {
			state.formStep2 = action.payload;
		},
		setFormStep3: (state, action: PayloadAction<FormStep3Values>) => {
			state.formStep3 = action.payload;
		},
		setFormStep4: (state, action: PayloadAction<FormStep4Values>) => {
			state.formStep4 = action.payload;
		},
		setQuoteData: (state, action: PayloadAction<QuoteResponce>) => {
			state.quoteData = action.payload;
		},
		setFormStep6: (state, action: PayloadAction<FormStep6Values>) => {
			state.formStep6 = action.payload;
		},
		setFormLienholderList: (state, action: PayloadAction<FormLienholderValues>) => {
			if (action.payload.lienholderType === 'Loss Payee') {
				let index = Number(action.payload.lienholderRank.slice(0, -2)) - 1;
				state.formLienholderLossPayeeList.splice(index, 0, action.payload);
				state.formLienholderLossPayeeList = state.formLienholderLossPayeeList.map((item, index) => {
					item.lienholderRank = rankList[index];
					return item;
				});
			} else if (action.payload.lienholderType === 'Mortgagee') {
				let index = Number(action.payload.lienholderRank.slice(0, -2)) - 1;
				state.formLienholderMortgageeList.splice(index, 0, action.payload);
				state.formLienholderMortgageeList = state.formLienholderMortgageeList.map((item, index) => {
					item.lienholderRank = rankList[index];
					return item;
				});
			} else if (action.payload.lienholderType === 'Lenders Loss Payable') {
				let index = Number(action.payload.lienholderRank.slice(0, -2)) - 1;
				state.formLienholderLendersList.splice(index, 0, action.payload);
				state.formLienholderLendersList = state.formLienholderLendersList.map((item, index) => {
					item.lienholderRank = rankList[index];
					return item;
				});
			} else if (action.payload.lienholderType === 'Contract of sale') {
				let index = Number(action.payload.lienholderRank.slice(0, -2)) - 1;
				state.formLienholderContractList.splice(index, 0, action.payload);
				state.formLienholderContractList = state.formLienholderContractList.map((item, index) => {
					item.lienholderRank = rankList[index];
					return item;
				});
			} else {
				console.log('Wrong Lienholder Type');
			};
		},
		editLienholderEntry: (state, action: PayloadAction<{ lienholderId: string, lienholderType: string, formLienholderValues: FormLienholderValues }>) => {
			if (action.payload.lienholderType === 'Loss Payee') {
				state.formLienholderLossPayeeList = state.formLienholderLossPayeeList.filter((item) => item.lienholderId !== action.payload.lienholderId);
				let index = Number(action.payload.formLienholderValues.lienholderRank.slice(0, -2)) - 1;
				state.formLienholderLossPayeeList.splice(index, 0, action.payload.formLienholderValues);
				state.formLienholderLossPayeeList = state.formLienholderLossPayeeList.map((item, index) => {
					item.lienholderRank = rankList[index];
					return item;
				});
			} else if (action.payload.lienholderType === 'Mortgagee') {
				state.formLienholderMortgageeList = state.formLienholderMortgageeList.filter((item) => item.lienholderId !== action.payload.lienholderId);
				let index = Number(action.payload.formLienholderValues.lienholderRank.slice(0, -2)) - 1;
				state.formLienholderMortgageeList.splice(index, 0, action.payload.formLienholderValues);
				state.formLienholderMortgageeList = state.formLienholderMortgageeList.map((item, index) => {
					item.lienholderRank = rankList[index];
					return item;
				});
			} else if (action.payload.lienholderType === 'Lenders Loss Payable') {
				state.formLienholderLendersList = state.formLienholderLendersList.filter((item) => item.lienholderId !== action.payload.lienholderId);
				let index = Number(action.payload.formLienholderValues.lienholderRank.slice(0, -2)) - 1;
				state.formLienholderLendersList.splice(index, 0, action.payload.formLienholderValues);
				state.formLienholderLendersList = state.formLienholderLendersList.map((item, index) => {
					item.lienholderRank = rankList[index];
					return item;
				});
			} else if (action.payload.lienholderType === 'Contract of sale') {
				state.formLienholderContractList = state.formLienholderContractList.filter((item) => item.lienholderId !== action.payload.lienholderId);
				let index = Number(action.payload.formLienholderValues.lienholderRank.slice(0, -2)) - 1;
				state.formLienholderContractList.splice(index, 0, action.payload.formLienholderValues);
				state.formLienholderContractList = state.formLienholderContractList.map((item, index) => {
					item.lienholderRank = rankList[index];
					return item;
				});
			} else {
				console.log('Wrong Lienholder Type');
			};
		},
		deleteLienholderEntry: (state, action: PayloadAction<{ lienholderType: string, lienholderRank: string }>) => {
			if (action.payload.lienholderType === 'Loss Payee') {
				let index = Number(action.payload.lienholderRank.slice(0, -2)) - 1;
				state.formLienholderLossPayeeList.splice(index, 1);
				state.formLienholderLossPayeeList = state.formLienholderLossPayeeList.map((item, index) => {
					item.lienholderRank = rankList[index];
					return item;
				});
			} else if (action.payload.lienholderType === 'Mortgagee') {
				let index = Number(action.payload.lienholderRank.slice(0, -2)) - 1;
				state.formLienholderMortgageeList.splice(index, 1);
				state.formLienholderMortgageeList = state.formLienholderMortgageeList.map((item, index) => {
					item.lienholderRank = rankList[index];
					return item;
				});
			} else if (action.payload.lienholderType === 'Lenders Loss Payable') {
				let index = Number(action.payload.lienholderRank.slice(0, -2)) - 1;
				state.formLienholderLendersList.splice(index, 1);
				state.formLienholderLendersList = state.formLienholderLendersList.map((item, index) => {
					item.lienholderRank = rankList[index];
					return item;
				});
			} else if (action.payload.lienholderType === 'Contract of sale') {
				let index = Number(action.payload.lienholderRank.slice(0, -2)) - 1;
				state.formLienholderContractList.splice(index, 1);
				state.formLienholderContractList = state.formLienholderContractList.map((item, index) => {
					item.lienholderRank = rankList[index];
					return item;
				});
			} else {
				console.log('Wrong Lienholder Type');
			};
		},
		setFormLossList: (state, action: PayloadAction<FormLossValues>) => {
			state.formLossList.push(action.payload);
		},
		editLossEntry: (state, action: PayloadAction<{ lossId: string, formLossValues: FormLossValues }>) => {
			let index = state.formLossList.findIndex((item) => item.lossId === action.payload.lossId);
			state.formLossList[index] = action.payload.formLossValues;
		},
		deleteLossEntry: (state, action: PayloadAction<string>) => {
			state.formLossList = state.formLossList.filter((item) => item.lossId !== action.payload);
		},
		setFormPreviousInsurance: (state, action: PayloadAction<FormPreviousInsuranceValues>) => {
			state.formPreviousInsuranceList.push(action.payload);
		},
		editPreviousInsuranceEntry: (state, action: PayloadAction<{ previousInsuranceId: string, formPreviousInsuranceValues: FormPreviousInsuranceValues }>) => {
			let index = state.formPreviousInsuranceList.findIndex((item) => item.insuranceId === action.payload.previousInsuranceId);
			state.formPreviousInsuranceList[index] = action.payload.formPreviousInsuranceValues;
		},
		deletePreviousInsuranceEntry: (state, action: PayloadAction<string>) => {
			state.formPreviousInsuranceList = state.formPreviousInsuranceList.filter((item) => item.insuranceId !== action.payload);
		},
	},
});

export const {
	setFormStep1,
	setFormStep2,
	setFormStep3,
	setFormStep4,
	setQuoteData,
	setFormStep6,
	setFormLienholderList,
	editLienholderEntry,
	deleteLienholderEntry,
	setFormLossList,
	editLossEntry,
	deleteLossEntry,
	setFormPreviousInsurance,
	editPreviousInsuranceEntry,
	deletePreviousInsuranceEntry,
} = newQuoteSlice.actions;
export const newQuoteReducer = newQuoteSlice.reducer;
