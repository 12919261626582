import { PayloadAction, createSlice, AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit';
import { AuthorizationState, ServerAuthResponse } from '../models/models';
import { REPORTS_BASE_URL, POSTFIX_LOGIN } from '../constants';

const initialState: AuthorizationState = {
	// access_token: localStorage.getItem('accessTokenHO') ?? null,
	// refresh_token: localStorage.getItem('refreshTokenHO') ?? null,
	access_token: null,
	refresh_token: null,
	loader: false,
	error: '',
};

type PayloadType = {
	access_token: string;
	refresh_token: string;
};

export const logInUser: AsyncThunk<ServerAuthResponse, undefined, { rejectValue: string }> = createAsyncThunk<
	ServerAuthResponse,
	undefined,
	{ rejectValue: string }>(
		'authorization/logInUser',
		async (_, { rejectWithValue }) => {
			try {
				const response = await fetch(`${REPORTS_BASE_URL}${POSTFIX_LOGIN}`, {
					method: 'POST',
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json',
					},
				});

				if (!response.ok) {
					throw new Error(`Request failed with status code ${response.status}`);
				};

				const data = await response.json() as ServerAuthResponse;
				return data;
			} catch (e) {
				const err = e as Error;
				// console.log('Error: ', err.message);
				return rejectWithValue(err.message);
			};
		}
	);

const authorizationSlice = createSlice({
	name: 'authorization',
	initialState: initialState,
	reducers: {
		setCredentials: (state, action: PayloadAction<PayloadType>) => {
			state.access_token = action.payload.access_token;
			state.refresh_token = action.payload.refresh_token;
			console.log('action.payload.access_token=', action.payload.access_token);
			console.log('action.payload.refresh_token=', action.payload.refresh_token);
			// localStorage.setItem('accessTokenHO', action.payload.access_token);
			// localStorage.setItem('refreshTokenHO', action.payload.refresh_token);
		},
		logOut: (state) => {
			state.access_token = null;
			state.refresh_token = null;
			// localStorage.setItem('accessTokenHO', '');
			// localStorage.setItem('refreshTokenHO', '');
		},
	},
	extraReducers: (builder) => {
		builder.addCase(logInUser.pending, (state) => {
			state.loader = true;
			state.error = '';
		});
		builder.addCase(logInUser.fulfilled, (state, action) => {
			state.access_token = action.payload.data.access_token;
			state.refresh_token = action.payload.data.refresh_token;
			// localStorage.setItem('accessTokenHO', action.payload.data.access_token ?? '');
			// localStorage.setItem('refreshTokenHO', action.payload.data.refresh_token ?? '');
			// console.log('whole response from server: ', action.payload);
			// console.log('data.accessToken: ', action.payload.data.access_token);
			// console.log('data.refreshToken: ', action.payload.data.refresh_token);
			state.loader = false;
			state.error = '';
		});
		builder.addCase(logInUser.rejected, (state, action) => {
			state.access_token = null;
			state.refresh_token = null;
			state.loader = false;
			state.error = action.payload ?? 'Error: something went wrong';
			console.log(state.error);
		});
	},
});

export const { setCredentials, logOut } = authorizationSlice.actions;
export const authorizationReducer = authorizationSlice.reducer;
