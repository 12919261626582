import classNames from "classnames";
import { createPortal } from "react-dom";
import { rootPortal } from '../../index';
import { Modal } from "../Modal/Modal";
import { useAppSelector } from "../../store/hookTypes";
import styles from "../FormSteps/FormSteps.module.css";
import { ErrorMessage } from "../ErrorMessage/ErrorMessage";
import { LossHistoryProps } from "../../models/models";
import { useEffect, useState } from "react";
import { LossList } from "../LossList/LossList";
import { FormAddLoss } from "../FormAddLoss/FormAddLoss";
import { CheckboxInvisible } from "../Checkbox/CheckboxInvisible";
import { SelectDisabled } from "../Select/SelectDisabled";

export const LossHistory = ({ register, errors, setValue }: LossHistoryProps) => {
	const lossAnswer = useAppSelector((state) => state.newQuote.formStep4.anyClaimsOrLossesInLast);
	const isLoss = lossAnswer === "Yes";

	const [isModalOpen, setIsModalOpen] = useState(false);

	const { formLossList } = useAppSelector((state) => state.newQuote);

	useEffect(() => {
		if (formLossList.length > 0) {
			setValue('lossCheckbox', ["ok"], { shouldValidate: true });
		} else {
			setValue('lossCheckbox', [""], { shouldValidate: true });
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formLossList.length]);

	const handleAddLoss = () => {
		setIsModalOpen(true);
	};

	return (
		<>
			{
				isLoss &&
				(<div className={styles["grid-container-3"]}>

					<h3 className={classNames(styles["title"], styles["grid-item-wide-3"])}>Loss History</h3>

					<SelectDisabled
						fieldName="anyClaimsOrLossesInLast-disabled"
						labelText="Any claims or losses in last 3 years?"
						value="Yes"
						optionsList={["Yes"]}
					/>

					<div className={classNames(styles["grid-item"], styles["box-row-end-button"])}>
						<button
							type="button"
							className={classNames(styles["add-button"], errors?.lossCheckbox && styles["error-button"])}
							onClick={handleAddLoss}
						>
							Add Loss Record<br></br>+
						</button>
						{<ErrorMessage errorMessage={errors?.lossCheckbox ? (errors?.lossCheckbox?.message as string) : ''} />}
					</div>

					{/* INVISIBLE CHECKBOX */}
					<CheckboxInvisible
						name="lossCheckbox"
						requiredText="Add at least one entry"
						register={register}
					/>

					<LossList />

					{
						isModalOpen &&
						createPortal(
							<Modal title={"Adding a new entry"} onClose={setIsModalOpen}>
								<FormAddLoss setIsModalOpen={setIsModalOpen} />
							</Modal>,
							rootPortal)
					}

				</div>)
			}
		</>
	);
};
