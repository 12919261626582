import { useState } from 'react';
import { pdfjs } from 'react-pdf';
import { Document, Page } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import styles from "./PDFViewer.module.css";
// import { FaSearchMinus } from "react-icons/fa";
// import { FaSearchPlus } from "react-icons/fa";
import { FiPlusCircle } from "react-icons/fi";
import { FiMinusCircle } from "react-icons/fi";
import { IconContext } from "react-icons";

// Configure PDF.js worker:
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
	'pdfjs-dist/build/pdf.worker.min.js',
	import.meta.url,
).toString();

export const PDFViewer = () => {
	const [numPages, setNumPages] = useState<number>(0);
	const [pageNumber, setPageNumber] = useState<number>(1);
	const [scale, setScale] = useState<number>(1.0);

	const increaseScale = () => setScale((prev) => prev + 0.1);

	const decreaseScale = () => setScale((prev) => prev - 0.1);

	const goToPrevPage = () => setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);

	const goToNextPage = () => setPageNumber(pageNumber + 1 >= numPages ? numPages : pageNumber + 1);

	const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
		setNumPages(numPages);
		setPageNumber(1);
	};

	return (
		<IconContext.Provider value={{ color: "#3B3764", size: "20px" }}>
			<div className={styles["container"]}>
				<div className={styles["header"]}>

					<div className={styles["scale-buttons"]}>
						<button className={styles["scale-btn"]} type="button" onClick={increaseScale}>
							<FiPlusCircle />
						</button>
						<button className={styles["scale-btn"]} type="button" onClick={decreaseScale}>
							<FiMinusCircle />
						</button>
					</div>

					<nav className={styles["page-buttons"]}>
						<button className={styles["page-btn"]} type="button" onClick={goToPrevPage}>Prev</button>
						<p className={styles["page-counter"]}>page {pageNumber} of {numPages}</p>
						<button className={styles["page-btn"]} type="button" onClick={goToNextPage}>Next</button>
					</nav>

				</div>

				<Document
					file="/pdf-doc-Insuremyk9.pdf"
					onLoadSuccess={onDocumentLoadSuccess}
					className={styles["document"]}
				>
					<Page
						height={1000}
						scale={scale}
						pageNumber={pageNumber}
						className={styles["page"]}
					/>
				</Document>
			</div>
		</IconContext.Provider>
	);
};
