import styles from "../FormSteps/FormSteps.module.css";
import { SelectBaseProps } from "../../models/models";
import classNames from "classnames";

export const SelectBaseRow = ({
	children,
	fieldName,
	labelText,
	optionsList,
	register,
	errors,
	requiredText,
	optionsValueTextObject,
	onChange }: SelectBaseProps) => {
	// console.log('optionsList: ', optionsList, 'optionsValueTextObject: ', optionsValueTextObject);
	return (
		<div className={classNames(styles["grid-item"], styles["grid-item-wide-3"])}>
			<div className={styles["box-row-near"]}>
				<div className={styles["one-third-page-select"]}>
					<select
						{...register(fieldName, {
							onChange: onChange ?? null, // onChange is optional
							required: requiredText ?? false, // requiredText is optional
						})}
						name={fieldName}
						className={
							requiredText ?
								classNames(styles["dropdown-field"], styles["w-select"], errors[fieldName] && styles["error"]) :
								classNames(styles["dropdown-field"], styles["w-select"], styles["optional-field"])
						}
						id={fieldName}>
						<option value="">-- Choose --</option>
						{
							(optionsValueTextObject && (Object.keys(optionsValueTextObject).length > 0)) ?
								Object.entries(optionsValueTextObject).map(([key, value]) => (<option key={key} value={key}>{value}</option>)) :
								optionsList.length > 0 ?
									optionsList.map((item) => (<option key={item} value={item}>{item}</option>)) :
									(<option value="Unknown">Unknown</option>)
						}
					</select>
					{children}
				</div>
				<label htmlFor={fieldName} className={styles["form-field-label"]}>
					{labelText}
				</label>
			</div>
		</div>
	);
};
