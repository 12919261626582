import { Outlet } from "react-router-dom";
import { useAppSelector } from "../../store/hookTypes";
import { RecallToken } from "./RecallToken";

export const CheckToken = () => {
	const token = useAppSelector((state) => state.authorization.access_token);

	return (
		token ?
			<Outlet /> :
			<RecallToken />
	);
};
