import { useState, ChangeEvent, MouseEvent } from 'react';
import styles from './RadioMapRisk.module.css';

export type RadioMapRiskProps = {
	isVerified: boolean;
	checkedStreet: string;
	checkedCity: string;
	checkedState: string;
	checkedZip: string;
	handleApplyingAddress: (event: MouseEvent<HTMLButtonElement>, radioValue: string) => void;
};

export const RadioMapRisk = ({
	isVerified,
	checkedStreet,
	checkedCity,
	checkedState,
	checkedZip,
	handleApplyingAddress,
}: RadioMapRiskProps) => {
	const [formAddressVerification, setFormAddressVerification] = useState('radioVerified');

	const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target as HTMLInputElement;
		setFormAddressVerification(value);
	};

	return (
		<div className={styles["box"]}>
			<fieldset className={styles["fieldset"]}>

				{
					isVerified ?
						(<div className={styles["option"]}>
							<input
								type="radio"
								name="radioMapRisk"
								value="radioVerified"
								id="radioVerified"
								checked={formAddressVerification === 'radioVerified'}
								onChange={handleRadioChange}
							/>
							<label htmlFor="radioVerified">
								After verification, this address record is offered:
								<span className={styles["checkedStreet"]}>
									{` ${checkedStreet}, ${checkedCity}, ${checkedState} ${checkedZip}, US`}
								</span>
							</label>
						</div>) :
						<div>{`Address "${checkedStreet}, ${checkedCity}, ${checkedState} ${checkedZip}" was not found in MapRisk database. Change the address OR Choose "Use Manually Entered Address" option to proceed with above address.`}</div>
				}

				<div className={styles["option"]}>
					<input
						type="radio"
						name="radioMapRisk"
						value="radioManually"
						id="radioManually"
						checked={formAddressVerification === 'radioManually'}
						onChange={handleRadioChange}
					/>
					<label htmlFor="radioManually">Use manually entered address</label>
				</div>

			</fieldset>

			<button
				type="button"
				className={styles["sign-apply-button"]}
				onClick={(event) => handleApplyingAddress(event, formAddressVerification)}
			>
				Apply&nbsp;the&nbsp;option
			</button>
		</div>
	);
};
