import styles from './FormSteps.module.css';
import { useForm, SubmitHandler } from 'react-hook-form';
import { ErrorMessage } from '../ErrorMessage/ErrorMessage';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState, MouseEvent, ChangeEvent, FocusEvent } from 'react';
import classNames from 'classnames';
import { CheckAddressParams, DistanceToCoast, FormStep2Values } from '../../models/models';
import { useAppSelector, useAppDispatch } from '../../store/hookTypes';
import { setFormStep1, setFormStep2 } from '../../store/newQuoteSlice';
import { useLazyCheckAddressQuery, useLazyGetDistanceToCoastQuery, useLazyGetLocationWithZipQuery } from '../../store/mainApiQueries';
import { createPortal } from "react-dom";
import { rootPortal } from '../../index';
import { Loader } from '../Loader/Loader';
import { setCheckedAddress, setIsAddressValidated, setIsStreetValidated, setIsCityValidated, setDataDTC, setOptionDTC, setIsStreetManuallyEntered } from '../../store/checkedAddressSlice';
import { getDtcFeets } from '../../helpers/getDtcFeets';
import { getDtcOption } from '../../helpers/getDtcOption';
import { RadioMapRisk } from '../RadioMapRisk/RadioMapRisk';
import { InputTextRequired } from '../InputText/InputTextRequired';
import { InputTextOptional } from '../InputText/InputTextOptional';
import { SelectRequired } from '../Select/SelectRequired';
import { CheckboxInvisible } from '../Checkbox/CheckboxInvisible';

//TODO: обработка isError, isErrorDTC, isErrorZip при помощи ErrorBoundary
export const FormStep2 = () => {
	const { formStep2 } = useAppSelector((state) => state.newQuote);
	const { formStep1 } = useAppSelector((state) => state.newQuote);
	const { locationWithZip } = useAppSelector((state) => state.locationWithZip);
	const { checkedAddress, isAddressValidated, isStreetValidated, isCityValidated, isStreetManuallyEntered } = useAppSelector((state) => state.checkedAddress);

	const [checkAddressObj, setCheckAddressObj] = useState<CheckAddressParams>({
		zip: locationWithZip.zip,
		city: formStep2.city ?? "",
		state: locationWithZip.state.short,
		street: formStep2.street ?? "",
	});

	const [isAddressVerified, setIsAddressVerified] = useState<boolean>(isAddressValidated);
	const [isStreetEqual, setIsStreetEqual] = useState<boolean>(isStreetValidated);
	const [isCityEqual, setIsCityEqual] = useState<boolean>(isCityValidated);
	const [isStreetManually, setIsStreetManually] = useState<boolean>(isStreetManuallyEntered);
	const [citiesZipList, setCitiesZipList] = useState<Array<string>>(locationWithZip.city);

	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const [checkAddress, { data: dataCheckedAddress, isLoading, isFetching, isError }] = useLazyCheckAddressQuery();

	const [getDistanceToCoast, { data: dataDTC, isLoading: isLoadingDTC, isFetching: isFetchingDTC, isError: isErrorDTC }] = useLazyGetDistanceToCoastQuery();

	const [getLocationWithZip, { data: dataZip, isLoading: isLoadingZip, isFetching: isFetchingZip, isError: isErrorZip }] = useLazyGetLocationWithZipQuery();

	const {
		register,
		watch,
		formState: { errors, isSubmitSuccessful },
		handleSubmit,
		setValue,
	} = useForm<FormStep2Values>({
		mode: 'onSubmit',
		defaultValues: formStep2,
	});

	const watchAllFields = watch(); // все поля формы - для сохранения в store, когда нажимается Previous

	useEffect(() => {
		if (locationWithZip.county.length > 0) {
			setValue('county', locationWithZip.county);
		};
	}, [locationWithZip, setValue]);

	useEffect(() => {
		if (locationWithZip.state.short.length > 0) {
			setValue('locationState', locationWithZip.state.short);
		};
	}, [locationWithZip, setValue]);

	useEffect(() => {
		if (dataCheckedAddress) {
			console.log('dataCheckedAddress', dataCheckedAddress);
			dispatch(setCheckedAddress(dataCheckedAddress)); // saving checked address to the store

			setIsAddressVerified(dataCheckedAddress.verified);
			dispatch(setIsAddressValidated(dataCheckedAddress.verified)); // saving isAddressValidated to the store
			setIsStreetEqual(dataCheckedAddress.geocoded.street === checkAddressObj.street);
			dispatch(setIsStreetValidated(dataCheckedAddress.geocoded.street === checkAddressObj.street)); // saving isStreetValidated to the store
			setIsCityEqual(dataCheckedAddress.geocoded.city === checkAddressObj.city);
			dispatch(setIsCityValidated(dataCheckedAddress.geocoded.city === checkAddressObj.city)); // saving isCityValidated to the store

			if (dataCheckedAddress.geocoded.street === checkAddressObj.street) {
				setIsStreetManually(false);
				dispatch(setIsStreetManuallyEntered(false)); // saving isStreetManuallyEntered to the store
			};
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dataCheckedAddress]);

	useEffect(() => {
		// dataZip появляется, если текущий город не совпадает с городом, который пришёл из верификации адреса (checkAddress)
		if (dataZip) {
			console.log('dataZip', dataZip);

			// устанавливаю новый zip
			dispatch(setFormStep1({
				...formStep1,
				zipCode: dataZip.zip,
			}));

			setCitiesZipList(dataZip.city); // устанавливаю новый список опций городов для select
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dataZip]);

	useEffect(() => {
		// меняю город, если он не совпадает с городом из валидации адреса (в таком случае появится dataZip)
		if (citiesZipList.length > 0 && dataZip && dataCheckedAddress) {
			setValue('city', dataCheckedAddress?.geocoded.city ?? '');
		};
	}, [citiesZipList, dataCheckedAddress, dataZip, setValue]);

	useEffect(() => {
		// валидация формы для перехода на другой step
		if ((isAddressVerified && isStreetEqual && isCityEqual) || isStreetManually) {
			setValue('addressVerificationCheckbox', ["ok"], { shouldValidate: true });
		} else {
			setValue('addressVerificationCheckbox', [""], { shouldValidate: true });
		};
	}, [isAddressVerified, isStreetEqual, isCityEqual, isStreetManually, setValue]);

	useEffect(() => {
		if (dataDTC) {
			console.log('dataDTC', dataDTC);

			dispatch(setDataDTC(dataDTC)); // saving dataDTC to the store

			const dtcValue: number = getDtcFeets(dataDTC as DistanceToCoast);
			// console.log('dtcValue=', dtcValue);
			const dtcOption: string = getDtcOption(dtcValue);	// getting dtcOption
			// console.log('dtcOption=', dtcOption);

			dispatch(setOptionDTC(dtcOption)) // saving dtcOption to the store

			navigate('/new-quote-step3');
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dataDTC]);

	useEffect(() => {
		if (isSubmitSuccessful && isStreetManually) {
			navigate('/new-quote-step3');
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSubmitSuccessful]);

	const handleApplyingAddress = (event: MouseEvent<HTMLButtonElement>, radioValue: string) => {
		event.preventDefault();

		if (radioValue === "radioVerified") {
			setValue('street', dataCheckedAddress?.geocoded.street ?? '');
			setIsStreetEqual(true);
			dispatch(setIsStreetValidated(true)); // saving isStreetValidated to the store
			setIsCityEqual(true);
			dispatch(setIsCityValidated(true)); // saving isCityValidated to the store
			setIsStreetManually(false);
			dispatch(setIsStreetManuallyEntered(false)); // saving isStreetManuallyEntered to the store

			if (formStep1.zipCode !== dataCheckedAddress?.geocoded.zip) {
				console.log('formStep1.zipCode=', formStep1.zipCode);
				console.log('dataCheckedAddress?.geocoded.zip=', dataCheckedAddress?.geocoded.zip);
				console.log({
					...formStep1,
					zipCode: dataCheckedAddress?.geocoded.zip ?? '',
				});

				getLocationWithZip(dataCheckedAddress?.geocoded.zip ?? ''); // API: getting location with zip - same as in FormStep1
			};
		} else if (radioValue === "radioManually") {
			// setIsStreetEqual(false);
			// dispatch(setIsStreetValidated(false)); // saving isStreetValidated to the store
			// setIsCityEqual(false);
			// dispatch(setIsCityValidated(false)); // saving isCityValidated to the store
			setIsStreetManually(true);
			dispatch(setIsStreetManuallyEntered(true)); // saving isStreetManuallyEntered to the store
		} else {
			console.log('Unexpected radioValue in handleApplyingAddress');
		};
	};

	const handleEntry = (event: FocusEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>) => {
		// setIsAddressVerified(false);
		setIsStreetEqual(false);
		setIsCityEqual(false);
		setIsStreetManually(false);

		const { name, value } = event.target as HTMLInputElement | HTMLSelectElement;

		setCheckAddressObj((prev) => {
			return {
				...prev,
				[name]: value,
			};
		});

		if (name === "street" && (checkAddressObj.city.length > 0)) {
			checkAddress({
				zip: locationWithZip.zip,
				city: checkAddressObj.city,
				state: locationWithZip.state.short,
				street: value,
			});
			// getDistanceToCoast({
			// 	zip: locationWithZip.zip,
			// 	city: checkAddressObj.city,
			// 	state: locationWithZip.state.short,
			// 	street: value,
			// });
		} else if (name === "city" && (checkAddressObj.street.length > 0)) {
			checkAddress({
				zip: locationWithZip.zip,
				city: value,
				state: locationWithZip.state.short,
				street: checkAddressObj.street,
			});
			// getDistanceToCoast({
			// 	zip: locationWithZip.zip,
			// 	city: value,
			// 	state: locationWithZip.state.short,
			// 	street: checkAddressObj.street,
			// });
		};
	};

	const onSubmit: SubmitHandler<FormStep2Values> = (data) => {
		console.log('FormStep2=', data);
		dispatch(setFormStep2(data));

		// console.log('obj for getting DistanceToCoast', {
		// 	zip: locationWithZip.zip,
		// 	city: checkAddressObj.city,
		// 	state: locationWithZip.state.short,
		// 	street: checkAddressObj.street,
		// });

		if (!isStreetManually) {
			getDistanceToCoast({
				zip: locationWithZip.zip,
				city: checkAddressObj.city,
				state: locationWithZip.state.short,
				street: checkAddressObj.street,
			});
		};
	};

	const handlePreviousClick = (event: MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		dispatch(setFormStep2(watchAllFields));
		navigate('/new-quote-step1');
	};

	if (isError) {
		console.log('Error in FormStep2 with checkAddress');
	};

	if (isErrorDTC) {
		console.log('Error in FormStep2 with getDistanceToCoast');
	};

	if (isErrorZip) {
		console.log('Error in FormStep2 with getLocationWithZip');
	};

	return (
		<>
			<form
				onSubmit={handleSubmit(onSubmit)}
				className={styles["form"]}
				id="form2"
			>

				<div className={styles["grid-container"]}>

					<InputTextRequired
						fieldName="street"
						labelText="Street Address"
						placeholder="i.e. 123 Main Street"
						register={register}
						requiredText="Enter your address"
						errors={errors}
						onBlur={handleEntry}
					/>

					<InputTextOptional
						fieldName="street2"
						labelText="Street Address 2"
						placeholder="i.e. 77 Green Street"
						register={register}
						errors={errors}
					/>

					<SelectRequired
						fieldName="city"
						labelText="City"
						optionsList={citiesZipList}
						register={register}
						errors={errors}
						requiredText="Choose the City"
						onChange={handleEntry}
					/>

					<SelectRequired
						fieldName="county"
						labelText="County"
						optionsList={[locationWithZip.county]}
						register={register}
						errors={errors}
						requiredText="Choose the County"
					/>

					<SelectRequired
						fieldName="locationState"
						labelText="State"
						optionsList={[]}
						register={register}
						errors={errors}
						requiredText="Choose the State"
						optionsValueTextObject={{ [locationWithZip.state.short]: locationWithZip.state.long }}
					/>

					{/* INVISIBLE CHECKBOX */}
					<CheckboxInvisible
						name="addressVerificationCheckbox"
						requiredText="Verify your address"
						register={register}
					/>

					{
						dataCheckedAddress &&
						(<div className={classNames(styles["grid-item"], styles["grid-item-wide"])}>
							<div className={classNames(styles["specific-section"], errors?.addressVerificationCheckbox && styles["error"])}>
								<h3 className={styles["specific-section-title"]}>Address verification</h3>
								{
									isStreetManually ?
										<div>The manually entered address is selected.</div> :

										!isAddressVerified ?
											<RadioMapRisk
												isVerified={dataCheckedAddress?.verified}
												checkedStreet={checkAddressObj.street}
												checkedCity={checkAddressObj.city}
												checkedState={checkAddressObj.state}
												checkedZip={checkAddressObj.zip}
												handleApplyingAddress={handleApplyingAddress}
											/> :

											(isStreetEqual && isCityEqual) ?
												<div>Your address is successfully verified!</div> :

												<RadioMapRisk
													isVerified={dataCheckedAddress?.verified}
													checkedStreet={dataCheckedAddress?.geocoded.street}
													checkedCity={dataCheckedAddress?.geocoded.city}
													checkedState={dataCheckedAddress?.geocoded.state}
													checkedZip={dataCheckedAddress?.geocoded.zip}
													handleApplyingAddress={handleApplyingAddress}
												/>
								}
							</div>
							{<ErrorMessage errorMessage={errors?.addressVerificationCheckbox ? (errors?.addressVerificationCheckbox?.message as string) : ''} />}
						</div>)
					}

					{/* Сообщение о верификации, которое видно при повторном посещении этого step'а - без запроса checkAddress */}
					{
						!dataCheckedAddress &&
						(<div className={classNames(styles["grid-item"], styles["grid-item-wide"])}>
							<div className={classNames(styles["specific-section"], errors?.addressVerificationCheckbox && styles["error"])}>
								<h3 className={styles["specific-section-title"]}>Address verification</h3>
								{
									(isAddressVerified && isStreetEqual && isCityEqual) ?
										(<div>Your address is successfully verified!</div>) :

										isStreetManually ?
											(<div>The manually entered address is selected.</div>) :

											checkAddressObj.street.length !== 0 && checkAddressObj.city.length !== 0 ?
												(<RadioMapRisk
													isVerified={checkedAddress?.verified || false}
													checkedStreet={checkedAddress?.geocoded.street || ""}
													checkedCity={checkedAddress?.geocoded.city || ""}
													checkedState={checkedAddress?.geocoded.state || ""}
													checkedZip={checkedAddress?.geocoded.zip || ""}
													handleApplyingAddress={handleApplyingAddress}
												/>) :

												(<div>Enter your address and city to verify them.</div>)
								}
							</div>
						</div>)
					}

				</div>

				<div className={styles["box-row-end"]}>
					<button type="button" className={styles["step-button"]} onClick={handlePreviousClick}>Previous</button>
					<button type="submit" className={styles["step-button"]}>Next step</button>
				</div>

			</form>

			{
				(isLoading || isFetching || isLoadingZip || isFetchingZip || isLoadingDTC || isFetchingDTC) ?
					createPortal(<Loader isDark={true} />, rootPortal) :
					isError ? <div style={{ color: 'red' }}>Address verification error</div > :
						isErrorZip ? <div style={{ color: 'red' }}>Error getting location with zip</div > :
							null
			}
		</>
	);
};
