import { NoteOneRowProps } from '../../models/models';
import styles from './NoteOneRow.module.css';

export const NoteOneRow = ({ text }: NoteOneRowProps) => {
	return (
		<div className={styles['note-message']}>
			{text}
		</div>
	);
};
