import classNames from "classnames";
import styles from "../FormSteps/FormSteps.module.css";
import { RenovationProps } from "../../models/models";
import { useAppSelector } from "../../store/hookTypes";
import { InputTextRequired } from "../InputText/InputTextRequired";
import { InputDateRequired } from "../InputDate/InputDateRequired";
import { YesNoRequiredRow } from "../Select/YesNoRequiredRow";
import { TextareaRequired } from "../Textarea/TextareaRequired";
import { SelectDisabledRow } from "../Select/SelectDisabledRow";

export const Renovation = ({ register, errors }: RenovationProps) => {
	const renovationAnswer = useAppSelector((state) => state.newQuote.formStep4.isAnyRenovationOrReconstruction);
	const isRenovation = renovationAnswer === "Yes";

	return (
		<>
			{
				isRenovation &&
				(<div className={styles["grid-container-3"]}>

					<h3 className={classNames(styles["title"], styles["grid-item-wide-3"])}>Renovation/Reconstruction</h3>

					<SelectDisabledRow
						fieldName="isAnyRenovationOrReconstruction-disabled"
						labelText="Is the dwelling undergoing any renovation or reconstruction?"
						value="Yes"
						optionsList={["Yes"]}
					/>

					<YesNoRequiredRow
						fieldName="isCostMoreThan25Percent"
						labelText="Is the cost of renovation more than 25% of the ACV value of existing building?"
						register={register}
						errors={errors}
					/>

					<YesNoRequiredRow
						fieldName="willBuildingBeLivedIn"
						labelText="Will the building be lived in during the construction period?"
						register={register}
						errors={errors}
					/>

					<YesNoRequiredRow
						fieldName="involveStructuralRenovations"
						labelText="Does any part of the project involve structural renovations?"
						register={register}
						errors={errors}
					/>

					<InputDateRequired
						fieldName="completionDate"
						labelText="Estimated completion date"
						register={register}
						requiredText="Enter the date"
						errors={errors}
					/>

					<InputTextRequired
						fieldName="costOfRenovations"
						labelText="Total cost of renovations"
						placeholder="i.e. 31800"
						register={register}
						requiredText="Enter cost of renovations"
						errors={errors}
						validation={
							{
								pattern: {
									// eslint-disable-next-line no-useless-escape
									value: /^\d+$/g,
									message: "Field must contain only digits"
								},
							}
						}
					/>

					<TextareaRequired
						fieldName="describeRenovationWork"
						labelText="Describe the work being done"
						placeholder=""
						register={register}
						isAppearing={false}
						requiredText="Enter the description"
						errors={errors}
					/>

					<TextareaRequired
						fieldName="describeRenovationApplicant"
						labelText="Describe who is doing the work (applicant, licensed contractor, etc) and liability coverage that is in place for those performing the work"
						placeholder=""
						register={register}
						isAppearing={false}
						requiredText="Enter the description"
						errors={errors}
					/>

				</div>)
			}
		</>
	);
};
