import styles from "../FormSteps/FormSteps.module.css";
// import { useNavigate } from 'react-router-dom';
import { MouseEvent } from 'react';
import { PDFViewer } from "../PDFViewer/PDFViewer";
import { useAppSelector } from "../../store/hookTypes";

export const PolicyDocument = () => {
	const { signatureURL } = useAppSelector((state) => state.signature);

	// const navigate = useNavigate();

	// const handlePreviousPage = (event: MouseEvent<HTMLButtonElement>) => {
	// 	event.preventDefault();
	// 	navigate('/new-quote-step6');
	// };

	const handleApplySignature = (event: MouseEvent<HTMLButtonElement>) => {
		console.log('Apply Signature. Go to the payment');
	};

	return (
		<div className={styles["sign-container"]}>
			<h2 className={styles["sign-title"]}>Form to be signed</h2>
			<h3 className={styles["sign-subtitle"]}>Read the document below and click on the "Apply Signature" button</h3>

			<PDFViewer />

			<div className={styles["sign-buttons-wrapper"]}>
				{/* <button className={styles["cancel-button"]} onClick={handlePreviousPage}>Previous page</button> */}
				<div className={styles["sign-preview"]}>
					{signatureURL ? (
						<img
							className={styles["sign-image"]}
							src={signatureURL}
							alt="User generated signature"
						/>
					) : null}
				</div>
				<button className={styles["sign-apply-button"]} onClick={handleApplySignature}>Apply&nbsp;Signature</button>
			</div>
		</div>
	);
};
