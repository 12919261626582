export const BASE_URL = 'https://evolveapidev.e-bode.com/'; // secondApiQueries
export const REPORTS_BASE_URL = 'https://reportsauapi.e-bode.com/'; // mainApiQueries
export const POSTFIX_RATE = 'api/rate/2';
export const POSTFIX_LOGIN = 'api/login/guest';
export const POSTFIX_ZIP = 'api/info/geo/zip/us';
export const POSTFIX_CHECKADDRESS = 'api/info/geo/maprisk/checkaddress';
export const POSTFIX_DTC = 'api/info/geo/maprisk/dtc';
export const POSTFIX_WIND = 'api/info/fields/wind/ho';

export const BASE_USERNAME = 'evolve';
export const BASE_PASSWORD = '%%32/labor/PULL/teach/09%%';

export const FEETS_IN_MILE = 5280;
export const COUNTIES_TIER1 = ['Bay', 'Brevard', 'Broward', 'Charlotte', 'Citrus', 'Collier', 'Dixie', 'Duval', 'Escambia', 'Flagler', 'Franklin', 'Gulf', 'Hernando', 'Hillsborough', 'Indian River', 'Jefferson', 'Lee', 'Levy', 'Manatee', 'Martin', 'Miami-Dade', 'Monroe', 'Nassau', 'Okaloosa', 'Palm Beach', 'Pasco', 'Pinellas', 'Saint Johns', 'Saint Lucie', 'Santa Rosa', 'Sarasota', 'Taylor', 'Volusia', 'Wakulla', 'Walton'];
export const COUNTIES_HIGH_LIMIT_1 = ['Miami-Dade', 'Broward', 'Palm Beach', 'Monroe'];
export const COUNTIES_HIGH_LIMIT_2 = ['Seminole', 'Orange', 'Lake', 'Osceola'];

export const ROOF_TYPE_MAIN = ["Tar Shingle", "Wood Shingle/Shakes (must be fire retardant)", "Composite Shingle", "Tar & Gravel", "Roll Roofing", "Slate", "Other"];
export const ROOF_TYPE_TILE = ["Barrel Tile", "Flat Tile", "Tile (other)", "Metal"];
// export const dtcOptionsList = ['0 - 0.5 miles', '0.5 - 1 mile', '1 - 2 miles', '2 - 5 miles', '5 - 10 miles', '10 - 15 miles', '15 miles +'];
export const STATES_MAIL = ["N/A", "INTL", "AK-US", "AL-US", "AR-US", "AZ-US", "CA-US", "CO-US", "CT-US", "DC-US", "DE-US", "FL-US", "GA-US", "HI-US", "IA-US", "ID-US", "IL-US", "IN-US", "KS-US", "KY-US", "LA-US", "MA-US", "MD-US", "ME-US", "MI-US", "MN-US", "MO-US", "MS-US", "MT-US", "NC-US", "ND-US", "NE-US", "NH-US", "NJ-US", "NM-US", "NV-US", "NY-US", "OH-US", "OK-US", "OR-US", "PA-US", "PR-US", "RI-US", "SC-US", "SD-US", "TN-US", "TX-US", "USVI-US", "UT-US", "VA-US", "VT-US", "WA-US", "WI-US", "WV-US", "WY-US", "AB-CA", "BC-CA", "MB-CA", "NB-CA", "NL-CA", "NS-CA", "NT-CA", "NU-CA", "ON-CA", "PE-CA", "QC-CA", "SK-CA", "XQ-CA", "YT-CA"];
export const NUMBER_OF_STORIES = ["1", "1.5", "2", "2.5", "3", "3.5", "4", "4.5", "5"];

export const HOW_LONG_VACANT_OPTIONS = ["1-3 months", "3-6 months", "6-9 months", "9-12 months", "1 year", "2 years", "3 years", "4 years", "5 years", "Over 5 years"];
export const REASON_OF_VACANCY_OPTIONS = ["For Sale", "Remodel", "Investment"];
export const CONDITION_OF_BUILDING_OPTIONS = ["New", "Good", "Fair", "Poor"];
