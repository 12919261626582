import styles from "./FormSteps.module.css";
import classNames from "classnames";
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from "../../store/hookTypes";
import { MouseEvent, useEffect, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { FormStep6Values } from "../../models/models";
import { setFormStep6 } from "../../store/newQuoteSlice";
import { LienholderMortgagee } from "../LienholderMortgagee/LienholderMortgagee";
import { Renovation } from "../Renovation/Renovation";
import { LossHistory } from "../LossHistory/LossHistory";
import { ProtectionHighClass } from "../ProtectionHighClass/ProtectionHighClass";
import { PreviousInsuranceCoverage } from "../PreviousInsuranceCoverage/PreviousInsuranceCoverage";
import { AdditionalCommentsRemarks } from "../AdditionalCommentsRemarks/AdditionalCommentsRemarks";
import { InputTextRequired } from "../InputText/InputTextRequired";
import { InputTextOptional } from "../InputText/InputTextOptional";
import { InputDateRequired } from "../InputDate/InputDateRequired";
import { InputDateOptional } from "../InputDate/InputDateOptional";
import { InputEmailRequired } from "../InputEmail/InputEmailRequired";
import { InputPhoneTripleRequired } from "../InputPhone/InputPhoneTripleRequired";
import { InputZipWithZip4 } from "../InputZip/InputZipWithZip4";
import { CONDITION_OF_BUILDING_OPTIONS, HOW_LONG_VACANT_OPTIONS, NUMBER_OF_STORIES, REASON_OF_VACANCY_OPTIONS, STATES_MAIL } from "../../constants";
import { SelectRequired } from "../Select/SelectRequired";
import { YesNoRequiredWithExtraRow } from "../Select/YesNoRequiredWithExtraRow";
import { YesNoRequired } from "../Select/YesNoRequired";
import { YesNoRequiredRow } from "../Select/YesNoRequiredRow";
import { YesNoOptional } from "../Select/YesNoOptional";
import { TextareaRequired } from "../Textarea/TextareaRequired";
import { CheckboxesGroupRequired } from "../Checkbox/CheckboxesGroupRequired";

export const FormStep6 = () => {
	const { formStep6 } = useAppSelector((state) => state.newQuote);
	const { formStep1 } = useAppSelector((state) => state.newQuote);
	const { formStep2 } = useAppSelector((state) => state.newQuote);
	const yearBuilt = useAppSelector((state) => state.newQuote.formStep3.yearBuilt);

	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const {
		register,
		formState: { errors, isSubmitSuccessful },
		handleSubmit,
		watch,
		setFocus,
		setValue,
	} = useForm<FormStep6Values>({
		mode: 'onSubmit',
		defaultValues: formStep6,
	});

	const watchAllFields = watch(); // все поля формы - для сохранения в store, когда нажимается Previous

	const watchSameAsLocation = watch("sameAsLocation");
	const watchCheckboxes = watch("starscheckbox");
	const watchfoundationType = watch("foundationType");
	const watchFireHydrant = watch("fireHydrantWithin");
	const watchSwimmingPool = watch("isThereSwimmingPool");
	const watchBuildingBeVacant = watch("willBuildingBeVacant");

	const [isShowDescribeStars, setIsShowDescribeStars] = useState(false);
	const [isShowDescribeFoundationTypePilings, setIsShowDescribeFoundationTypePilings] = useState(false);
	const [isShowDescribeFoundationTypeOther, setIsShowDescribeFoundationTypeOther] = useState(false);
	const [isShowDescribeSwimmingPool, setIsShowDescribeSwimmingPool] = useState(false);
	const [isShowDescribeBuildingBeVacant, setIsShowDescribeBuildingBeVacant] = useState(false);

	useEffect(() => {
		if (watchSameAsLocation === "Yes") {
			setValue("address1Mail", formStep2.street, { shouldValidate: true });
			setValue("address2Mail", formStep2.street2, { shouldValidate: true });
			setValue("cityMail", formStep2.city, { shouldValidate: true });
			setValue("locationStateMail", `${formStep2.locationState}-US`, { shouldValidate: true });
			setValue("zipCodeMail", formStep1.zipCode, { shouldValidate: true });
			setValue("zip4Mail", formStep1.zip4, { shouldValidate: true });
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [watchSameAsLocation]);

	useEffect(() => {
		if (watchCheckboxes.includes("Politician") ||
			watchCheckboxes.includes("TV/Movie Star/Actor") ||
			watchCheckboxes.includes("Professional Athlete") ||
			watchCheckboxes.includes("Musician") ||
			watchCheckboxes.includes("Celebrity")) {
			setIsShowDescribeStars(true);
		} else {
			setIsShowDescribeStars(false);
		};
	}, [watchCheckboxes]);

	useEffect(() => {
		if (watchCheckboxes.length > 1 && watchCheckboxes.includes("None of these")) {
			setValue("starscheckbox", ["None of these"], { shouldValidate: true });
		};
	}, [setValue, watchCheckboxes]);

	useEffect(() => {
		if (watchfoundationType === "Pilings/stilts") {
			setIsShowDescribeFoundationTypePilings(true);
		} else {
			setIsShowDescribeFoundationTypePilings(false);
		};

		if (watchfoundationType === "Other") {
			setIsShowDescribeFoundationTypeOther(true);
		} else {
			setIsShowDescribeFoundationTypeOther(false);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [watchfoundationType]);

	useEffect(() => {
		if (watchSwimmingPool === "Yes") {
			setIsShowDescribeSwimmingPool(true);
		} else {
			setIsShowDescribeSwimmingPool(false);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [watchSwimmingPool]);

	useEffect(() => {
		if (watchBuildingBeVacant === "Yes") {
			setIsShowDescribeBuildingBeVacant(true);
		} else {
			setIsShowDescribeBuildingBeVacant(false);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [watchBuildingBeVacant]);

	useEffect(() => {
		if (isSubmitSuccessful) {
			navigate('/new-quote-step7');
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSubmitSuccessful]);

	const onSubmit: SubmitHandler<FormStep6Values> = (data) => {
		console.log('FormStep6=', data);
		dispatch(setFormStep6(data));
	};

	const handlePreviousClick = (event: MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		dispatch(setFormStep6(watchAllFields));
		navigate('/new-quote-step5');
	};

	return (
		<>
			<form
				onSubmit={handleSubmit(onSubmit)}
				className={styles["form"]}
				id="form6"
			>

				<div className={styles["grid-container-3"]}>

					<h3 className={classNames(styles["title"], styles["grid-item-wide-3"])}>Mailing Address</h3>

					<YesNoRequiredRow
						fieldName="sameAsLocation"
						labelText="Is the mail address the same as the location address?"
						register={register}
						errors={errors}
					/>

					<InputTextRequired
						fieldName="address1Mail"
						labelText="Street Address"
						placeholder="i.e. 123 Main Street"
						register={register}
						requiredText="Enter the address"
						errors={errors}
					/>

					<InputTextOptional
						fieldName="address2Mail"
						labelText="Street Address 2"
						placeholder="i.e. 77 Green Street"
						register={register}
						errors={errors}
					/>

					<InputTextRequired
						fieldName="cityMail"
						labelText="City"
						placeholder="i.e. Fernandina Beach"
						register={register}
						requiredText="Enter the city"
						errors={errors}
					/>

					<SelectRequired
						fieldName="locationStateMail"
						labelText="State"
						optionsList={STATES_MAIL}
						register={register}
						errors={errors}
						requiredText="Choose the State"
					/>

					<InputZipWithZip4
						fieldName1="zipCodeMail"
						fieldName2="zip4Mail"
						labelText="Zip Code"
						watch={watch}
						setFocus={setFocus}
						register={register}
						errors={errors}
					/>

					<InputTextRequired
						fieldName="contactName"
						labelText="Contact Name"
						placeholder="i.e. Art Vandelay"
						register={register}
						requiredText="Enter contact name"
						errors={errors}
					/>

					<InputPhoneTripleRequired
						fieldName1="phoneNumber1Mail"
						fieldName2="phoneNumber2Mail"
						fieldName3="phoneNumber3Mail"
						labelText="Phone Number"
						watch={watch}
						setFocus={setFocus}
						register={register}
						requiredText="Enter the phone number"
						errors={errors}
					/>

					<InputEmailRequired
						fieldName="emailMail"
						labelText="Email"
						placeholder="i.e. artvandelay@email.com"
						register={register}
						errors={errors}
					/>

					<InputTextRequired
						fieldName="applicantEmployer"
						labelText="Applicant Employer"
						placeholder="i.e. Vandelay Industries"
						register={register}
						requiredText="Enter applicant employer"
						errors={errors}
					/>

					<InputTextRequired
						fieldName="occupation"
						labelText="Occupation"
						placeholder="i.e. Architect"
						register={register}
						requiredText="Enter occupation"
						errors={errors}
					/>

					<InputDateRequired
						fieldName="dateOfBirth"
						labelText="Date of Birth"
						register={register}
						requiredText="Enter the date of birth"
						errors={errors}
					/>

					<InputTextOptional
						fieldName="coApplicantEmployer"
						labelText="Co-Applicant Employer"
						placeholder="i.e. Black's Bookstore"
						register={register}
						errors={errors}
					/>

					<InputTextOptional
						fieldName="coApplicantOccupation"
						labelText="Occupation"
						placeholder="i.e. Manager"
						register={register}
						errors={errors}
					/>

					<InputDateOptional
						fieldName="coApplicantDateOfBirth"
						labelText="Date of Birth"
						register={register}
					/>

					<div className={classNames(styles["grid-item"], styles["grid-item-wide-3"])}>

						<CheckboxesGroupRequired
							groupName="starscheckbox"
							legend="Does the occupation of any applicant or household member include the following?"
							valuesList={["Politician", "TV/Movie Star/Actor", "Professional Athlete", "Musician", "Celebrity", "None of these"]}
							register={register}
							errors={errors}
							requiredText="Choose at least one option"
						/>

					</div>

					{
						isShowDescribeStars &&
						<TextareaRequired
							fieldName="describeStars"
							labelText="Describe this occupation"
							placeholder="i.e. An actor at the Apollo Theater"
							register={register}
							isAppearing={true}
							requiredText="Enter the description"
							errors={errors}
						/>
					}

				</div>

				<div className={styles["grid-container-3"]}>

					<h3 className={classNames(styles["title"], styles["grid-item-wide-3"])}>Building Details</h3>

					<InputTextRequired
						fieldName="wiringYear"
						labelText="Wiring Year"
						placeholder="i.e. 2015"
						register={register}
						requiredText="Enter wiring year"
						errors={errors}
						maxLength={4}
						validation={
							{
								min: {
									value: yearBuilt,
									message: 'Minimum year is equal to the year built',
								},
								pattern: {
									// eslint-disable-next-line no-useless-escape
									value: /^\d+$/g,
									message: "Year must contain only digits"
								},
								minLength: {
									value: 4,
									message: "Wrong year format"
								},
							}
						}
					/>

					<InputTextRequired
						fieldName="heatingYear"
						labelText="Heating Year"
						placeholder="i.e. 2015"
						register={register}
						requiredText="Enter heating year"
						errors={errors}
						maxLength={4}
						validation={
							{
								min: {
									value: yearBuilt,
									message: 'Minimum year is equal to the year built',
								},
								pattern: {
									// eslint-disable-next-line no-useless-escape
									value: /^\d+$/g,
									message: "Year must contain only digits"
								},
								minLength: {
									value: 4,
									message: "Wrong year format"
								},
							}
						}
					/>

					<InputTextRequired
						fieldName="marketValue"
						labelText="Market Value"
						placeholder="i.e. 425000"
						register={register}
						requiredText="Enter market value"
						errors={errors}
						validation={
							{
								pattern: {
									// eslint-disable-next-line no-useless-escape
									value: /^\d+$/g,
									message: "Field must contain only digits"
								},
							}
						}
					/>

					<InputTextRequired
						fieldName="squareFeet"
						labelText="Square Feet"
						placeholder="i.e. 2375"
						register={register}
						requiredText="Enter square feet"
						errors={errors}
						validation={
							{
								pattern: {
									// eslint-disable-next-line no-useless-escape
									value: /^\d+$/g,
									message: "Field must contain only digits"
								},
							}
						}
					/>

					<SelectRequired
						fieldName="numberOfStories"
						labelText="# of Stories"
						optionsList={NUMBER_OF_STORIES}
						register={register}
						errors={errors}
						requiredText="Choose the number"
					/>

					<SelectRequired
						fieldName="numberOfFamilies"
						labelText="# of Families"
						optionsList={["1", "2", "3", "4", "5", "6", "7", "8"]}
						register={register}
						errors={errors}
						requiredText="Choose the number"
					/>

					<SelectRequired
						fieldName="foundationType"
						labelText="Foundation Type"
						optionsList={["Concrete slab", "Concrete block", "Pilings/stilts", "Other"]}
						register={register}
						errors={errors}
						requiredText="Choose the Foundation type"
					/>

					{
						isShowDescribeFoundationTypePilings &&
						<TextareaRequired
							fieldName="describeFoundationTypePilings"
							labelText="Describe the Pilings/stilts"
							placeholder="i.e. Concrete pilings"
							register={register}
							isAppearing={true}
							requiredText="Enter the description"
							errors={errors}
						/>
					}

					{
						isShowDescribeFoundationTypeOther &&
						<TextareaRequired
							fieldName="describeFoundationTypeOther"
							labelText="Describe the Foundation type"
							placeholder="i.e. Crawl space"
							register={register}
							isAppearing={true}
							requiredText="Enter the description"
							errors={errors}
						/>
					}

					<YesNoRequired
						fieldName="fireHydrantWithin"
						labelText="Fire hydrant within 1,000 feet of the premises?"
						register={register}
						errors={errors}
					/>

					<SelectRequired
						fieldName="distanceToFireDepartment"
						labelText="Distance to Fire Department"
						optionsList={["5 road miles or less", "over 5 road miles"]}
						register={register}
						errors={errors}
						requiredText="Choose the distance"
					/>

					<YesNoRequired
						fieldName="smokeDetectors"
						labelText="Smoke detectors"
						register={register}
						errors={errors}
					/>

					<YesNoOptional
						fieldName="automaticBurglarAlarmLocal"
						labelText="Automatic burglar alarm-local"
						register={register}
					/>

					<YesNoOptional
						fieldName="automaticFireAlarmLocal"
						labelText="Automatic fire alarm-local"
						register={register}
					/>

					<YesNoOptional
						fieldName="gatedCommunity"
						labelText="Gated community"
						register={register}
					/>

					<YesNoOptional
						fieldName="fireExtinguisher"
						labelText="Fire extinguisher"
						register={register}
					/>

					<YesNoOptional
						fieldName="automaticSprinklerSystem"
						labelText="Automatic sprinkler system"
						register={register}
					/>

					<YesNoOptional
						fieldName="securityPatrol"
						labelText="Security patrol"
						register={register}
					/>

				</div>

				<LienholderMortgagee
					register={register}
					errors={errors}
					watch={watch}
					setValue={setValue}
				/>

				<PreviousInsuranceCoverage />

				<LossHistory
					register={register}
					errors={errors}
					setValue={setValue}
				/>

				<div className={styles["grid-container-3"]}>

					<h3 className={classNames(styles["title"], styles["grid-item-wide-3"])}>Additional Underwriting Questions</h3>

					<YesNoRequiredWithExtraRow
						fieldName="uninsuredWithinTheLast12Months"
						labelText="Has the applicant been uninsured within the last 12 months?"
						describeName="describeUninsuredWithinTheLast12Months"
						placeholderText=""
						register={register}
						errors={errors}
						watch={watch}
					/>

					<YesNoRequiredWithExtraRow
						fieldName="foreclosureRepossessionBankruptcy"
						labelText="Has applicant had a foreclosure, repossession, bankruptcy, judgement, or lien during the past 5 years?"
						describeName="describeForeclosureRepossessionBankruptcy"
						placeholderText=""
						register={register}
						errors={errors}
						watch={watch}
					/>

					<YesNoRequiredWithExtraRow
						fieldName="anyPolicyDeclined"
						labelText="Any policy or coverage declined, cancelled or non-renewed during the prior 3 years?"
						describeName="describeAnyPolicyDeclined"
						placeholderText=""
						register={register}
						errors={errors}
						watch={watch}
					/>

					<YesNoRequiredWithExtraRow
						fieldName="riskWithExistingDamage"
						labelText="Risk with existing damage from a prior loss?"
						describeName="describeRiskWithExistingDamage"
						placeholderText=""
						register={register}
						errors={errors}
						watch={watch}
					/>

					<YesNoRequiredWithExtraRow
						fieldName={"anyKnownSinkholeActivity"}
						labelText={"Is there any known sinkhole activity on the premises?"}
						describeName={"describeAnyKnownSinkholeActivity"}
						placeholderText=""
						register={register}
						errors={errors}
						watch={watch}
					/>

					<YesNoRequiredWithExtraRow
						fieldName={"isThereTrampoline"}
						labelText={"Is there a trampoline on premises?"}
						describeName={"describeIsThereTrampoline"}
						placeholderText=""
						register={register}
						errors={errors}
						watch={watch}
					/>

					<YesNoRequiredWithExtraRow
						fieldName={"hasAnyoneConvicted"}
						labelText={"Has anyone with financial interest in the property been convicted of arson, fraud, or other crime related to a loss on the property now or within the last 5 years?"}
						describeName={"describeHasAnyoneConvicted"}
						placeholderText=""
						register={register}
						errors={errors}
						watch={watch}
					/>

					<YesNoRequiredWithExtraRow
						fieldName={"haveAnyCrimesOccurred"}
						labelText={"Have any crimes occurred or been attempted on your premises within the last 3 years?"}
						describeName={"describeHaveAnyCrimesOccurred"}
						placeholderText=""
						register={register}
						errors={errors}
						watch={watch}
					/>

					<YesNoRequiredWithExtraRow
						fieldName={"daycareConducted"}
						labelText={"Daycare conducted on premises?"}
						describeName={"describeDaycareConducted"}
						placeholderText=""
						register={register}
						errors={errors}
						watch={watch}
					/>

					<YesNoRequiredWithExtraRow
						fieldName={"isBusinessConducted"}
						labelText={"Is business conducted on premises?"}
						describeName={"describeIsBusinessConducted"}
						placeholderText=""
						register={register}
						errors={errors}
						watch={watch}
					/>

					<YesNoRequiredWithExtraRow
						fieldName={"isThereWoodstove"}
						labelText={"Is there a woodstove on premises?"}
						describeName={"describeIsThereWoodstove"}
						placeholderText=""
						register={register}
						errors={errors}
						watch={watch}
					/>

					<YesNoRequiredWithExtraRow
						fieldName={"anyLiveStockOrSaddleAnimal"}
						labelText={"Does the dwelling have any live stock or saddle animal exposure which is used for personal or business purposes?"}
						describeName={"describeAnyLiveStockOrSaddleAnimal"}
						placeholderText=""
						register={register}
						errors={errors}
						watch={watch}
					/>

					<YesNoRequiredWithExtraRow
						fieldName={"anyExoticAnimals"}
						labelText={"Are there are any exotic animals on premises?"}
						describeName={"describeAnyExoticAnimals"}
						placeholderText=""
						register={register}
						errors={errors}
						watch={watch}
					/>

					<YesNoRequiredWithExtraRow
						fieldName={"isDwellingRented"}
						labelText={"Is the dwelling rented?"}
						describeName={"describeIsDwellingRented"}
						placeholderText=""
						register={register}
						errors={errors}
						watch={watch}
					/>

					<YesNoRequiredWithExtraRow
						fieldName={"isDwellingForSale"}
						labelText={"Is the dwelling for sale?"}
						describeName={"describeIsDwellingForSale"}
						placeholderText=""
						register={register}
						errors={errors}
						watch={watch}
					/>

					<YesNoRequiredWithExtraRow
						fieldName={"anyHistoricalRegister"}
						labelText={"Is the risk on any historical register?"}
						describeName={"describeAnyHistoricalRegister"}
						placeholderText=""
						register={register}
						errors={errors}
						watch={watch}
					/>

					<YesNoRequiredWithExtraRow
						fieldName={"wasGuttedAndRemodeled"}
						labelText={"Was home completely gutted and remodeled?"}
						describeName={"describeWasGuttedAndRemodeled"}
						placeholderText=""
						register={register}
						errors={errors}
						watch={watch}
					/>

					<YesNoRequiredWithExtraRow
						fieldName={"areMortgagePaymentsLate"}
						labelText={"Are the mortgage payments late/delinquent?"}
						describeName={"describeAreMortgagePaymentsLate"}
						placeholderText=""
						register={register}
						errors={errors}
						watch={watch}
					/>

					<YesNoRequiredWithExtraRow
						fieldName={"areTaxPaymentsLate"}
						labelText={"Are the tax payments late/delinquent?"}
						describeName={"describeAreTaxPaymentsLate"}
						placeholderText=""
						register={register}
						errors={errors}
						watch={watch}
					/>

					<YesNoRequiredRow
						fieldName="isThereSwimmingPool"
						labelText="Is there a swimming pool on the premises?"
						register={register}
						errors={errors}
					/>

					{
						isShowDescribeSwimmingPool &&
						(<div className={classNames(styles["appearing-field"], styles["grid-item-wide-3"], styles["grid-container"])}>

							<TextareaRequired
								fieldName="describeIsThereSwimmingPool"
								labelText="Explain the answer"
								placeholder=""
								register={register}
								isAppearing={false}
								requiredText="Enter the explanation"
								errors={errors}
							/>

							<YesNoRequired
								fieldName={"isPoolFenced"}
								labelText={"Is pool fenced with self-latching and self-closing gates?"}
								register={register}
								errors={errors}
							/>

							<YesNoRequired
								fieldName={"anySlidesOrDivingBoards"}
								labelText={"Are there any slides or diving boards?"}
								register={register}
								errors={errors}
							/>

						</div>)
					}

					<YesNoRequiredRow
						fieldName="willBuildingBeVacant"
						labelText="Will the building be vacant or unoccupied for more than 60 days?"
						register={register}
						errors={errors}
					/>

					{
						isShowDescribeBuildingBeVacant &&
						(<div className={classNames(styles["appearing-field"], styles["grid-item-wide-3"], styles["grid-container-3"])}>

							<TextareaRequired
								fieldName="describeWillBuildingBeVacant"
								labelText="Provide details of vacancy"
								placeholder=""
								register={register}
								isAppearing={false}
								requiredText="Enter the explanation"
								errors={errors}
							/>

							<TextareaRequired
								fieldName="detailsOfSecurityProtections"
								labelText="Provide details of building security/protections (locks, etc)"
								placeholder=""
								register={register}
								isAppearing={false}
								requiredText="Enter the explanation"
								errors={errors}
							/>

							<YesNoRequiredRow
								fieldName="utilitiesConditions"
								labelText="If utilities are connected will heat be maintained to prevent all plumbing and/or fire protective systems from freezing OR if utilities are disconnected are all pipe/plumbing systems drained?"
								register={register}
								errors={errors}
							/>

							<YesNoRequired
								fieldName={"areUtilitiesDisconnected"}
								labelText={"Are utilities disconnected?"}
								register={register}
								errors={errors}
							/>

							<SelectRequired
								fieldName="howLongVacant"
								labelText="How long has the property been vacant?"
								optionsList={HOW_LONG_VACANT_OPTIONS}
								register={register}
								errors={errors}
								requiredText="Choose the option"
							/>

							<SelectRequired
								fieldName="reasonForVacancy"
								labelText="What is the reason for vacancy?"
								optionsList={REASON_OF_VACANCY_OPTIONS}
								register={register}
								errors={errors}
								requiredText="Choose the option"
							/>

							<SelectRequired
								fieldName="conditionOfBuilding"
								labelText="Condition of building?"
								optionsList={CONDITION_OF_BUILDING_OPTIONS}
								register={register}
								errors={errors}
								requiredText="Choose the option"
							/>

							<YesNoRequired
								fieldName={"isBuildingCondemned"}
								labelText={"Is building condemned?"}
								register={register}
								errors={errors}
							/>

						</div>)
					}

				</div>

				<Renovation
					register={register}
					errors={errors}
				/>

				<ProtectionHighClass
					watchFireHydrant={watchFireHydrant}
					register={register}
					errors={errors}
					watch={watch}
				/>

				<AdditionalCommentsRemarks
					register={register}
				/>

				<div className={styles["box-row-end"]}>
					<button type="button" className={styles["step-button"]} onClick={handlePreviousClick}>Previous</button>
					<button type="submit" className={styles["step-button"]}>Next step</button>
				</div>

			</form >
		</>
	);
};
