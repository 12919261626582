import { InputTextOptionalProps } from "../../models/models";
import { ErrorMessage } from "../ErrorMessage/ErrorMessage";
import { NoteOneRow } from "../NoteOneRow/NoteOneRow";
import { InputTextBase } from "./InputTextBase";

export const InputTextOptional = ({
	fieldName,
	labelText,
	placeholder,
	register,
	errors,
	onBlur,
	onFocus,
	onChange,
	maxLength,
	validation
}: InputTextOptionalProps) => {
	return (
		<InputTextBase
			fieldName={fieldName}
			labelText={labelText}
			placeholder={placeholder}
			register={register}
			errors={errors}
			onBlur={onBlur}
			onFocus={onFocus}
			onChange={onChange}
			maxLength={maxLength}
			validation={validation}
		>
			{
				errors[fieldName] ?
					(<ErrorMessage errorMessage={errors[fieldName]?.message as string} />) :
					(<NoteOneRow text="Optional" />)
			}
		</InputTextBase>
	);
};
