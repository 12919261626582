import classNames from "classnames";
import styles from '../FormSteps/FormSteps.module.css';
import { SelectDisabledProps } from "../../models/models";
import { ErrorMessage } from "../ErrorMessage/ErrorMessage";

export const SelectDisabledRow = ({ fieldName, labelText, value, optionsList }: SelectDisabledProps) => {
	return (
		<div className={classNames(styles["grid-item"], styles["grid-item-wide-3"])}>
			<div className={styles["box-row-near"]}>
				<div className={styles["one-third-page-select"]}>
					<select
						disabled
						value={value}
						name={fieldName}
						className={classNames(styles["dropdown-field"], styles["w-select"])}
						id={fieldName}>
						<option value="">-- Choose --</option>
						{optionsList.map((item) => (
							<option key={item} value={item}>{item}</option>
						))}
					</select>
					{/* ErrorMessage тут пустой - нужен для правильного расстояния */}
					<ErrorMessage errorMessage={''} />
				</div>
				<label htmlFor={fieldName} className={styles["form-field-label"]}>
					{labelText}
				</label>
			</div>
		</div>
	);
};
