import { InputPhoneTripleRequiredProps } from "../../models/models";
import { ErrorMessage } from "../ErrorMessage/ErrorMessage";
import { InputPhoneTripleBase } from "./InputPhoneTripleBase";

export const InputPhoneTripleRequired = ({
	fieldName1,
	fieldName2,
	fieldName3,
	labelText,
	watch,
	setFocus,
	register,
	requiredText,
	errors }: InputPhoneTripleRequiredProps) => {
	return (
		<InputPhoneTripleBase
			fieldName1={fieldName1}
			fieldName2={fieldName2}
			fieldName3={fieldName3}
			labelText={labelText}
			watch={watch}
			setFocus={setFocus}
			register={register}
			requiredText={requiredText}
			errors={errors}
		>
			<ErrorMessage
				errorMessage={errors[fieldName1] ? (errors[fieldName1].message as string) :
					errors[fieldName2] ? (errors[fieldName2].message as string) :
						errors[fieldName3] ? (errors[fieldName3].message as string) : ''}
			/>
		</InputPhoneTripleBase>
	)
};
