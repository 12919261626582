import { useEffect } from 'react';
import styles from './HomePage.module.css';
import { logInUser } from '../../store/authorizationSlice';
import { useAppDispatch } from '../../store/hookTypes';
import HomeImageProtect from './insurance-4.svg';
import HomeImageFinance from './financial-literacy-1.svg';
import HomeDeal from './business-deal-1.svg';
import classNames from 'classnames';

export const HomePage = () => {
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(logInUser());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={styles["page-container"]}>
			<h2 className={styles["title"]}>Welcome to Aldgate Insurance!</h2>

			<div className={styles["main-box"]}>
				<div className={styles["main-text"]}>
					<h3 className={styles["subtitle"]}>Protecting Your Home and Peace of Mind</h3>

					<p>At Aldgate Insurance, we understand that your home is more than just a place to live—it's your sanctuary, your haven, and your most valuable asset. We also recognize that unforeseen accidents and liabilities can happen, potentially putting your home and financial security at risk. That's why we are here to offer you comprehensive homeowners liability insurance coverage tailored to your needs.</p>

					<h3 className={styles["subtitle"]}>Comprehensive Protection</h3>

					<p>We provide robust homeowners liability insurance coverage to safeguard you against unexpected events. Whether it's a slip and fall accident on your property or accidental property damage caused by you or your family members, our policies are designed to protect you from potential legal and financial liabilities.</p>
				</div>

				<div className={styles["main-image"]}>
					<img src={HomeImageProtect} alt="Home page" />
				</div>
			</div>

			<div className={styles["main-box"]}>
				<div className={styles["main-image"]}>
					<img src={HomeDeal} alt="Home page" />
				</div>

				<div className={styles["main-text"]}>
					<h3 className={styles["subtitle"]}>Tailored Solutions</h3>

					<p>We understand that every homeowner's needs are unique. That's why we offer customized coverage options to suit your specific requirements. Our experienced insurance professionals will work closely with you to assess your risks and provide a policy that offers the right amount of coverage for your home.</p>

					<h3 className={styles["subtitle"]}>Expert Guidance</h3>

					<p>With years of industry experience, our knowledgeable team is well-versed in the complexities of homeowners liability insurance. We are committed to providing you with expert guidance, answering your questions, and ensuring you have a clear understanding of your coverage options. Trust us to guide you through the insurance process with transparency and professionalism.</p>
				</div>
			</div>

			<div className={styles["main-box"]}>
				<div className={styles["main-text"]}>

					<h3 className={styles["subtitle"]}>Financial Security</h3>

					<p>Our homeowners liability insurance policies offer financial protection and peace of mind. In the event of a liability claim or lawsuit, we will assist you with legal defense costs, medical expenses, and potential settlement fees, helping you navigate through the process and minimize any financial burdens.</p>

					<h3 className={styles["subtitle"]}>Competitive Rates</h3>

					<p>We believe that reliable homeowners liability insurance should be accessible and affordable. Our commitment to competitive rates ensures that you receive quality coverage without breaking the bank. Protecting your home and loved ones shouldn't be a financial burden, and we are here to help you find the right balance between cost and protection.</p>
				</div>

				<div className={styles["main-image"]}>
					<img src={HomeImageFinance} alt="Home page" />
				</div>
			</div >

			<div className={styles["main-box"]}>
				{/* <div className={styles["main-image"]}>
					<img src={HomeImage} alt="Home page" />
				</div> */}

				<div className={classNames(styles["main-text"], styles["highlight"])}>
					<h3 className={styles["subtitle"]}>Protect Your Home Today!</h3>

					<p>Don't leave the safety and security of your home to chance. Trust Aldgate Insurance to provide you with the comprehensive homeowners liability insurance coverage you deserve. With us, you can rest assured knowing that your home, belongings, and financial well-being are protected. Contact us today for a personalized quote, and let us help you safeguard your home and enjoy the peace of mind you deserve.</p>
				</div>
			</div>
		</div >
	);
};
