import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { LocationFromZip } from '../models/models';

export type LocationWithZipState = {
	locationWithZip: LocationFromZip;
};

const initialState: LocationWithZipState = {
	locationWithZip: {
		county: '',
		zip: '',
		state: {
			long: '',
			short: '',
		},
		city: [],
	},
};

const locationWithZipSlice = createSlice({
	name: 'locationWithZip',
	initialState: initialState,
	reducers: {
		setLocationWithZip(state, action: PayloadAction<LocationFromZip>) {
			state.locationWithZip = action.payload;
		},
	},
});

export const { setLocationWithZip } = locationWithZipSlice.actions;
export const locationWithZipReducer = locationWithZipSlice.reducer;
