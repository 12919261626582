import { InputTextRequiredProps } from "../../models/models";
import { ErrorMessage } from "../ErrorMessage/ErrorMessage";
import { InputTextBase } from "./InputTextBase";

export const InputTextRequired = ({
	fieldName,
	labelText,
	placeholder,
	register,
	requiredText,
	errors,
	onBlur,
	onFocus,
	onChange,
	maxLength,
	validation
}: InputTextRequiredProps) => {
	return (
		<InputTextBase
			fieldName={fieldName}
			labelText={labelText}
			placeholder={placeholder}
			register={register}
			requiredText={requiredText}
			errors={errors}
			onBlur={onBlur}
			onFocus={onFocus}
			onChange={onChange}
			maxLength={maxLength}
			validation={validation}
		>
			<ErrorMessage errorMessage={errors[fieldName] ? (errors[fieldName]?.message as string) : ''} />
		</InputTextBase>
	);
};
