export let rankList = ['1st'];
for (let i = 2; i <= 100; i++) {
	if (i === 2) {
		rankList.push(`${i}nd`);
	} else if (i === 3) {
		rankList.push(`${i}rd`);
	} else if (i >= 4 && i <= 20) {
		rankList.push(`${i}th`);
	} else if (i === 21 || i === 31 || i === 41 || i === 51 || i === 61 || i === 71 || i === 81 || i === 91) {
		rankList.push(`${i}st`);
	} else if (i === 22 || i === 32 || i === 42 || i === 52 || i === 62 || i === 72 || i === 82 || i === 92) {
		rankList.push(`${i}nd`);
	} else if (i === 23 || i === 33 || i === 43 || i === 53 || i === 63 || i === 73 || i === 83 || i === 93) {
		rankList.push(`${i}rd`);
	} else {
		rankList.push(`${i}th`);
	};
};
