import { useAppSelector } from "../store/hookTypes";

export const useEntryInfo = () => {
	const { formLienholderLossPayeeList, formLienholderMortgageeList, formLienholderLendersList, formLienholderContractList } = useAppSelector((state) => state.newQuote);

	const getEntryInfo = (lienholderId: string, lienholderType: string) => {
		let entryInfo;
		if (lienholderType === 'Loss Payee') {
			entryInfo = formLienholderLossPayeeList.find(item => item.lienholderId === lienholderId);
		} else if (lienholderType === 'Mortgagee') {
			entryInfo = formLienholderMortgageeList.find(item => item.lienholderId === lienholderId);
		} else if (lienholderType === 'Lenders Loss Payable') {
			entryInfo = formLienholderLendersList.find(item => item.lienholderId === lienholderId);
		} else if (lienholderType === 'Contract of sale') {
			entryInfo = formLienholderContractList.find(item => item.lienholderId === lienholderId);
		} else {
			console.log('Wrong Lienholder Type');
		};
		return entryInfo;
	};

	return { getEntryInfo };
};
