import styles from "../FormSteps/FormSteps.module.css";

export const PolicyOverview = () => {
	return (
		<div className={styles["sign-container"]}>
			<h2 className={styles["sign-title"]}>Overview</h2>
			<h3 className={styles["sign-subtitle"]}>General information about your policy</h3>

			<div className={styles["sign-overview"]}>
				<div className={styles["sign-flex-item"]}>
					<span className={styles["sign-text"]}>Quote Number</span>
					<span className={styles["sign-text-strong"]}>ABC123456789</span>
				</div>
				<div className={styles["sign-flex-item"]}>
					<span className={styles["sign-text"]}>Reference Number</span>
					<span className={styles["sign-text-strong"]}>123456789</span>
				</div>
				<div className={styles["sign-flex-item"]}>
					<span className={styles["sign-text"]}>Effective Date</span>
					<span className={styles["sign-text-strong"]}>123456789</span>
				</div>
				<div className={styles["sign-flex-item"]}>
					<span className={styles["sign-text"]}>Expiration Date</span>
					<span className={styles["sign-text-strong"]}>123456789</span>
				</div>
				<div className={styles["sign-flex-item"]}>
					<span className={styles["sign-text"]}>Number of forms to be signed</span>
					<span className={styles["sign-text-strong"]}>1</span>
				</div>
				<div className={styles["sign-flex-item"]}>
					<span className={styles["sign-text"]}>Agency</span>
					<span className={styles["sign-text-strong"]}>Amelia Underwriters, Inc.</span>
				</div>
				<div className={styles["sign-flex-item"]}>
					<span className={styles["sign-text"]}>Agency Phone Number</span>
					<span className={styles["sign-text-strong"]}>844-467-6959</span>
				</div>
			</div>
		</div>
	);
};
