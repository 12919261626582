import { createApi, fetchBaseQuery, BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { RootState } from '.';
import { REPORTS_BASE_URL, POSTFIX_ZIP, POSTFIX_CHECKADDRESS, POSTFIX_DTC, POSTFIX_WIND } from '../constants';
import { ServerAuthResponse, LocationFromZip, ServerDataResponse, CheckedAddress, CheckAddressParams, DistanceToCoast, WindData, WindParams } from '../models/models';
import { setCredentials, logOut } from './authorizationSlice';

//*================= auth ===============================
const baseQuery = fetchBaseQuery({
	baseUrl: REPORTS_BASE_URL,
	credentials: 'include',
	// prepareHeaders is used to configure the header of every request and gives access to getState()
	prepareHeaders: (headers, { getState }) => {
		const accessToken = (getState() as RootState).authorization.access_token;
		if (accessToken) {
			headers.set('Authorization', `Bearer ${accessToken}`);
			return headers;
		};
	},
});

const baseQueryRefresh = fetchBaseQuery({
	baseUrl: REPORTS_BASE_URL,
	credentials: 'include',
	method: 'POST',
	// prepareHeaders is used to configure the header of every request and gives access to getState()
	prepareHeaders: (headers, { getState }) => {
		const accessToken = (getState() as RootState).authorization.access_token;
		const refreshToken = (getState() as RootState).authorization.refresh_token;
		if (accessToken) {
			headers.set('x-refresh-token', `${refreshToken}`);
			return headers;
		};
	},
});

const baseQueryWithReauth: BaseQueryFn<
	string | FetchArgs,
	unknown,
	FetchBaseQueryError
> = async (args, api, extraOptions) => {
	let result = await baseQuery(args, api, extraOptions);
	if (result.error && result.error.status === 401) {
		// try to get a new token
		const refreshResult = await baseQueryRefresh('cbsecurity/refreshtoken', api, extraOptions);
		console.log('refreshResult=', refreshResult);
		if (refreshResult.data) {
			// store the new token
			api.dispatch(setCredentials({
				access_token: (refreshResult.data as ServerAuthResponse).data.access_token,
				refresh_token: (refreshResult.data as ServerAuthResponse).data.refresh_token,
			}));
			//! api.dispatch(setLogInUser({
			//! 	access_token: (refreshResult.data as ServerAuthResponse).data.access_token,
			//! 	refresh_token: (refreshResult.data as ServerAuthResponse).data.refresh_token,
			//! }));
			// console.log('refreshResult.data=', refreshResult.data);
			// retry the original query with new access token
			result = await baseQuery(args, api, extraOptions);
		} else {
			//! api.dispatch(setlogOutUser());
			api.dispatch(logOut());
		};
	};
	return result;
};

//*==============================================================

export const mainApiQueries = createApi({
	reducerPath: 'mainApiQueries',
	baseQuery: baseQueryWithReauth,
	endpoints: (builder) => ({
		//! <что-получаем-в-ответ-от-сервера, какой-параметр-принимаем-чтобы-осуществить-данный-запрос>

		getLocationWithZip: builder.query<LocationFromZip, string>({
			query: (zip) => ({
				url: `${POSTFIX_ZIP}/${zip}`,
				method: 'GET',
			}),
			transformResponse: (response: ServerDataResponse<LocationFromZip>) => response.data,
		}),

		checkAddress: builder.query<CheckedAddress, CheckAddressParams>({
			query: (addressObj) => ({
				url: `${POSTFIX_CHECKADDRESS}`,
				method: 'GET',
				params: {
					zip: addressObj.zip,
					city: addressObj.city,
					state: addressObj.state,
					street: addressObj.street,
				},
			}),
			transformResponse: (response: ServerDataResponse<CheckedAddress>) => response.data,
		}),

		getDistanceToCoast: builder.query<DistanceToCoast, CheckAddressParams>({
			query: (addressObj) => ({
				url: `${POSTFIX_DTC}`,
				method: 'GET',
				params: {
					zip: addressObj.zip,
					city: addressObj.city,
					state: addressObj.state,
					street: addressObj.street,
				},
			}),
			transformResponse: (response: ServerDataResponse<DistanceToCoast>) => response.data,
		}),

		getWind: builder.query<WindData, WindParams>({
			query: (obj) => ({
				url: `${POSTFIX_WIND}`,
				method: 'GET',
				params: {
					state: obj.state,
					county: obj.county,
					effectiveDate: obj.effectiveDate,
					distanceToCoast: obj.distanceToCoast,
				},
			}),
			transformResponse: (response: ServerDataResponse<WindData>) => response.data,
		}),

		// place for other queries


	}),
});

export const {
	useLazyGetLocationWithZipQuery,
	useLazyCheckAddressQuery,
	useLazyGetDistanceToCoastQuery,
	useLazyGetWindQuery,
} = mainApiQueries;
