import { NavLink } from 'react-router-dom';
import styles from './Navigation.module.css';
import { useState } from 'react';
import classNames from 'classnames';

export const Navigation = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    document.body.classList.toggle("_lock"); //! для запрета прокрутки при активном меню-бургере
  };

  return (
    <nav className={styles.navigation}>
      <div className={styles.navigation__title}>Homeowners</div>

      <div className={menuOpen ? classNames(styles.navigation__links, styles._active) : styles.navigation__links}>
        <div className={styles.navigation__main}>
          <NavLink
            to="/"
            className={({ isActive }) => (isActive ? styles['link-active'] : styles['link'])}
            end
            onClick={toggleMenu}

          >
            Home
          </NavLink>
          <NavLink
            to="/about"
            className={({ isActive }) => (isActive ? styles['link-active'] : styles['link'])}
            onClick={toggleMenu}

          >
            About
          </NavLink>
          <NavLink
            to="/faqs"
            className={({ isActive }) => (isActive ? styles['link-active'] : styles['link'])}
            onClick={toggleMenu}

          >
            FAQs
          </NavLink>
          <NavLink
            to="/blog"
            className={({ isActive }) => (isActive ? styles['link-active'] : styles['link'])}
            onClick={toggleMenu}

          >
            Blog
          </NavLink>
          <NavLink
            to="/contact"
            className={({ isActive }) => (isActive ? styles['link-active'] : styles['link'])}
            onClick={toggleMenu}

          >
            Contact
          </NavLink>
        </div>

        <NavLink
          to="/new-quote-step1"
          className={({ isActive }) => (isActive ? styles['button-hidden'] : styles['button-link'])}
          onClick={toggleMenu}
        >
          New Quote
        </NavLink>
      </div>

      <div className={menuOpen ? classNames(styles.menu__logo, styles._active) : styles.menu__logo}>
        <h2 className={styles.navigation__title}>Homeowners</h2>
      </div>

      <button type="button" className={styles["menu__button"]}>
        <div className={menuOpen ? classNames(styles.menu__icon, styles._active) : styles.menu__icon} onClick={toggleMenu}>
          <span className={styles["menu__icon-span"]}></span>
        </div>
      </button>
    </nav >
  );
};
