import styles from "./FormSteps.module.css";
import { useNavigate } from 'react-router-dom';
import { MouseEvent } from 'react';
import { PolicyOverview } from "../PolicyOverview/PolicyOverview";
import { PolicySigning } from "../PolicySigning/PolicySigning";
import { PolicyDocument } from "../PolicyDocument/PolicyDocument";
import { useAppSelector } from "../../store/hookTypes";

export const FormStep7 = () => {
	const { isValid } = useAppSelector((state) => state.signature);
	const navigate = useNavigate();

	const handlePreviousClick = (event: MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		navigate('/new-quote-step6');
	};

	const handleDoneClick = (event: MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		console.log('Done!');
	};

	return (
		<div className={styles["sign-wrapper"]}>
			<h3 className={styles["title"]}>Legal issues</h3>

			<PolicyOverview />

			<PolicySigning />

			{
				isValid ? <PolicyDocument /> : null
			}

			<div className={styles["box-row-end"]}>
				<button type="button" className={styles["step-button"]} onClick={handlePreviousClick}>Previous</button>
				<button type="button" className={styles["step-button"]} onClick={handleDoneClick}>Done</button>
			</div>
		</div>
	);
};
