import { useAppSelector } from "../../store/hookTypes";
import { LossCard } from "../LossCard/LossCard";
import styles from "./LossList.module.css";

export const LossList = () => {
	const formLossList = useAppSelector((state) => state.newQuote.formLossList);

	return (
		<div className={styles["list-container"]}>
			{
				formLossList.map((entry) => (<LossCard
					key={entry.lossId}
					id={entry.lossId}
					date={entry.lossDate}
					type={entry.lossType}
				/>))
			}
		</div>
	);
};
