import classNames from 'classnames';
import styles from '../FormSteps/FormSteps.module.css';
import { CheckboxInvisibleProps } from '../../models/models';

// INVISIBLE CHECKBOX FOR USEFORM VALIDATION
export const CheckboxInvisible = ({ name, requiredText, register }: CheckboxInvisibleProps) => {
	return (
		<div className={classNames(styles["grid-item"], styles["ok-checkbox"])} >
			<div className={classNames(styles["row-checkboxes"])}>
				<input
					{...register(name, {
						required: requiredText,
					})}
					type="checkbox"
					value="ok"
					className={styles["input-checkbox"]}
					id={name} />
				<label htmlFor={name} className={styles["label-checkbox"]}>Ok</label>
			</div>
		</div>
	);
};
